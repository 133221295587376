import React from "react";
import { Grid } from "@mui/material";

const StatusSelect = ({ selectedStatus, setSelectedStatus }) => {
  return (
    <Grid container spacing={2}>
      <Grid item xs={12} sm={8} md={9}>
        <select
          className="form-select mb-3"
          aria-label="Select status"
          value={selectedStatus}
          onChange={(e) => setSelectedStatus(e.target.value)}
        >
          <option value="All">All</option>
          <option value="Accepted">Accepted</option>
          <option value="rejected">Rejected</option>
          <option value="Ready to ship">Ready to ship</option>
          <option value="shipped">Shipped</option>
          <option value="delivered">Delivered</option>
          <option value="return">Return</option>
          <option value="completed">Completed</option>
        </select>
      </Grid>
    </Grid>
  );
};

export default StatusSelect;
