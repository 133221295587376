import React, { createContext, useContext, useEffect, useState } from 'react';
import { useLocation } from 'react-router-dom';
import { useTheme } from "@mui/material/styles";
// Create a Context for the background color
const BackgroundColorContext = createContext();

export const useBackgroundColor = () => {
  return useContext(BackgroundColorContext);
};

const BackgroundColorProvider = ({ children }) => {
  const [backgroundColor, setBackgroundColor] = useState("#f7f7ff"); 
  const location = useLocation();
  const theme = useTheme();
  useEffect(() => {
    if (location.pathname === "/category") {
      setBackgroundColor(theme.palette.custom.backgroundColor); 
    } else if (/^\/category\/\d+$/.test(location.pathname)) {
      setBackgroundColor(theme.palette.blue.backgroundColor); 
    } else if (location.pathname === "/product") {
      setBackgroundColor(theme.palette.purple.backgroundColor); 
    } 
    else if (location.pathname === "/accepted") {
      setBackgroundColor(theme.palette.lime.backgroundColor); 
    } 
    else if (location.pathname === "/all%20orders") {
      setBackgroundColor(theme.palette.lime.backgroundColor); 
    } 
    else if (location.pathname === "/profile") {
      setBackgroundColor(theme.palette.lightBlue.backbackgroundColor); 
    }
    else if (location.pathname === "/ManageProduct") {
      setBackgroundColor(theme.palette.custom.backgroundColor); 
    }
    else if (location.pathname === "/add-store") {
      setBackgroundColor(theme.palette.store.backgroundColor); 
    }
    else if (location.pathname === "/Home") {
      setBackgroundColor(theme.palette.home.backgroundColor); 
    }
    
    else if (location.pathname === "/dashboard") {
      setBackgroundColor("red !important");
    }else {
      setBackgroundColor(theme.palette.home.backgroundColor); 
    }
  }, [location.pathname, theme.palette]);

  return (
    <BackgroundColorContext.Provider value={backgroundColor}>
      <div style={{ backgroundColor, minHeight: "100vh", width: "100%", display: "flex", flexDirection: "column" }}>
        {children}
      </div>
    </BackgroundColorContext.Provider>
  );
};

export default BackgroundColorProvider;
