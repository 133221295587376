
import React from "react";
import { Dialog, DialogTitle, DialogContent, DialogActions, TextField, Typography, Box } from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import DoneIcon from "@mui/icons-material/Done";
import IconButtonWithText from "../../../reusable/button/IconButtonWithText ";


const normalizeProductName = (name) => {
  return name
    .replace(/\s+/g, ' ')  
    .trim()                
    .toLowerCase()        
    .replace(/\b\w/g, char => char.toUpperCase()); 
};

const NameEdit = ({
  isOpen,
  handleClose,
  title = "Edit Name",
  label = "Enter product name",
  placeholder = "Product name",
  value,
  onChange,
  onSave,
}) => {
  const handleSave = () => {
    const normalizedValue = normalizeProductName(value);
    onSave(normalizedValue);
    handleClose();
  };

  return (
    <Dialog
      open={isOpen}
      onClose={handleClose}
      maxWidth="xs"
      sx={{ borderRadius: "5px", margin: "20px" }}
      fullWidth
    >
      <DialogTitle
        sx={{
          backgroundColor: "#02ac02db",
          color: "white",
          textAlign: "center",
          fontSize: "16px",
          fontWeight: "500",
          padding: "14px",
          fontFamily: "Poppins, sans-serif",
        }}
      >
        {title}
      </DialogTitle>
      <DialogContent style={{ padding: "14px" }}>
        <Box style={{ justifyContent: 'space-between',display:'flex' }}>
          <Typography variant="body1" style={{ marginBottom: "5px" }}>
            <b style={{ fontWeight: "400", fontFamily: "Poppins, sans-serif" }}>
              {label}
            </b>
          </Typography>
          <Typography variant="caption" sx={{  textAlign: 'left', color: 'blue', display: 'flex', fontSize: '15px', justifyContent: 'flex-end' }}>
            <b>{value.length}/100</b>
          </Typography>
        </Box>
        <TextField
          type="text"
          variant="outlined"
          size="small"
          className="customTexName"
          placeholder={placeholder}
          fullWidth
          value={value}
          onChange={onChange}
          autoComplete="off"
          inputProps={{ maxLength: 100 }}
          sx={{
            "& .MuiOutlinedInput-root": {
              "& fieldset": {
                border: "none",
              },
            },
          }}
        />
      </DialogContent>
      <DialogActions sx={{ paddingBottom: "20px" }}>
        <button
          onClick={handleClose}
          type="button"
          className="btn btn-outline-secondary d-flex justify-content-between align-items-center px-4"
        >
          <CloseIcon sx={{ fontSize: "20px !important" }} className="me-2" />
          CANCEL
        </button>
        <IconButtonWithText
          icon={DoneIcon}
          text="SAVE"
          onClick={handleSave}
        />
      </DialogActions>
    </Dialog>
  );
};

export default NameEdit;
