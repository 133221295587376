import { createTheme } from "@mui/material/styles";

export const mytheme = createTheme({
  palette: {
    primary: {
      light: "#1b5e20", //listitem button hovor text
      main: "#3f50b5",
      dark: "#002884",
      contrastText: "#fff",
      text: "#014601",
    },
    secondary: {
      light: "#ff7961",
      main: "#f44336",
      dark: "#ba000d",
      contrastText: "#000",
    },
    error: {
      main: "#ff7961",
    },
    gray: {
      main: "#5f5f5f", //listitem button
    },
    warning: {
      main: "#ff9800",
      light: "#ffc947",
    },

    blue: {
      // lightBlue: '#e1f2f9d1',
      lightBlue: "#e1f5fe",
      mainbutton: "#43a047", //button add/edit
      light: "#0288d1", //title/path
      backgroundColor: "#e3f2fd",
      hovor: "#b3e5fc", //card,menu
    },
    custom: {
      // This is the hex code for rgb(236 247 255)
      lightGreen: "#c8e6c9", //menu
      mainbutton: "#43a047", //button add/edit
      light: "#009578", //title/path
      backgroundColor: "#e8f5e9",
      hovor: "#c8e6c9", //card,menu
    },

    // amber
    purple: {
      lightpurple: "#fff8e1",
      mainbutton: "#ff8f00", //button /action
      light: "#ffa000", //title/path
      backgroundColor: "#fff8e1",
    },
    lime: {
      lightplime: "#fff8e1",
      mainbutton: "#f9fbe7", //table
      main: "", //  //button add/edit/action
      texthead: "#d4e157",
      light: "rgba(251,183,24,255)", //title/path
      backgroundColor: "#f9fbe7",
      head: "#cddc39",
      hovor: "#e6ee9c", //card,menu
      smalltable: "#dce775",
    },
    // order
    green: {
      menuhead: "green",
    },
    // store
    store:{
      backgroundColor: '#e8eaf6',
      light:'#3f51b5', //breadcrumb
      hovor:'#c5cae9',
      // "rgb(213 215 232)",
    },
    dashboard:{
      backgroundColor: '#e8eaf6',
      light:'#3f51b5', //breadcrumb
      hovor:'#c5cae9',
    },
      // "rgb(213 215 232)",
    // home
    home:{
      backgroundColor: '#e8eaf6',
      light:'#3f51b5', //breadcrumb
      hovor:'#c5cae9',
      // "rgb(213 215 232)",
    },
    // Profile
    lightBlue: {
      backbackgroundColor: "#f7f7ff",
    },

    menu: {
      // This is the hex code for rgb(236 247 255)
      lightGreen: "#ededff", //menu
      mainbutton: "#43a047", //button add/edit
      light: "#4c5258", //title/path
      backgroundColor: "#e8f5e9",
      hovor: "#ededff", //card,menu
      iconWithText:'#4c5258'
      
    },
// promo
    promo: {
      lightpurple: "#fff8e1",
      mainbutton: "#474f8d", //button /action
      light: "#ffa000", //title/path
      backgroundColor: "#fff8e1",
    },
  },
});
