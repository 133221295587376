import React from 'react'
import { useTheme } from "@mui/material/styles";

function Dashboard() {
  const theme = useTheme();
  return (
    <div 
    style={{ backgroundColor: theme.palette.dashboard.backgroundColor,  minHeight: "100vh", width: "100%", display: "flex", flexDirection: "column" }}
    >
    Dashboard1
   
   
    
  </div>
  )
}

export default Dashboard
