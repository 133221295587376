import React, { useState, useEffect } from 'react';
import { Typography, Grid } from '@mui/material';
import OrderTable from './OrderTable';
import StatusSelect from './SelectStatus';
import json from '../../ManageProducts/Orders/Demo.json';
import OrderDetails from './OrderView';
import { useTheme } from "@mui/material/styles";
function OrdersData() {
  const [orders, setOrders] = useState([]);
  const [filteredData, setFilteredData] = useState([]);
  const [selectedStatus, setSelectedStatus] = useState('');
  const [loading, setLoading] = useState(true);
  const [selectedOrder, setSelectedOrder] = useState(null);
  const [drawerOpen, setDrawerOpen] = useState(false);
  const theme = useTheme();
  const toggleDrawer = (open) => () => {
    setDrawerOpen(open);
  };
  useEffect(() => {
    // Set the orders data directly from the imported JSON
    setOrders(json.content);
    setFilteredData(json.content);
    setLoading(false);
  }, []);

  useEffect(() => {
    filterOrders(selectedStatus);
  }, [selectedStatus, orders]);

  const filterOrders = (status) => {
    if (status === '') {
      setFilteredData(orders);
    } else {
      const filtered = orders.filter(order => order.orderStatus === status);
      setFilteredData(filtered);
    }
  };

  const handleView = (orderId) => {
    console.log('View order:', orderId);
    setSelectedOrder(orderId);
    toggleDrawer(true)();
  };

  return (
    <div>
      <Typography gutterBottom variant="h6" sx={{ color: theme.palette.lime.texthead,}}>
        Order {selectedStatus}
      </Typography>

      <StatusSelect
        selectedStatus={selectedStatus}
        setSelectedStatus={setSelectedStatus}
      />

      <Grid container spacing={1}>
        <Grid item md={12} xs={12}>
          <OrderTable data={filteredData} onView={handleView} loading={loading} />
        </Grid>
      </Grid>
      {selectedOrder && (
            <OrderDetails order={selectedOrder} open={drawerOpen}  toggleDrawer={toggleDrawer} />
      )}
    </div>
  );
}

export default OrdersData;
