import React from 'react';
import { Typography } from '@mui/material';
import { useTheme } from '@mui/material/styles';
import ShoppingCartIcon from '@mui/icons-material/ShoppingCart';
const IconWithText = ({ icon: Icon, text, iconStyle = {}, textStyle = {}, containerStyle = {} }) => {
  const theme = useTheme();

  return (
    <div
      style={{
        display: "flex",
        flexDirection: "row",
        alignItems: "center",
        marginRight: '10px',
        ...containerStyle, // Apply custom container styles if provided
      }}
    >
      <ShoppingCartIcon
        style={{
          fontSize: 22,
          color: theme.palette.purple.light,
          marginRight: "5px",
          ...iconStyle, // Apply custom icon styles if provided
        }}
      />
      <Typography
        gutterBottom
        variant="h6"
        align="center"
        style={{
          color: theme.palette.purple.light,
          margin: "0 auto",
          ...textStyle, // Apply custom text styles if provided
        }}
      >
        {text}
      </Typography>
    </div>
  );
};

export default IconWithText;
