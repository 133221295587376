

import React from 'react';
import CloseIcon from '@mui/icons-material/Close';
import PropTypes from 'prop-types';

const CancelButton = ({ 
  icon: Icon = CloseIcon, 
  text = 'CANCEL', 
  onClick 
}) => {
  return (
    <button 
      onClick={onClick} 
      type="button" 
      className="btn btn-outline-secondary d-flex justify-content-between align-items-center px-4"
    >
      {Icon && <Icon sx={{ fontSize: "20px !important" }} className="me-2" />}
      {text}
    </button>
  );
};

// Define prop types
CancelButton.propTypes = {
  icon: PropTypes.elementType,
  text: PropTypes.string,
  onClick: PropTypes.func.isRequired,
};
export default CancelButton;