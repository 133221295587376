import React, { useState, useEffect } from 'react';
import { Modal, Box, Typography, Button, List, ListItem,  ListItemIcon } from '@mui/material';
import { ArrowBack } from '@mui/icons-material';
import { fetchCategories } from '../../../../utils/SelectionApiUtile';
import SubcategorySelector from './SubcategorySelector ';
import CategorySelector from './CategorySelector ';
import ProductSelector from './ProductSelector ';
import CloseIcon from '@mui/icons-material/Close';

const ProductModal = ({ open, onClose, onProductAdd }) => {
  const [categories, setCategories] = useState([]);
  const [subcategories, setSubcategories] = useState([]);
  const [products, setProducts] = useState([]);
  const [selectedCategory, setSelectedCategory] = useState(null);
  const [selectedSubcategory, setSelectedSubcategory] = useState(null);
  const [selectedProduct, setSelectedProduct] = useState(null);
  const [step, setStep] = useState(1);
 
  useEffect(() => {
    const fetchData = async () => {
      try {
        const categoriesData = await fetchCategories();
        setCategories(categoriesData);
      } catch (error) {
        console.error("Failed to fetch categories:", error);
      }
    };
    fetchData();
  }, []);

  useEffect(() => {
    if (selectedCategory) {
      const fetchSubcategories = () => {
        const category = categories.find(cat => cat.id === selectedCategory.id);
        setSubcategories(category ? category.subcategories : []);
      };
      fetchSubcategories();
    }
  }, [selectedCategory, categories]);

  useEffect(() => {
    if (selectedSubcategory) {
      const fetchProducts = async () => {
        try {
          const category = categories.find(cat => cat.id === selectedCategory.id);
          const subcategory = category.subcategories.find(sub => sub.id === selectedSubcategory.id);
          setProducts(subcategory ? subcategory.products : []);
        } catch (error) {
          console.error("Failed to fetch products:", error);
        }
      };
      fetchProducts();
    }
  }, [selectedSubcategory, selectedCategory, categories]);

  const handleCategorySelect = (category) => {
    setSelectedCategory(category);
    setStep(2);
  };

  const handleSubcategorySelect = (subcategory) => {
    setSelectedSubcategory(subcategory);
    setStep(3);
  };

  const handleProductSelect = (product) => {
    setSelectedProduct(product);
  };

  const handleAddProduct = () => {
    setSelectedProduct("");
    if (selectedProduct) {
      onProductAdd(selectedProduct);
      onClose();
    }
  };

  return ( <>
    <Modal open={open} onClose={onClose}>
        
      <Box style={{  backgroundColor: 'white', margin: 'auto', marginTop: '10%', maxWidth: '600px' }}>
      <Box  sx={{
          backgroundColor: "#7986cb",
          color: "white",
          textAlign: "center",
          fontSize: "16px",
          fontWeight: "500",
          padding: "14px",
          fontFamily: "Poppins, sans-serif",
        }}>
        <div
          style={{
            display: "flex",
            justifyContent: "space-between",
            fontFamily: "Poppins, sans-serif",
            fontWeight: "bold",
          }}
        >
          <b>Select Product</b>
          <CloseIcon onClick={onClose} style={{ cursor: "pointer" }} />
        </div>
        </Box>
       
        {step === 1 && (
          <Box style={{padding:'10px'}}>
            <Typography style={{color:'#6877c7',padding:'5px'}} variant="h6">Category</Typography>
            <CategorySelector categories={categories} onCategorySelect={handleCategorySelect} />
          </Box>
        )}

        {step === 2 && (
         <Box style={{padding:'10px'}}>
            <Button style={{
  borderBottom: '1px solid #e4e4e4',
  boxShadow: '0 .125rem .25rem rgba(0, 0, 0, .075)',
  background: '#ffffff'
}}
 onClick={() => setStep(1)} startIcon={<ArrowBack />}>Back</Button>
            <Typography style={{color:'#6877c7',padding:'5px'}} variant="h6">Subcategories</Typography>
            <SubcategorySelector subcategories={subcategories} onSubcategorySelect={handleSubcategorySelect} />
          </Box>
        )}

        {step === 3 && (
         <Box style={{padding:'10px' ,paddingBottom:'20px',}}>
            <Button  style={{
  borderBottom: '1px solid #e4e4e4',
  boxShadow: '0 .125rem .25rem rgba(0, 0, 0, .075)',
  background: '#ffffff',
 
    maxHeight: '300px',  
    overflowY: 'auto',   

}}
 onClick={() => setStep(2)} startIcon={<ArrowBack />}>Back</Button>
            <Typography style={{color:'#6877c7',padding:'5px'}} variant="h6">Products</Typography>
            <ProductSelector products={products} onProductSelect={handleProductSelect} />
          </Box>
        )}

        {selectedProduct && (
          <Box >
            <Typography variant="h6">Selected Product</Typography>
            <List>
              <ListItem>
                <ListItemIcon>
                <img
  src={selectedProduct.image}
  alt={selectedProduct.name}
  style={{
    width: '100px',
    height: '100px',
    borderRadius: '1rem',
    objectFit: 'cover',
      
  }}
/>

                </ListItemIcon>
                
              </ListItem>
            </List>
            <Button onClick={handleAddProduct} disabled={!selectedProduct}>Add Product</Button>
          </Box>
        )}
      </Box>
    </Modal>
    </>
  );
};

export default ProductModal;











































// it allow multi

// import React, { useState, useEffect } from 'react';
// import { Modal, Box, Typography, Button, List, ListItem, ListItemText, ListItemIcon } from '@mui/material';
// import { ArrowBack } from '@mui/icons-material';
// import { fetchCategories } from '../../../../utils/SelectionApiUtile';
// import SubcategorySelector from './SubcategorySelector ';
// import CategorySelector from './CategorySelector ';
// import ProductSelector from './ProductSelector ';
// import CloseIcon from '@mui/icons-material/Close';
// import AddIcon from '@mui/icons-material/Add';
// const ProductModal = ({ open, onClose, onProductAdd }) => {
//   const [categories, setCategories] = useState([]);
//   const [subcategories, setSubcategories] = useState([]);
//   const [products, setProducts] = useState([]);
//   const [selectedCategory, setSelectedCategory] = useState(null);
//   const [selectedSubcategory, setSelectedSubcategory] = useState(null);
//   const [selectedProducts, setSelectedProducts] = useState([]);
//   const [step, setStep] = useState(1);

//   useEffect(() => {
//     const fetchData = async () => {
//       try {
//         const categoriesData = await fetchCategories();
//         setCategories(categoriesData);
//       } catch (error) {
//         console.error("Failed to fetch categories:", error);
//       }
//     };
//     fetchData();
//   }, []);

//   useEffect(() => {
//     if (selectedCategory) {
//       const fetchSubcategories = () => {
//         const category = categories.find(cat => cat.id === selectedCategory.id);
//         setSubcategories(category ? category.subcategories : []);
//       };
//       fetchSubcategories();
//     }
//   }, [selectedCategory, categories]);

//   useEffect(() => {
//     if (selectedSubcategory) {
//       const fetchProducts = () => {
//         const category = categories.find(cat => cat.id === selectedCategory.id);
//         const subcategory = category.subcategories.find(sub => sub.id === selectedSubcategory.id);
//         setProducts(subcategory ? subcategory.products : []);
//       };
//       fetchProducts();
//     }
//   }, [selectedSubcategory, selectedCategory, categories]);

//   const handleCategorySelect = (category) => {
//     setSelectedCategory(category);
//     setStep(2);
//   };

//   const handleSubcategorySelect = (subcategory) => {
//     setSelectedSubcategory(subcategory);
//     setStep(3);
//   };

//   const handleProductSelect = (products) => {
//     setSelectedProducts(products);
//   };

//   const handleAddProducts = () => {
   
//       onProductAdd(selectedProducts);
//       console.log("select pro",selectedProducts)
//       onClose();
    
//   };

//   return (
//     <Modal open={open} onClose={onClose}>
//       <Box style={{ backgroundColor: 'white', margin: 'auto', marginTop: '10%', maxWidth: '600px' }}>
//         <Box sx={{
//           backgroundColor: "#7986cb",
//           color: "white",
//           textAlign: "center",
//           fontSize: "16px",
//           fontWeight: "500",
//           padding: "14px",
//           fontFamily: "Poppins, sans-serif",
//         }}>
//           <div
//             style={{
//               display: "flex",
//               justifyContent: "space-between",
//               fontFamily: "Poppins, sans-serif",
//               fontWeight: "bold",
//             }}
//           >
//             <b>Select Product</b>
//             <CloseIcon onClick={onClose} style={{ cursor: "pointer" }} />
//           </div>
//         </Box>

//         {step === 1 && (
//           <Box style={{ padding: '10px' }}>
//             <Typography style={{ color: '#6877c7', padding: '5px' }} variant="h6">Category</Typography>
//             <CategorySelector categories={categories} onCategorySelect={handleCategorySelect} />
//           </Box>
//         )}

//         {step === 2 && (
//           <Box style={{ padding: '10px' }}>
//             <Button
//               style={{
//                 borderBottom: '1px solid #e4e4e4',
//                 boxShadow: '0 .125rem .25rem rgba(0, 0, 0, .075)',
//                 background: '#ffffff'
//               }}
//               onClick={() => setStep(1)} startIcon={<ArrowBack />}
//             >
//               Back
//             </Button>
//             <Typography style={{ color: '#6877c7', padding: '5px' }} variant="h6">Subcategories</Typography>
//             <SubcategorySelector subcategories={subcategories} onSubcategorySelect={handleSubcategorySelect} />
//           </Box>
//         )}

//         {step === 3 && (
//           <Box style={{ padding: '10px' }}>
//             <Button
//               style={{
//                 borderBottom: '1px solid #e4e4e4',
//                 boxShadow: '0 .125rem .25rem rgba(0, 0, 0, .075)',
//                 background: '#ffffff'
//               }}
//               onClick={() => setStep(2)} startIcon={<ArrowBack />}
//             >
//               Back
//             </Button>
//             <Typography style={{ color: '#6877c7', padding: '5px' }} variant="h6">Products</Typography>
//             <ProductSelector products={products} onProductSelect={handleProductSelect} />
//           </Box>
//         )}

//         {selectedProducts.length > 0 && (
//           <Box>
//             <Typography variant="h6">Selected Products</Typography>
//             <List>
//               {selectedProducts.map(product => (
//                 <ListItem key={product.id}>
//                   <ListItemIcon>
//                     <img src={product.image} alt={product.name} style={{ width: '50px', height: '50px' }} />
//                   </ListItemIcon>
//                   <ListItemText primary={product.name} secondary={`$${product.price.toFixed(2)}`} />
//                 </ListItem>
//               ))}
//             </List>
//             <div style={{padding:'10px',justifyContent:'end',display:"flex"}}>
//             <button type="button"   onClick={handleAddProducts} class="btn btn-outline-purple d-flex justify-content-between align-items-center px-4" fdprocessedid="jokde">
//             <AddIcon className='me-2'/> ADD PRODUCT</button>
           
//             </div>



//           </Box>
//         )}
//       </Box>
//     </Modal>
//   );
// };

// export default ProductModal;
