


// import React, { useState } from 'react';
// import { Grid, Box, Typography, TextField, IconButton, Dialog, DialogTitle, DialogContent } from '@mui/material';
// import YouTubeIcon from '@mui/icons-material/YouTube';
// import ClearAllIcon from '@mui/icons-material/ClearAll';
// import ContentPasteIcon from '@mui/icons-material/ContentPaste';
// import DoneIcon from '@mui/icons-material/Done';
// import { useTheme } from "@mui/material/styles";
// import CloseIcon from "@mui/icons-material/Close";

// const YouTubeURLInput = () => {
//   const [url, setUrl] = useState('');
//   const [isPopupOpen, setIsPopupOpen] = useState(false);
//   const theme = useTheme();
//   const [errorMessage, setErrorMessage] = useState('');
//   const [isValidURL, setIsValidURL] = useState(false); // New state to track URL validity

//   // Check if the URL is a valid YouTube URL
//   const isValidYouTubeURL = (urlString) => {
//     try {
//       const urlObj = new URL(urlString);
//       const validHostnames = ['youtube.com', 'www.youtube.com', 'youtu.be'];
//       const hostname = urlObj.hostname;
//       return validHostnames.some(validHostname => hostname.endsWith(validHostname));
//     } catch (error) {
//       return false;
//     }
//   };

//   const handleUrlChange = (e) => {
//     const newUrl = e.target.value;
//     setUrl(newUrl);
//     setIsValidURL(isValidYouTubeURL(newUrl)); // Update validity based on the new URL
//   };

//   const handleApplyClick = () => {
//     if (url.trim() === '') {
//       setErrorMessage('URL cannot be empty. Please enter a valid YouTube URL.');
//       return;
//     }

//     if (isValidURL) {
//       try {
//         setErrorMessage(''); 
//         setIsPopupOpen(true); 
//       } catch (error) {
//         setErrorMessage('An error occurred while processing the URL.');
//       }
//     } else {
//       setErrorMessage('Invalid YouTube URL. Please enter a valid YouTube URL.');
//     }
//   };

//   const handleClosePopup = () => {
//     setIsPopupOpen(false); // Close the popup
//   };

//   const handleClearClick = () => {
//     setUrl(''); // Clear the input box
//     setErrorMessage(''); // Clear any existing error message
//     setIsValidURL(false); // Reset the URL validity
//   };

//   const handlePasteClick = async () => {
//     try {
//       const text = await navigator.clipboard.readText();
//       if (isValidYouTubeURL(text)) {
//         setUrl(text);
//         setIsValidURL(true);
//         setErrorMessage(''); // Clear any existing error message
//       } else {
//         setErrorMessage('Invalid YouTube URL. Pasting not allowed.');
//       }
//     } catch (error) {
//       console.error('Failed to read clipboard contents: ', error);
//     }
//   };

//   // Extract the YouTube video ID from the URL
//   const getYouTubeVideoId = (youtubeUrl) => {
//     try {
//       const urlObj = new URL(youtubeUrl);
//       return urlObj.searchParams.get('v') || urlObj.pathname.split('/').pop();
//     } catch (error) {
//       console.error('Failed to extract YouTube video ID:', error);
//       return '';
//     }
//   };

//   // Generate the embed URL
//   const getYouTubeEmbedUrl = (youtubeUrl) => {
//     const videoId = getYouTubeVideoId(youtubeUrl);
//     return videoId ? `https://www.youtube.com/embed/${videoId}` : '';
//   };

//   return (
//     <>
//       <Grid item xs={12} style={{ padding: '16px 16px' }}>
//         <Box
//           className="marginBottom5"
//           sx={{
//             display: 'flex',
//             alignItems: 'center',
//             justifyContent: 'space-between',
//             width: '100%',
//           }}
//         >
//           <Box className="marginBottom5" sx={{ display: 'flex', alignItems: 'center' }}>
//             <YouTubeIcon
//               style={{
//                 marginRight: '5px',
//                 fontSize: '20px',
//                 color: 'red',
//               }}
//             />
//             <Typography variant="body1" style={{ fontFamily: 'Poppins, sans-serif', color: theme.palette.primary.text }}>
//               <b>YouTube</b>
//             </Typography>
//           </Box>
//           <Box>
//             {/* Optional: Add any other actions here */}
//           </Box>
//         </Box>
//         <Typography style={{ fontFamily: 'Poppins, sans-serif' }} variant="body2">
//           Copy URL of a YouTube video and paste it here.
//         </Typography>
//         <TextField
//           fullWidth
//           variant="outlined"
//           placeholder="Paste YouTube URL here"
//           style={{ marginTop: '10px' }}
//           value={url}
//           onChange={handleUrlChange} // Use handleUrlChange instead of setUrl directly
//           error={!!errorMessage} // Show error state if there's an error message
//           helperText={errorMessage}
//           sx={{
//             marginTop: '10px',
//             borderRadius: '8px', // Set the border radius
//             '& .MuiOutlinedInput-root': {
//               borderRadius: '8px', // Border radius for the input box
//               padding: '10px', // Padding inside the input box
//             },
//             '& .MuiInputBase-input': {
//               padding: '0px', // Padding for the text inside the input box
//             },
//           }} // Display the error message below the input field
//         />
//       </Grid>

//       <Grid container spacing={2} padding="25px 40px">
//         <Grid
//           onClick={handleClearClick}
//           item
//           xs={4}
//           style={{ display: "flex", alignItems: "center", padding: '0px' }}
//         >
//           <IconButton style={{ padding: '0px' }}>
//             <ClearAllIcon 
//               style={{ 
//                 marginRight: "3px", 
//                 color: theme.palette.error.main // Color for the Clear icon
//               }} 
//             />
//           </IconButton>
//           <Typography style={{ color: theme.palette.error.main }}>Clear</Typography>
//         </Grid>

//         <Grid
//           item
//           onClick={handlePasteClick}
//           xs={4}
//           style={{
//             display: "flex",
//             justifyContent: "center",
//             alignItems: "center",
//             padding: '0px'
//           }}
//         >
//           <IconButton style={{ padding: '0px' }}>
//             <ContentPasteIcon 
//               style={{ 
//                 marginRight: "3px", 
//                 color: theme.palette.info.main // Color for the Paste icon
//               }} 
//             />
//           </IconButton>
//           <Typography style={{ color: theme.palette.info.main }}>Paste</Typography>
//         </Grid>

//         <Grid
//           onClick={handleApplyClick}
//           item
//           xs={4}
//           style={{
//             display: "flex",
//             justifyContent: "flex-end",
//             alignItems: "center",
//             padding: '0px',
//             opacity: isValidURL ? 1 : 0.5, // Adjust opacity based on URL validity
//             pointerEvents: isValidURL ? 'auto' : 'none' // Disable clicking if not valid
//           }}
//         >
//           <IconButton style={{ padding: '0px' }}>
//             <DoneIcon 
//               style={{ 
//                 marginRight: "3px", 
//                 color: theme.palette.success.main // Color for the Apply icon
//               }} 
//             />
//           </IconButton>
//           <Typography style={{ color: theme.palette.success.main }}>Apply</Typography>
//         </Grid>
//       </Grid>

//       {/* Popup Dialog to Display the YouTube Video */}
//       <Dialog open={isPopupOpen} onClose={handleClosePopup} maxWidth="md" fullWidth>
//         <DialogTitle style={{ padding: "16px" }}>
//           <div
//             style={{
//               display: "flex",
//               justifyContent: "space-between",
//               fontFamily: "Poppins, sans-serif",
//               fontWeight: "bold",
//             }}
//           >
//             <span></span>
//             <CloseIcon onClick={handleClosePopup} />
//           </div>
//         </DialogTitle>
//         <DialogContent>
//           {url ? (
//             <Box sx={{ position: 'relative', paddingBottom: '56.25%', height: 0, overflow: 'hidden' }}>
//               <iframe
//                 src={getYouTubeEmbedUrl(url)}
//                 frameBorder="0"
//                 allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture"
//                 allowFullScreen
//                 title="YouTube video"
//                 style={{
//                   position: 'absolute',
//                   top: 0,
//                   left: 0,
//                   width: '100%',
//                   height: '100%',
//                 }}
//               ></iframe>
//             </Box>
//           ) : (
//             <Typography>No URL pasted yet.</Typography>
//           )}
//         </DialogContent>
//       </Dialog>
//     </>
//   );
// };

// export default YouTubeURLInput;
import React, { useState } from 'react';
import { Grid, Box, Typography, TextField, IconButton, Dialog, DialogTitle, DialogContent, } from '@mui/material';
import YouTubeIcon from '@mui/icons-material/YouTube';
import ClearAllIcon from '@mui/icons-material/ClearAll';
import ContentPasteIcon from '@mui/icons-material/ContentPaste';
// import DoneIcon from '@mui/icons-material/Done';
// import DeleteIcon from '@mui/icons-material/Delete';
import { useTheme } from "@mui/material/styles";
import CloseIcon from "@mui/icons-material/Close";
import VideoListModal from './VideoListModel';
import AddIcon from '@mui/icons-material/Add';
import RemoveRedEyeIcon from '@mui/icons-material/RemoveRedEye';
const YouTubeURLInput = () => {
  const [url, setUrl] = useState('');
  const [videos, setVideos] = useState([]); // State to hold multiple videos
  const [isPopupOpen, setIsPopupOpen] = useState(false);
  const [selectedVideo, setSelectedVideo] = useState(''); // State to track the video being viewed
  const theme = useTheme();
  const [errorMessage, setErrorMessage] = useState('');
  const [isValidURL, setIsValidURL] = useState(false);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const isValidYouTubeURL = (urlString) => {
    try {
      const urlObj = new URL(urlString);
      const validHostnames = ['youtube.com', 'www.youtube.com', 'youtu.be'];
      const hostname = urlObj.hostname;
  
      // Check if the hostname is valid
      if (!validHostnames.some(validHostname => hostname.endsWith(validHostname))) {
        return false;
      }
  
      // Check if the URL contains a valid video ID
      const videoId = getYouTubeVideoId(urlString);
      return videoId.length === 11; // YouTube video IDs are typically 11 characters long
    } catch (error) {
      return false;
    }
  };
  const handleOpenModal = () => {
    setIsModalOpen(true);
  };

  const handleCloseModal = () => {
    setIsModalOpen(false);
  };
  const handleUrlChange = (e) => {
    const newUrl = e.target.value;
    setUrl(newUrl);
    setIsValidURL(isValidYouTubeURL(newUrl));
  };

  const handleApplyClick = () => {
    if (url.trim() === '') {
      setErrorMessage('URL cannot be empty. Please enter a valid YouTube URL.');
      return;
    }
  
    if (isValidYouTubeURL(url)) {
      setVideos([...videos, url]);
      setUrl('');
      setErrorMessage('');
      setIsValidURL(false);
    } else {
      setErrorMessage('Invalid YouTube URL. Please enter a valid YouTube URL.');
    }
  };
  
  const handleClearClick = () => {
    setUrl('');
    setErrorMessage('');
    setIsValidURL(false);
  };

  const handlePasteClick = async () => {
    try {
      const text = await navigator.clipboard.readText();
      if (isValidYouTubeURL(text)) {
        setUrl(text);
        setIsValidURL(true);
        setErrorMessage('');
      } else {
        setErrorMessage('Invalid YouTube URL. Pasting not allowed.');
      }
    } catch (error) {
      console.error('Failed to read clipboard contents: ', error);
    }
  };

  const handleViewVideo = (videoUrl) => {
    setSelectedVideo(videoUrl);
    setIsPopupOpen(true);
  };

  const handleDeleteVideo = (videoUrl) => {
    setVideos(videos.filter(video => video !== videoUrl));
  };

  const handleClosePopup = () => {
    setIsPopupOpen(false);
    setSelectedVideo('');
  };

  const getYouTubeVideoId = (youtubeUrl) => {
    try {
      const urlObj = new URL(youtubeUrl);
      // For standard YouTube URLs
      const videoId = urlObj.searchParams.get('v') || urlObj.pathname.split('/').pop();
      
      // For shortened YouTube URLs
      if (urlObj.hostname === 'youtu.be') {
        return urlObj.pathname.slice(1);
      }
  
      return videoId || '';
    } catch (error) {
      console.error('Failed to extract YouTube video ID:', error);
      return '';
    }
  };
  

  const getYouTubeEmbedUrl = (youtubeUrl) => {
    const videoId = getYouTubeVideoId(youtubeUrl);
    return videoId ? `https://www.youtube.com/embed/${videoId}` : '';
  };

  return (
    <>
      <Grid item xs={12} style={{ padding: '16px 16px' }}>
        <Box
          className="marginBottom5"
          sx={{
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'space-between',
            width: '100%',
          }}
        >
          <Box className="marginBottom5" sx={{ display: 'flex', alignItems: 'center' }}>
            <YouTubeIcon
              style={{
                marginRight: '5px',
                fontSize: '20px',
                color: 'red',
              }}
            />
            <Typography variant="body1" style={{ fontFamily: 'Poppins, sans-serif', color: theme.palette.primary.text }}>
              <b>YouTube</b>
            </Typography>
          </Box>
          <Box className="marginBottom5" sx={{ display: 'flex', alignItems: 'center' }}>
            <RemoveRedEyeIcon
              style={{
                marginRight: '5px',
                fontSize: '20px',
                color: 'rgb(1, 70, 1)',
              }}
            />
            <Typography  onClick={handleOpenModal} variant="body1" style={{ fontFamily: 'Poppins, sans-serif', color: theme.palette.primary.text }}>
              <b>View</b>
            </Typography>
          </Box>
        </Box>
        <Typography style={{ fontFamily: 'Poppins, sans-serif' }} variant="body2">
          Copy URL of a YouTube video and paste it here.
        </Typography>
        <TextField
          fullWidth
          variant="outlined"
          placeholder="Paste YouTube URL here"
          style={{ marginTop: '10px' }}
          value={url}
          onChange={handleUrlChange}
          error={!!errorMessage}
          helperText={errorMessage}
          sx={{
            marginTop: '10px',
            borderRadius: '8px',
            '& .MuiOutlinedInput-root': {
              borderRadius: '8px',
              padding: '10px',
            },
            '& .MuiInputBase-input': {
              padding: '0px',
            },
          }}
        />
      </Grid>

      <Grid container spacing={2} padding="25px 40px">
        <Grid
          onClick={handleClearClick}
          item
          xs={4}
          style={{ display: "flex", alignItems: "center", padding: '0px' }}
        >
          <IconButton style={{ padding: '0px' }}>
            <ClearAllIcon 
              style={{ 
                marginRight: "3px", 
                color: theme.palette.error.main 
              }} 
            />
          </IconButton>
          <Typography style={{ color: theme.palette.error.main }}>Clear</Typography>
        </Grid>

        <Grid
          item
          onClick={handlePasteClick}
          xs={4}
          style={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            padding: '0px'
          }}
        >
          <IconButton style={{ padding: '0px' }}>
            <ContentPasteIcon 
              style={{ 
                marginRight: "3px", 
                color: theme.palette.info.main 
              }} 
            />
          </IconButton>
          <Typography style={{ color: theme.palette.info.main }}>Paste</Typography>
        </Grid>

        <Grid
          onClick={handleApplyClick}
          item
          xs={4}
          style={{
            display: "flex",
            justifyContent: "flex-end",
            alignItems: "center",
            padding: '0px',
            opacity: isValidURL ? 1 : 0.5,
            pointerEvents: isValidURL ? 'auto' : 'none'
          }}
        >
          <IconButton style={{ padding: '0px' }}>
            <AddIcon  
              style={{ 
                marginRight: "3px", 
                color: theme.palette.success.main 
              }} 
            />
          </IconButton>
          <Typography style={{ color: theme.palette.success.main }}>Add</Typography>
        </Grid>
      </Grid>

      {/* List of Videos */}
      <VideoListModal
        open={isModalOpen}
        handleClose={handleCloseModal}
        videos={videos}
        handleViewVideo={handleViewVideo}
        handleDeleteVideo={handleDeleteVideo}
      />
      {/* <Box sx={{ padding: '16px' }}>
        <Typography variant="h6">Videos List</Typography>
        <List>
          {videos.map((video, index) => (
            <ListItem key={index}>
              <ListItemText primary={video} />
              <ListItemSecondaryAction>
                <IconButton edge="end" aria-label="view" onClick={() => handleViewVideo(video)}>
                  <YouTubeIcon />
                </IconButton>
                <IconButton edge="end" aria-label="delete" onClick={() => handleDeleteVideo(video)}>
                  <DeleteIcon />
                </IconButton>
              </ListItemSecondaryAction>
            </ListItem>
          ))}
        </List>
      </Box> */}

      {/* Popup Dialog to Display the YouTube Video */}
      <Dialog open={isPopupOpen} onClose={handleClosePopup} maxWidth="md" fullWidth>
        <DialogTitle style={{ padding: "16px" }}>
          <div
            style={{
              display: "flex",
              justifyContent: "space-between",
              fontFamily: "Poppins, sans-serif",
              fontWeight: "bold",
            }}
          >
            <span></span>
            <CloseIcon onClick={handleClosePopup} />
          </div>
        </DialogTitle>
        <DialogContent>
          {selectedVideo ? (
            <Box sx={{ position: 'relative', paddingBottom: '56.25%', height: 0, overflow: 'hidden' }}>
              <iframe
                src={getYouTubeEmbedUrl(selectedVideo)}
                title="YouTube Video"
                style={{ position: 'absolute', top: 0, left: 0, width: '100%', height: '100%' }}
                frameBorder="0"
                allowFullScreen
              />
            </Box>
          ) : (
            <Typography>No video selected</Typography>
          )}
        </DialogContent>
      </Dialog>
    </>
  );
};

export default YouTubeURLInput;
