// import React from 'react';
// import { Stepper, Step, Box, Button, Grid } from '@mui/material';
// import CheckCircleIcon from '@mui/icons-material/CheckCircle';

// // Custom Button Component
// const CustomButton = ({ label, value, currentStatus, handleClick, buttonClicked }) => (
//   <Button
//     variant="contained"
//     className={buttonClicked === value || currentStatus === value ? `${value.toLowerCase()}` : "order"}
//     onClick={() => handleClick(value)}
//     sx={{ 
//       display: 'flex', 
//       justifyContent: 'center', 
//       alignItems: 'center', 
//    padding: '6px', 
//       marginRight: '10px', 
//       height: '50px', 
//       width: '150px',
//     }}
//   >
//     {buttonClicked === value || currentStatus === value ? (
//       <>
//         <Box sx={{ display: 'flex', alignItems: 'center', padding: '8px' }}>
//           <CheckCircleIcon sx={{ fontSize: '20px' }} />
//         </Box>
//         <Box sx={{ display: 'flex', alignItems: 'left', padding: '6px', flexGrow: 1, justifyContent: 'left' }}>
//           {label}
//         </Box>
//       </>
//     ) : (
//       <Box sx={{ display: 'flex', alignItems: 'center', padding: '6px', flexGrow: 1, justifyContent: 'center' }}>
//         {label}
//       </Box>
//     )}
//   </Button>
  
// );

// const OrderStatusStepper = ({ currentStatus, buttonClicked, handleButtonClick }) => {
//   const getStepIndex = (value) => {
//     switch (value) {
//       case 'Reday to ship':
//         return 0;
//       case 'SHIPPED':
//         return 1;
//       case 'DELIVERED':
//         return 2;
//       case 'RETURNED':
//         return 3;
//       case 'COMPLETED':
//         return 4;
//       default:
//         return -1; // Handle the default case if needed
//     }
//   };

//   return (
//     <>
//       <Box
//         sx={{
//           display: 'flex',
//           flexDirection: 'row',
//           justifyContent: 'center',
//           marginLeft:'15px',
//           marginBottom: '16px', // Space between action buttons and status steps
//         }}
//       >
//         <CustomButton
//           label="Accept"
//           value="ACCEPTED"
//           currentStatus={currentStatus}
//           buttonClicked={buttonClicked}
//           handleClick={handleButtonClick}
//         />
//         <CustomButton
//           label="Reject"
//           value="REJECTED"
//           currentStatus={currentStatus}
//           buttonClicked={buttonClicked}
//           handleClick={handleButtonClick}
//         />
//       </Box>

//       <Stepper
//         activeStep={getStepIndex(currentStatus)}
//         orientation="vertical"
//         sx={{
//           '& .MuiStep-root': {
//             display: 'flex',
//             alignItems: 'center',
//           },
//           '& .MuiStepLabel-root': {
//             marginLeft: '10px',
//             display: 'flex',
//             alignItems: 'center',
//           },
//         }}
//       >
//         <Step>
//           <Grid container alignItems="center" spacing={2}>
//             <Grid item xs={2}>
//               <Box
//                 sx={{
//                   width: '24px',
//                   height: '24px',
//                   display: 'flex',
//                   alignItems: 'center',
//                   justifyContent: 'center',
//                   borderRadius: '50%',
//                   border: '2px solid #bdbdbd',
//                   backgroundColor: '#fff',
//                   fontSize: '14px',
//                   textAlign: 'center',
//                 }}
//               >
//                 1
//               </Box>
//               <Box
//                 sx={{
//                   borderLeft: '4px solid #bdbdbd',
//                   height: '50px',
//                   marginLeft: '10px',
//                 }}
//               />
//             </Grid>
//             <Grid item xs={10} style={{padding:'0px'}}>
//               <CustomButton
//                 label="Ready to Ship"
//                 value="Reday to ship"
//                 currentStatus={currentStatus}
//                 buttonClicked={buttonClicked}
//                 handleClick={handleButtonClick}
//               />
//             </Grid>
//           </Grid>
//         </Step>
//         <Step>
//           <Grid container alignItems="center" spacing={2}>
//             <Grid item xs={2}>
//               <Box
//                 sx={{
//                   width: '24px',
//                   height: '24px',
//                   display: 'flex',
//                   alignItems: 'center',
//                   justifyContent: 'center',
//                   borderRadius: '50%',
//                   border: '2px solid #bdbdbd',
//                   backgroundColor: '#fff',
//                   fontSize: '14px',
//                   textAlign: 'center',
//                 }}
//               >
//                 2
//               </Box>
//               <Box
//                 sx={{
//                   borderLeft: '4px solid #bdbdbd',
//                   height: '50px',
//                   marginLeft: '10px',
//                 }}
//               />
//             </Grid>
//             <Grid item xs={10} style={{padding:'0px'}}>
//               <CustomButton
//                 label="Shipped"
//                 value="SHIPPED"
//                 currentStatus={currentStatus}
//                 buttonClicked={buttonClicked}
//                 handleClick={handleButtonClick}
//               />
//             </Grid>
//           </Grid>
//         </Step>
//         <Step>
//           <Grid container alignItems="center" spacing={2}>
//             <Grid item xs={2}>
//               <Box
//                 sx={{
//                   width: '24px',
//                   height: '24px',
//                   display: 'flex',
//                   alignItems: 'center',
//                   justifyContent: 'center',
//                   borderRadius: '50%',
//                   border: '2px solid #bdbdbd',
//                   backgroundColor: '#fff',
//                   fontSize: '14px',
//                   textAlign: 'center',
//                 }}
//               >
//                 3
//               </Box>
//               <Box
//                 sx={{
//                   borderLeft: '4px solid #bdbdbd',
//                   height: '50px',
//                   marginLeft: '10px',
//                 }}
//               />
//             </Grid>
//             <Grid item xs={10} style={{padding:'0px'}}>
//               <CustomButton
//                 label="Delivered"
//                 value="DELIVERED"
//                 currentStatus={currentStatus}
//                 buttonClicked={buttonClicked}
//                 handleClick={handleButtonClick}
//               />
//             </Grid>
//           </Grid>
//         </Step>
//         <Step>
//           <Grid container alignItems="center" spacing={2}>
//             <Grid item xs={2}>
//               <Box
//                 sx={{
//                   width: '24px',
//                   height: '24px',
//                   display: 'flex',
//                   alignItems: 'center',
//                   justifyContent: 'center',
//                   borderRadius: '50%',
//                   border: '2px solid #bdbdbd',
//                   backgroundColor: '#fff',
//                   fontSize: '14px',
//                   textAlign: 'center',
//                 }}
//               >
//                 4
//               </Box>
//               <Box
//                 sx={{
//                   borderLeft: '4px solid #bdbdbd',
//                   height: '50px',
//                   marginLeft: '10px',
//                 }}
//               />
//             </Grid>
//             <Grid item xs={10} style={{padding:'0px'}}>
//               <CustomButton
//                 label="Return"
//                 value="RETURNED"
//                 currentStatus={currentStatus}
//                 buttonClicked={buttonClicked}
//                 handleClick={handleButtonClick}
//               />
//             </Grid>
//           </Grid>
//         </Step>
//         <Step>
//           <Grid container alignItems="center" spacing={2}>
//             <Grid item xs={2}>
//               <Box
//                 sx={{
//                   width: '24px',
//                   height: '24px',
//                   display: 'flex',
//                   alignItems: 'center',
//                   justifyContent: 'center',
//                   borderRadius: '50%',
//                   border: '2px solid #bdbdbd',
//                   backgroundColor: '#fff',
//                   fontSize: '14px',
//                   textAlign: 'center',
//                 }}
//               >
//                 5
//               </Box>
//               <Box
//                 sx={{
//                   borderLeft: '4px solid #bdbdbd',
//                   height: '50px',
//                   marginLeft: '10px',
//                 }}
//               />
//             </Grid>
//             <Grid item xs={10} style={{padding:'0px'}}>
//               <CustomButton
//                 label="Completed"
//                 value="COMPLETED"
//                 currentStatus={currentStatus}
//                 buttonClicked={buttonClicked}
//                 handleClick={handleButtonClick}
//               />
//             </Grid>
//           </Grid>
//         </Step>
//       </Stepper>
//     </>
//   );
// };

// export default OrderStatusStepper;





// import React from 'react';
// import { Stepper, Step, Box, Button, Grid } from '@mui/material';
// import CheckCircleIcon from '@mui/icons-material/CheckCircle';
// const STEP_ORDER = [
//   'Ready to ship',
//   'shipped',
//   'delivered',
//   'RETURNED',
//   'COMPLETED',
// ];

// const STEP_LABELS = {
//   'Ready to ship': 'Ready to Ship',
//   'shipped': 'Shipped',
//   'delivered': 'Delivered',
//   'RETURNED': 'Return',
//   'COMPLETED': 'Completed',
// };

// const CustomButton = ({ label, value, currentStatus, handleClick, buttonClicked }) => (
//   <Button
//     variant="contained"
//     className={buttonClicked === value || currentStatus === value ? `${value.toLowerCase()}` : "order"}
//     onClick={() => handleClick(value)}
//     sx={{ 
//       display: 'flex', 
//       justifyContent: 'center', 
//       alignItems: 'center', 
//       padding: '6px', 
//       marginRight: '10px', 
//       height: '50px', 
//       width: '150px',
//     }}
//   >
//     {buttonClicked === value || currentStatus === value ? (
//       <>
//         <Box sx={{ display: 'flex', alignItems: 'center', padding: '8px' }}>
//           <CheckCircleIcon sx={{ fontSize: '20px' }} />
//         </Box>
//         <Box sx={{ display: 'flex', alignItems: 'left', padding: '6px', flexGrow: 1, justifyContent: 'left',whiteSpace: 'nowrap' }}>
//           {label}
//         </Box>
//       </>
//     ) : (
//       <Box sx={{ display: 'flex', alignItems: 'center', padding: '6px', flexGrow: 1, justifyContent: 'center' }}>
//         {label}
//       </Box>
//     )}
//   </Button>
// );

// const OrderStatusStepper = ({ currentStatus, buttonClicked, handleButtonClick }) => {
//   const getStepIndex = (value) => {
//     return STEP_ORDER.indexOf(value);
//   };

//   const getVisibleButtons = (status) => {
//     const index = STEP_ORDER.indexOf(status);
//     return STEP_ORDER.slice(index);
//   };

//   const visibleButtons = getVisibleButtons(currentStatus);

//   return (
//     <>
//       <Stepper
//         activeStep={getStepIndex(currentStatus)}
//         orientation="vertical"
//         sx={{
//           '& .MuiStep-root': {
//             display: 'flex',
//             alignItems: 'center',
//           },
//           '& .MuiStepLabel-root': {
//             marginLeft: '10px',
//             display: 'flex',
//             alignItems: 'center',
//           },
//         }}
//       >
//         {visibleButtons.map((step, index) => (
//           <Step key={step}>
//             <Grid container alignItems="center" spacing={2}>
//               <Grid item xs={2}>
//                 <Box
//                   sx={{
//                     width: '24px',
//                     height: '24px',
//                     display: 'flex',
//                     alignItems: 'center',
//                     justifyContent: 'center',
//                     borderRadius: '50%',
//                     border: '2px solid #bdbdbd',
//                     backgroundColor: '#fff',
//                     fontSize: '14px',
//                     textAlign: 'center',
//                   }}
//                 >
//                   {index + 1}
//                 </Box>
//                 <Box
//                   sx={{
//                     borderLeft: '4px solid #bdbdbd',
//                     height: '50px',
//                     marginLeft: '10px',
//                   }}
//                 />
//               </Grid>
//               <Grid item xs={10} style={{ padding: '0px' }}>
//                 <CustomButton
//                   label={STEP_LABELS[step]}
//                   value={step}
//                   currentStatus={currentStatus}
//                   buttonClicked={buttonClicked}
//                   handleClick={handleButtonClick}
//                 />
//               </Grid>
//             </Grid>
//           </Step>
//         ))}
//       </Stepper>
//     </>
//   );
// };

// export default OrderStatusStepper;
import React from 'react';
import { Stepper, Step, Box, Button, Grid } from '@mui/material';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';

const STEP_ORDER = [
  'Ready to ship',
  'shipped',
  'delivered',
  'RETURNED',
  'COMPLETED',
];

const STEP_LABELS = {
  'Ready to ship': 'Ready to Ship',
  'shipped': 'Shipped',
  'delivered': 'Delivered',
  'RETURNED': 'Return',
  'COMPLETED': 'Completed',
};

const CustomButton = ({ label, value, currentStatus, handleClick, buttonClicked }) => (
  <Button
    variant="contained"
    className={buttonClicked === value || currentStatus === value ? `${value.toLowerCase()}` : "order"}
    onClick={() => handleClick(value)}
    sx={{ 
      display: 'flex', 
      justifyContent: 'center', 
      alignItems: 'center', 
      padding: '6px', 
      marginRight: '10px', 
      height: '50px', 
      width: '150px',
      backgroundColor:'rgb(144 208 71)'
    }}
  >
    {buttonClicked === value || currentStatus === value ? (
      <>
        <Box sx={{ display: 'flex', alignItems: 'center', padding: '8px' }}>
          <CheckCircleIcon sx={{ fontSize: '20px' }} />
        </Box>
        <Box sx={{ display: 'flex', alignItems: 'left', padding: '6px', flexGrow: 1, justifyContent: 'left', whiteSpace: 'nowrap' }}>
          {label}
        </Box>
      </>
    ) : (
      <Box sx={{ display: 'flex', alignItems: 'center', padding: '6px', flexGrow: 1, justifyContent: 'center' }}>
        {label}
      </Box>
    )}
  </Button>
);

const OrderStatusStepper = ({ currentStatus, buttonClicked, handleButtonClick }) => {
  const getStepIndex = (value) => {
    return STEP_ORDER.indexOf(value);
  };

  const getVisibleButtons = (status) => {
    const index = STEP_ORDER.indexOf(status);
    return STEP_ORDER.slice(index);
  };

  const visibleButtons = getVisibleButtons(currentStatus);

  return (
    <>
      <Stepper
        activeStep={getStepIndex(currentStatus)}
        orientation="vertical"
        sx={{
          '& .MuiStep-root': {
            display: 'flex',
            alignItems: 'center',
          },
          '& .MuiStepLabel-root': {
            marginLeft: '10px',
            display: 'flex',
            alignItems: 'center',
          },
        }}
      >
        {visibleButtons.map((step, index) => (
          <Step key={step}>
            <Grid container alignItems="center" spacing={2}>
              <Grid item xs={2}>
                <Box
                  sx={{
                    width: '24px',
                    height: '24px',
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'center',
                    borderRadius: '50%',
                    border: `2px solid ${currentStatus === step ? 'rgb(144 208 71)' : '#bdbdbd'}`, // Change border color based on currentStatus
                    backgroundColor: currentStatus === step ? 'rgb(144 208 71)' : '#fff', // Change background color based on currentStatus
                    fontSize: '14px',
                    color: currentStatus === step ? '#fff' : '#000', // Change text color based on currentStatus
                    textAlign: 'center',
                  }}
                >
                  {index + 1}
                </Box>
                <Box
                  sx={{
                    borderLeft: `4px solid ${currentStatus === step ? 'rgb(197, 225, 165)' : '#bdbdbd'}`, // Change border color based on currentStatus
                    height: '50px',
                    marginLeft: '10px',
                  }}
                />
              </Grid>
              <Grid item xs={10} style={{ padding: '0px' }}>
                <CustomButton
                  label={STEP_LABELS[step]}
                  value={step}
                  currentStatus={currentStatus}
                  buttonClicked={buttonClicked}
                  handleClick={handleButtonClick}
                />
              </Grid>
            </Grid>
          </Step>
        ))}
      </Stepper>
    </>
  );
};

export default OrderStatusStepper;

