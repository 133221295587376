
import React, { useState } from "react";
import { useParams, useNavigate } from "react-router-dom";
import {
  Typography,
  Breadcrumbs,
  Box,
  Grid,
} from "@mui/material";
import SubcategoryItem from "./Subcategory";
import AddCircleIcon from "@mui/icons-material/AddCircle";
import table from "../../../assets/images/table.jpg";
import mobile from "../../../assets/images/mobile.jpg";
import sofa from "../../../assets/images/sofa.jpg";
import laptop from "../../../assets/images/laptop.jpg";
import GridViewIcon from "@mui/icons-material/GridView";
import { useTheme } from "@mui/material/styles";
import ArrowForwardIcon from '@mui/icons-material/ArrowForward';
import AddButton from "../../reusable/button/AddButton";
import IconWithText from "../ProductPage/IconWithText ";
import SearchBar from "../ProductPage/SearchBox";
import ProductTable from "../ProductPage/ProductTable";
import CommonDialogAdd from "../mainCategory/CommondialogAdd";
import AddProductButtonDialog from "../ProductPage/EditDialog/AddProductButtonDialog";
import StoreIcon from '@mui/icons-material/Store';
const SubcategoryView = () => {
  const { categoryId } = useParams();
  const [open, setOpen] = useState(false);
  const [newCategory, setNewCategory] = useState({ name: '' }); 
  const [searchQuery, setSearchQuery] = useState('');
  const theme = useTheme();
  const [dialogOpen, setDialogOen] = useState(false);
  const navigate = useNavigate();
  const [categories, setCategories] = useState([
    {
      id: 1,
      name: "Electronics",
      image: "https://example.com/images/electronics.jpg",
      subcategories: [
        {
          id: 11,
          name: "Mobile Phones",
          image: mobile,
        },
        {
          id: 12,
          name: "Laptops",
          image: laptop,
        },
      ],
    },
    {
      id: 2,
      name: "Furniture",
      image: "https://example.com/images/furniture.jpg",
      subcategories: [
        {
          id: 21,
          name: "Sofas",
          image: sofa,
        },
        {
          id: 22,
          name: "Tables",
          image: table,
        },
      ],
    },
  ]);

  const category = categories.find((cat) => cat.id === parseInt(categoryId));

  const handleBack = () => {
    navigate("/add-store"); 
  };
  const handleBackCategory = () => {
    navigate("/category"); 
  };

  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);

  const handleSearchChange = (e) => {
    setSearchQuery(e.target.value);
  };

  const handleChange = (e) => {
    setNewCategory({ ...newCategory, name: e.target.value });
  };
  const handlAddDialogeOpen = () => setDialogOen(true);
  const handleAddDialogClose = () => setDialogOen(false);
  const handleAddCategory = () => {
    if (newCategory.name.trim() === '') return; 

    const newSubcategory = {
      id: Date.now(), 
      name: newCategory.name,
      image: '', 
    };

    setCategories(categories.map((cat) => {
      if (cat.id === parseInt(categoryId)) {
        return {
          ...cat,
          subcategories: [...cat.subcategories, newSubcategory],
        };
      }
      return cat;
    }));

    setNewCategory({ name: '' }); 
    handleClose(); 
  };
  const handleAddProduct = () => {
    
    console.log("New Product:", newCategory);
    setDialogOen(false);
  };
  

  const handleEditSubcategory = (subcategoryId, newName) => {
    setCategories(
      categories.map((cat) => {
        if (cat.id === parseInt(categoryId)) {
          return {
            ...cat,
            subcategories: cat.subcategories.map((sub) =>
              sub.id === subcategoryId ? { ...sub, name: newName } : sub
            ),
          };
        }
        return cat;
      })
    );
  };

  const handleDeleteSubcategory = (subcategoryId) => {
    setCategories(
      categories.map((cat) => {
        if (cat.id === parseInt(categoryId)) {
          return {
            ...cat,
            subcategories: cat.subcategories.filter(
              (sub) => sub.id !== subcategoryId
            ),
          };
        }
        return cat;
      })
    );
  };

  const linkStyle = {
    borderRadius: "10px",
    fontSize: "small",
    display: "inline-flex",
    cursor: "pointer",
    backgroundColor: theme.palette.blue.light,
    color: "white",
    transition: "background-color 0.3s",
  };

  const iconStyle = {
    display: "flex",
    alignItems: "center",
    padding: "5px",
  };

  const textStyle = {
    display: "flex",
    alignItems: "center",
    padding: "5px",
    color: "white",
  };

  return (
    <div
      style={{
        backgroundColor: theme.palette.blue.backgroundColor,
        minHeight: "100vh",
        width: "100%",
        display: "flex",
        flexDirection: "column",
      }}
    >
      <div>
        <Breadcrumbs
          aria-label="breadcrumb"
          separator={
            <b>
              <ArrowForwardIcon style={{
                fontSize: '25px',
                color: theme.palette.blue.light,
              }} />
            </b>
          }
        >
           <Typography
            color="textPrimary"
            onClick={handleBack}
            style={linkStyle}
          >
            <Box style={iconStyle}>
              <StoreIcon style={{ fontSize: "large" }} />
            </Box>
            <Box style={textStyle}>Store</Box>
          </Typography>
          <Typography
            color="textPrimary"
            onClick={handleBackCategory}
            style={linkStyle}
          >
            <Box style={iconStyle}>
              <GridViewIcon style={{ fontSize: "large" }} />
            </Box>
            <Box style={textStyle}>Category</Box>
          </Typography>
          <Typography color="textPrimary" style={linkStyle}>
            {category && <Box style={textStyle}>{category.name}</Box>}
          </Typography>
        </Breadcrumbs>

        <Grid>
          <Box
            style={{
              display: "flex",
              flexWrap: "wrap",
              justifyContent: "space-between",
              alignItems: "center",
              marginTop: "5px",
            }}
          >
            <Grid
              item
              xs={12}
              sm={6}
              style={{ textAlign: "center", marginBottom: "0.5rem" }}
            >
              <div
                style={{
                  display: "flex",
                  flexDirection: "row",
                  alignItems: "center",
                }}
              >
                <GridViewIcon
                  style={{
                    fontSize: 40,
                    color: theme.palette.blue.light,
                    paddingTop: "1rem",
                    margin: "0 auto",
                  }}
                />
                <Typography
                  gutterBottom
                  variant="h6"
                  align="center"
                  style={{
                    paddingTop: "1rem",
                    color: theme.palette.blue.light,
                    margin: "0 auto",
                  }}
                >
                  SUBCATEGORY
                </Typography>
              </div>
            </Grid>
            <Grid item xs={12} sm={6} style={{ textAlign: "center" }}>
              <button type="button" className="buttonsub" onClick={handleOpen}>
                <span className="button__icon">
                  <AddCircleIcon />
                </span>
                <span className="button__text">
                  <b>Add SubCategory</b>
                </span>
              </button>
            </Grid>
          </Box>
        </Grid>

        <CommonDialogAdd
          open={open}
          onClose={handleClose}
          title='Add SubCategory'
          placeholder="Type Here..."
          value={newCategory.name}
          head="Enter SubCategory Name"
          onChange={handleChange}
          onSubmit={handleAddCategory}
          maxLength={32}
          submitButtonText="ADD"
        />

        <div>
          <Grid container spacing={1} style={{ marginTop: "0px" }}>
            {category &&
              category.subcategories.map((subcategory) => (
                <Grid item xs={12} sm={6} md={4} key={subcategory.id}>
                  <SubcategoryItem
                    key={subcategory.id}
                    category={subcategory}
                    onEditSubcategory={handleEditSubcategory}
                    onDeleteSubcategory={handleDeleteSubcategory}
                  />
                </Grid>
              ))}
          </Grid>
        </div>

        <Grid>
          <Box
            style={{
              display: "flex",
              flexWrap: "wrap",
              justifyContent: "space-between",
              alignItems: "center",
              // marginTop: "2px",
            }}
          >
            <Grid
              item
              xs={12}
              sm={6}
              style={{ textAlign: "center", marginBottom: "0.5rem" }}
            >
              <div
                style={{
                  display: "flex",
                  flexDirection: "row",
                  alignItems: "center",
                 
                }}
              >
                <IconWithText 
        text="PRODUCTS"/>
        <SearchBar placeholder="Search..."
        value={searchQuery}
        onChange={handleSearchChange}
       />
                


              </div>
            </Grid>
            <Grid item xs={12} sm={6} style={{ textAlign: "center" }}>
             
              <AddButton className = "buttonProduct" onClick={handlAddDialogeOpen}/>
            </Grid>
          </Box>
        </Grid>
      
          <Grid item xs={12} sm={6} style={{ textAlign: "center" }}>
             
           
          <ProductTable
            // products={category ? category.subcategories : []}
            // searchQuery={searchQuery}
          />
        </Grid>
        <AddProductButtonDialog   open={dialogOpen}
        handleClose={handleAddDialogClose}
        handleChange={handleChange}
        handleAddProduct={handleAddProduct}
        newProduct={newCategory}
        theme={theme}/>
      </div>
    </div>
  );
};

export default SubcategoryView;
