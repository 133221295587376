import { useEffect } from 'react';
import { useLocation } from 'react-router-dom';

const DynamicTitleAndIcon = () => {
  const location = useLocation();

  const updateTitleAndIcon = (pathname) => {
    let title = 'Nego Tube - Dashboard';
    let iconPath = '/assets/images/favicon-32x32.png'; 

    switch (pathname) {
      case '/dashboard':
        title = 'Nego Tube - Dashboard';
        break;
      case '/Home':
        title = 'Nego Tube - Home';
        iconPath = '/assets/images/favicon-32x32.png'; 
        break;
      case '/ManageProduct':
        title = 'Nego Tube - Manage Product';
        iconPath = '/assets/images/favicon-32x32.png';
        break;
      case '/profile':
        title = 'Nego Tube - Profile';
        iconPath = '/assets/images/favicon-32x32.png';
        break;
        case '/category':
            title = 'Nego Tube - Category';
            iconPath = '/assets/images/favicon-32x32.png';
            break;
      case '/Product':
        title = 'Nego Tube - Product';
        iconPath = '/assets/images/favicon-32x32.png';
        break;
      case '/customers':
        title = 'Nego Tube - Customers';
        iconPath = '/assets/images/favicon-32x32.png';
        break;
      case '/products':
        title = 'Nego Tube - Products';
        iconPath = '/assets/images/favicon-32x32.png';
        break;
      case '/all%20orders':
        title = 'Nego Tube - All orders';
        iconPath = '/assets/images/favicon-32x32.png';

        break;
        case '/category/:categoryId':
            title = 'Nego Tube - SubCategory';
            iconPath = '/assets/images/favicon-32x32.png';
    
            break;
      case '/accepted':
        title = 'Nego Tube - Accepted';
        iconPath = '/assets/images/favicon-32x32.png';
        break;
      default:
        title = 'Nego Tube - Dashboard';
    }

    const favicon = document.getElementById('favicon');
    if (favicon) {
      favicon.href = iconPath;
    }

    document.title = title;
  };

  useEffect(() => {
    updateTitleAndIcon(location.pathname);
  }, [location.pathname]);

  return null; 
};

export default DynamicTitleAndIcon;
