
import React, { useState } from 'react';
import { Avatar, Grid, Typography, Button, TextField, Card, CardContent, IconButton, Box, Tabs, Tab, Switch, FormControlLabel } from '@mui/material';
import { YouTube, Facebook, LinkedIn, WhatsApp, Email } from '@mui/icons-material';
import CameraAltIcon from "@mui/icons-material/CameraAlt";
import Profile from '../../../assets/images/profile/avatar-2.png';
import { useTheme } from "@mui/material/styles";

const ProfilePage = () => {
  const theme = useTheme();
  const [cashOnDelivery, setCashOnDelivery] = useState(false);
  const [onlineStatus, setOnlineStatus] = useState(true);
  
  const [currentTab, setCurrentTab] = useState(0);


  const [profileDetails, setProfileDetails] = useState({
    firstName: "John",
    lastName: "Doe",
    email: "contact@doeselectronics.com",
    phoneNumber: "9123664576",
    state: "TamilNadu",
    country: "India",
    address: "123 Main St"
  });

 
  const [formValues, setFormValues] = useState({
    firstName: "John",
    lastName: "Doe",
    email: "contact@doeselectronics.com",
    phoneNumber: "9123664576",
    state: "TamilNadu",
    country: "India",
    address: "123 Main St"
  });

  const handleTabChange = (event, newValue) => {
    setCurrentTab(newValue);
  };

  // Update form values when input changes
  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setFormValues({
      ...formValues,
      [name]: value,
    });
  };

  // Handle update and display new details on the left side
  const handleUpdate = () => {
    setProfileDetails(formValues);
  };

  return (
    <div style={{ backgroundColor: theme.palette.lightBlue.backbackgroundColor }}>
      <Grid container spacing={4} padding={4}>
        {/* Profile Section */}
        <Grid item xs={12} md={4}>
          <Card>
            <CardContent>
              <Box
                sx={{
                  position: "relative",
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                  margin: "auto",
                }}
              >
                <Avatar
                  alt="Shop Owner"
                  src={Profile}
                  sx={{ width: 150, height: 150 }}
                />
                <IconButton
                  aria-label="edit"
                  sx={{
                    position: "absolute",
                    top: "85%",
                    left: "65%",
                    transform: "translate(-50%, -50%)",
                    backgroundColor: "rgba(255, 255, 255, 0.7)",
                    "&:hover": { backgroundColor: "rgba(255, 255, 255, 1)" },
                  }}
                >
                  <CameraAltIcon />
                </IconButton>
              </Box>

              <Typography variant="h6" align="center" marginTop={2}>
                {profileDetails.firstName} {profileDetails.lastName}
              </Typography>
              <Typography variant="body1" align="center">
                {profileDetails.email}
              </Typography>
              <Typography variant="body2" align="center">
                {profileDetails.phoneNumber}
              </Typography>
              <Typography variant="body2" align="center">
                {profileDetails.state}, {profileDetails.country}
              </Typography>
              <Typography variant="body2" align="center">
                {profileDetails.address}
              </Typography>

              {/* Social Media Icons */}
              <Grid container justifyContent="center" spacing={2} sx={{ marginTop: 3 }}>
                <Grid item>
                  <IconButton href="https://www.youtube.com" target="_blank" aria-label="YouTube">
                    <YouTube color="error" />
                  </IconButton>
                </Grid>
                <Grid item>
                  <IconButton href="https://www.facebook.com" target="_blank" aria-label="Facebook">
                    <Facebook color="primary" />
                  </IconButton>
                </Grid>
                <Grid item>
                  <IconButton href="https://www.linkedin.com" target="_blank" aria-label="LinkedIn">
                    <LinkedIn color="primary" />
                  </IconButton>
                </Grid>
                <Grid item>
                  <IconButton href="https://wa.me/1234567890" target="_blank" aria-label="WhatsApp">
                    <WhatsApp color="success" />
                  </IconButton>
                </Grid>
                <Grid item>
                  <IconButton href="mailto:john@example.com" target="_blank" aria-label="Gmail">
                    <Email color="error" />
                  </IconButton>
                </Grid>
              </Grid>
            </CardContent>
          </Card>
        </Grid>

        {/* Tabs Section */}
        <Grid item xs={12} md={8}>
          <Tabs value={currentTab} onChange={handleTabChange} indicatorColor="primary" textColor="primary" variant="fullWidth">
            <Tab label="Contact Details" />
            <Tab label="Store Settings" />
            <Tab label="Security" />
          </Tabs>

          <Card>
            <CardContent>
              {currentTab === 0 && (
                <Grid container spacing={2}>
                  {/* Contact Details Form */}
                  <Grid item xs={12} md={6}>
                    <TextField
                      fullWidth
                      label="First Name"
                      name="firstName"
                      value={formValues.firstName}
                      onChange={handleInputChange}
                      margin="normal"
                    />
                  </Grid>
                  <Grid item xs={12} md={6}>
                    <TextField
                      fullWidth
                      label="Last Name"
                      name="lastName"
                      value={formValues.lastName}
                      onChange={handleInputChange}
                      margin="normal"
                    />
                  </Grid>
                  <Grid item xs={8}>
                    <TextField
                      fullWidth
                      label="Email"
                      name="email"
                      value={formValues.email}
                      onChange={handleInputChange}
                      margin="normal"
                    />
                  </Grid>
                  <Grid item xs={4}>
                    <TextField
                      fullWidth
                      label="Phone Number"
                      name="phoneNumber"
                      value={formValues.phoneNumber}
                      onChange={handleInputChange}
                      margin="normal"
                      maxLength={10}
                    />
                  </Grid>
                  <Grid item xs={12} md={6}>
                    <TextField
                      fullWidth
                      label="State"
                      name="state"
                      value={formValues.state}
                      onChange={handleInputChange}
                      margin="normal"
                    />
                  </Grid>
                  <Grid item xs={12} md={6}>
                    <TextField
                      fullWidth
                      label="Country"
                      name="country"
                      value={formValues.country}
                      onChange={handleInputChange}
                      margin="normal"
                    />
                  </Grid>
                  <Grid item xs={12}>
                    <TextField
                      fullWidth
                      label="Shop Address"
                      name="address"
                      value={formValues.address}
                      onChange={handleInputChange}
                      multiline
                      rows={3}
                  
                      margin="normal"
                    />
                  </Grid>
                  <Grid item xs={12}>
                    <Button fullWidth variant="contained" color="primary" sx={{ marginTop: 2 }} onClick={handleUpdate}>
                      Update
                    </Button>
                  </Grid>
                </Grid>
              )}
              
              {currentTab === 1 && (
                <Grid container spacing={2}>
                  {/* Store Settings */}
                  <Grid item xs={12}>
                    <TextField
                      fullWidth
                      label="Shop Name"
                      defaultValue="Nego Tube"
                      margin="normal"
                    />
                  </Grid>
                  <Grid item xs={12}>
                    <TextField
                      fullWidth
                      label="Business Category"
                      defaultValue="Electronics"
                      margin="normal"
                    />
                  </Grid>
                  <Grid item xs={12}>
                    <FormControlLabel
                      control={
                        <Switch
                          checked={cashOnDelivery}
                          onChange={() => setCashOnDelivery(!cashOnDelivery)}
                        />
                      }
                      label="Cash on Delivery Allowed"
                    />
                  </Grid>
                  <Grid item xs={12}>
                    <FormControlLabel
                      control={
                        <Switch
                          checked={onlineStatus}
                          onChange={() => setOnlineStatus(!onlineStatus)}
                        />
                      }
                      label="Online Status Allowed"
                    />
                  </Grid>
                </Grid>
              )}

              {currentTab === 2 && (
                <Grid container spacing={2}>
                  {/* Security Section */}
                  <Grid item xs={12}>
                    <Typography>Password: ********</Typography>
                    <Button variant="outlined" sx={{ marginTop: 2 }}>
                      Change Password
                    </Button>
                  </Grid>
                  <Grid item xs={12}>
                    {/* <Typography>Bank Account: XXXX-1234</Typography>
                    <Typography>PayPal: john@paypal.com</Typography>
                    <Box
                      className="marginBottom5"
                      sx={{
                        display: "flex",
                        alignItems: "center",
                        // justifyContent: "flex-end",
                        width: "100%",
                        paddingTop: "3px",
                      }}
                    >
                      <button
                        variant="contained"
                        style={{
                          display: "flex",
                          alignItems: "center",

                          //  width:'150px',
                          padding: "0px",
                        }}
                        onClick={() => console.log("Price saved")}
                      >
                        <Box
                          sx={{
                            display: "flex",
                            alignItems: "center",
                            padding: "10px",
                          }}
                        >
                          <DoneIcon sx={{ fontSize: "20px" }} />
                        </Box>
                        <Box
                          sx={{
                            flexGrow: 1,
                            textAlign: "center",
                            padding: "8px",
                          }}
                        >
                          <span>Manage Payment Methods</span>
                        </Box>
                      </button>
                    </Box> */}

                    <Button variant="outlined" sx={{ marginTop: 2 }}>
                      Manage Payment Methods
                    </Button>
                  </Grid>
                </Grid>
              )}
            </CardContent>
          </Card>
        </Grid>
      </Grid>
    </div>
  );
};

export default ProfilePage;
