
import React, { useState } from "react";
import { Grid, Box, Typography, TextField, InputAdornment } from "@mui/material";

import DoneIcon from "@mui/icons-material/Done";
import { useTheme } from "@mui/material/styles";
import EditIcon from "@mui/icons-material/Edit";

const PriceSection = ({ order = {} }) => {
  const initialPrice = order.price || 0; 
  const [sellingPrice, setSellingPrice] = useState(initialPrice);
  const [mrp, setMrp] = useState(null);
  const [discount, setDiscount] = useState(0);
  const [error, setError] = useState("");
  const [invalid, setInvalid] = useState(false);
  const theme = useTheme();
  const handleSellingPriceChange = (event) => {
    const { value } = event.target;

   
    let sanitizedValue = value.replace(/[^0-9.]/g, '');

    
    const parts = sanitizedValue.split('.');
    if (parts.length > 2) {
        sanitizedValue = parts[0] + '.' + parts.slice(1).join('');
    }

    
    const integerPart = parts[0] || '';
    const decimalPart = (parts[1] || '').slice(0, 2);

    
    let formattedValue = `${integerPart}${decimalPart ? '.' + decimalPart : ''}`;
    
    
    const maxLength = 12;
    if (sanitizedValue.length > maxLength) {
      
      setInvalid(false);
      return; 
  }
    
    const numericValue = parseFloat(formattedValue) || 0;

   
    setSellingPrice(numericValue);

    
    if (mrp > 0) {
        if (numericValue > mrp) {
            setError("*Selling price must be less than MRP");
            setInvalid(true);
            setDiscount(0);
        } else {
            setError("");
            setInvalid(false);
            const discountValue = ((mrp - numericValue) / mrp) * 100;
            setDiscount(Math.round(discountValue));
        }
    } else {
        setError("");
        setInvalid(false);
    }
};


  

   
  const handleMrpChange = (event) => {
    const { value } = event.target;

   
    let sanitizedValue = value.replace(/[^0-9.]/g, '');
   
    const parts = sanitizedValue.split('.');
    if (parts.length > 2) {
        sanitizedValue = parts[0] + '.' + parts.slice(1).join('');
    }

    
    const integerPart = parts[0] || '';
    const decimalPart = (parts[1] || '').slice(0, 2);

    
    let formattedValue = `${integerPart}${decimalPart ? '.' + decimalPart : ''}`;
    
    
    const maxLength = 12;
    if (sanitizedValue.length > maxLength) {
      
      setInvalid(false);
      return; 
  }
    
  
    const numericValue = parseFloat(formattedValue) || 0;
  
    setMrp(numericValue);
  
    if (sellingPrice > 0 && numericValue > 0) {
      if (sellingPrice > numericValue) {
        setError("*MRP must be greater than selling price");
        setInvalid(true);
        setDiscount(0);
      } else {
        setError("");
        setInvalid(false);
        const discountValue = ((value - sellingPrice) / value) * 100;
        setDiscount(Math.round(discountValue));
      }
    } else {
      setError("");
      setInvalid(false);
    }
  };
  

  

  const formatNumber = (value) => {
    if (isNaN(value)) return '0.00';

  
    return value.toLocaleString('en-IN', { minimumFractionDigits: 2, maximumFractionDigits: 2 });
};


  return (
    <Grid item xs={12} style={{ padding:'16px 16px'}}>
      <Box
                className="marginBottom5"
                sx={{
                  display: "flex",
                  alignItems: "center",
                  paddingBottom: "6px",
                }}
              >
                <EditIcon
                  style={{
                    marginRight: "5px",
                    fontSize: "18px",
                    color: theme.palette.primary.text,
                  }}
                />
                <Typography
                  variant="body1"
                  style={{
                    color: theme.palette.primary.text,
                    fontFamily: "Poppins, sans-serif",
                  }}
                >
                  <b>Price</b>
                </Typography>
              </Box>
    
      <Typography
        variant="body2"
        style={{ color: "gray", paddingBottom: "10px", fontFamily: "Poppins, sans-serif" }}
      >
        Preview
      </Typography>

      {invalid && mrp > 0 && (
        <div style={{ paddingBottom: "5px", width: '56px', display: 'flex',}}>
          <Typography
            variant="body2"
            style={{
              backgroundColor: "red",
              fontSize: "14px",
              padding: '5px',
              color: 'white',
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'center',
              borderRadius:'4px' , fontFamily: "Poppins, sans-serif"
            }}
          >
            <span>Invalid</span>
            <span style={{ marginLeft: '5px' }}>price</span>
          </Typography>
        </div>
      )}

      {!invalid && discount > 0 && mrp > 0 && (
        <div style={{ paddingBottom: "5px", width: '56px', display: 'flex' ,}}>
          <Typography
            variant="body2"
            style={{
              backgroundColor: "red",
              fontSize: "14px",
              padding: '5px',
              color: 'white',
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'center',
              borderRadius:'4px'
            }}
          >
            <span>{discount}%</span>
            <span style={{ marginLeft: '5px' }}>Off</span>
          </Typography>
        </div>
      )}
      <Box  sx={{ paddingBottom: "5px" }}>
      {!invalid && mrp > 0 && (
    <Typography
      variant="body1"
      style={{
        color: "black",
        fontSize: "15px",
        paddingBottom: "10px",
        textDecoration: "line-through",
      }}
    >
      ₹{formatNumber(mrp)}
    </Typography>
  )}
      {!invalid && (
        <Typography
          variant="body1"
          style={{
            color: "blue",
            fontSize: "15px",
            paddingBottom: "10px",
          }}
        >
         <b>  ₹{formatNumber(sellingPrice)}</b>
        </Typography>
      )}
</Box>
      <Box
        className="marginBottom5"
        sx={{
          display: "flex",
          alignItems: "center",
          justifyContent: "space-between",
          width: "100%",
        }}
      >
        <Typography
          style={{ marginLeft: "2rem", fontSize: "15px", color: "gray", fontFamily: "Poppins, sans-serif" }}
        >
          Selling Price*
        </Typography>
        <TextField
          type="number"
          variant="outlined"
          size="small"
          className="customTextField"
          placeholder="0"
          value={sellingPrice}
          onChange={handleSellingPriceChange}
          sx={{
            "& .MuiOutlinedInput-root": {
              "& fieldset": {
                border: "none",
              },
            },
          }}
          InputProps={{
            startAdornment: (
              <InputAdornment position="start" style={{ color: "black" }}>
                <b>₹</b>
              </InputAdornment>
            ),
          }}
        />
      </Box>

      <Box
        className="marginBottom5"
        sx={{
          display: "flex",
          alignItems: "center",
          justifyContent: "space-between",
          width: "100%",
        }}
      >
        <Typography
          style={{ marginLeft: "2rem", fontSize: "15px", color: "gray" , fontFamily: "Poppins, sans-serif"}}
        >
          MRP
        </Typography>
        <TextField
          type="number"
          variant="outlined"
          size="small"
          className="customTextField"
          placeholder="mrp"
          value={mrp}
          onChange={handleMrpChange}
          sx={{
            "& .MuiOutlinedInput-root": {
              "& fieldset": {
                border: "none",
              },
            },
          }}
          InputProps={{
            startAdornment: (
              <InputAdornment position="start" style={{ color: "black" }}>
                <b>₹</b>
              </InputAdornment>
            ),
          }}
        />
      </Box>

      {error && (
        <Typography
          variant="body2"
          style={{ color: "red", marginLeft: "2rem", marginTop: "5px", fontSize:'10px' }}
        >
          {error}
        </Typography>
      )}

   
      <Box
  className="marginBottom5"
  sx={{
    display: "flex",
    alignItems: "center",
    justifyContent: "flex-end",
    width: "100%",
    paddingTop:'3px'
  }}
>

  
  <button
    variant="contained"
    className="priceButton"
    style={{
      display: "flex",
      alignItems: "center",
      justifyContent: "space-between", // Ensures icon is on the left and text is centered
     width:'150px',
      padding: "0px",
     
    
    }}
    onClick={() => console.log("Price saved")}
  >
    <Box sx={{ display: "flex", alignItems: "center" , padding: "10px",}}>
      <DoneIcon sx={{ fontSize: "20px" }} />
    </Box>
    <Box sx={{ flexGrow: 1, textAlign: "center", padding: "8px", }}>
      <span>SAVE PRICE</span>
    </Box>
  </button>
</Box>


    </Grid>
  
  );
};

export default PriceSection;
