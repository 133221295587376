
// import React, { useState } from 'react';
// import Cropper from 'react-easy-crop';
// import { Box, Button, Typography } from '@mui/material';
// import CloseIcon from '@mui/icons-material/Close';
// const ImageCropper = ({ imageSrc, onCropDone, onCancel }) => {
//   const [crop, setCrop] = useState({ x: 0, y: 0 });
//   const [zoom, setZoom] = useState(1);
//   const [croppedAreaPixels, setCroppedAreaPixels] = useState(null);

//   const onCropComplete = (_, croppedAreaPixels) => {
//     setCroppedAreaPixels(croppedAreaPixels);
//   };

//   const generateCroppedImage = async () => {
//     const canvas = document.createElement('canvas');
//     const image = new Image();
//     image.src = imageSrc;
//     await new Promise((resolve) => {
//       image.onload = resolve;
//     });

//     const ctx = canvas.getContext('2d');
//     canvas.width = croppedAreaPixels.width;
//     canvas.height = croppedAreaPixels.height;
//     ctx.drawImage(
//       image,
//       croppedAreaPixels.x,
//       croppedAreaPixels.y,
//       croppedAreaPixels.width,
//       croppedAreaPixels.height,
//       0,
//       0,
//       croppedAreaPixels.width,
//       croppedAreaPixels.height
//     );

//     const croppedImageDataUrl = canvas.toDataURL('image/jpeg');
//     onCropDone(croppedImageDataUrl);
//   };

//   return (
//     <>
   
//     <Box
//     sx={{
//       position: 'relative',
//       width: '80%',
//       maxWidth: '300px', 
//       height: 0,
//       paddingBottom: '80%', 
//       backgroundColor: '#333',
//       // borderRadius: '8px', 
//       overflow: 'hidden', 
//       margin: '0 auto', 
//     }}
     
//     >
//       <Box
//         sx={{
//           position: 'absolute',
//           top: 0,
//           left: 0,
//           width: '100%',
//           height: '100%',
//         }}
//       >
//         <Cropper
//           image={imageSrc}
//           crop={crop}
//           zoom={zoom}
//           aspect={1} 
//           cropShape="rect" 
//           showGrid={false}
//           onCropChange={setCrop}
//           onZoomChange={setZoom}
//           onCropComplete={onCropComplete}
//         />
//         <Box
//           sx={{
//             position: 'absolute',
//             top: 0,
//             left: 0,
//             width: '100%',
//             height: '100%',
//             border: '5px solid rgb(255 255 255)', 
//             boxSizing: 'border-box',
//             pointerEvents: 'none', 
//           }}
//         />
//       </Box>
//       <Box
//         sx={{
//           position: 'absolute',
//           top: '10px',
//           width: '100%',
//           display: 'flex',
//           justifyContent: 'space-between',
//           padding: '0 20px',
//           boxSizing: 'border-box',
//         }}
//       >
//         <CloseIcon
//           sx={{ fontSize: '20px', cursor: 'pointer',color:'white' }}
//           onClick={onCancel}
//         />
//         <Typography
//                     sx={{ color:'white' }}

//           onClick={generateCroppedImage}
//         >
//           Done
//         </Typography>
//       </Box>
//     </Box>
//     </>
//   );
// };

// export default ImageCropper;

import React, { useState } from 'react';
import Cropper from 'react-easy-crop';
import { Box} from '@mui/material';

const ImageCropper = ({ imageSrc, onCropComplete ,}) => {
  const [crop, setCrop] = useState({ x: 0, y: 0 });
  const [zoom, setZoom] = useState(1);
  

  


  return (
    <>
   
    <Box
    sx={{
      position: 'relative',
      width: '80%',
      // maxWidth: '300px', 
      height: 0,
      paddingBottom: '80%', 
      backgroundColor: '#333',
      // borderRadius: '8px', 
      overflow: 'hidden', 
      margin: '0 auto', 
    }}
     
    >
      <Box
        sx={{
          position: 'absolute',
          top: 0,
          left: 0,
          width: '100%',
          height: '100%',
        }}
      >
        <Cropper
          image={imageSrc}
          crop={crop}
          zoom={zoom}
          aspect={1} 
          cropShape="rect" 
          showGrid={false}
          onCropChange={setCrop}
          onZoomChange={setZoom}
          onCropComplete={onCropComplete}
        />
        <Box
          sx={{
            position: 'absolute',
            top: 0,
            left: 0,
            width: '100%',
            height: '100%',
            border: '5px solid rgb(255 255 255)', 
            boxSizing: 'border-box',
            pointerEvents: 'none', 
          }}
        />
      </Box>
     
      </Box>
   
    </>
  );
};

export default ImageCropper;
