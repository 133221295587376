import React, { useState } from "react";
import {
  Typography,
  Card,
  CardContent,
  Grid,
  Box,
  useMediaQuery,
  
  Button,
  Tooltip,
  Drawer,
  useTheme,
} from "@mui/material";
import DownloadForOfflineIcon from "@mui/icons-material/DownloadForOffline";

import { IconButton } from "@mui/material";

// import CallIcon from "@mui/icons-material/Call";
import MailIcon from "@mui/icons-material/Mail";
import { CopyAll as CopyIcon } from "@mui/icons-material";
import PrintIcon from "@mui/icons-material/Print";
import WhatsAppChatButton from "./OtherInfo/WhatsAppChatButton";
import ContentCopyIcon from '@mui/icons-material/ContentCopy';
import WhatsAppShare from "./OtherInfo/WhatsAppShare";
// const capitalizeFirstLetterOnly = (text) => {
//   if (!text) return "";
//   return text.charAt(0).toUpperCase() + text.slice(1).toLowerCase();
// };
function OrderDetails({ order , open, toggleDrawer, }) {
  // const [openImageDialog, setOpenImageDialog] = useState(false);
  // const [selectedImage, setSelectedImage] = useState("");
  const isSmallScreen = useMediaQuery((theme) => theme.breakpoints.down("sm"));
  // const [imageTitle, setImageTitle] = useState("Image Preview");
  const theme = useTheme();
  
const additionalProductsCount = order.products ? order.products.length - 1 : 0;
  if (!order) return null;
  const formatDate = (dateString) => {
    const date = new Date(dateString);
    const day = String(date.getDate()).padStart(2, "0");
    const month = date
      .toLocaleString("default", { month: "short" })
      .toUpperCase();
    const year = date.getFullYear();
    return `${day} ${month} ${year}`;
  };
  const downloadCustomImage = (url) => {
    const link = document.createElement("a");
    link.href = url;
    link.download = "custom-image.jpg";
    link.click();
  };
  // const displayText = order.payment
  // ? order.payment.paymentStatus !== null && order.payment.paymentStatus !== undefined
  //   ? order.payment.paymentStatus
  //   : "No status available"
  // : "No payment information available";

  // const paymentType =
  //   order.payment.paymentType === "ONLINE"
  //     ? "Online payment"
  //     : order.payment.paymentType;

      const totalProductCount = order.products.reduce((count, product) => {
        return count + product.quantity;
      }, 0);

  const handlePrint = () => {
    const printWindow = window.open("", "", "height=600,width=800");

    printWindow.document.write("<html><head><title>Print Order</title>");
    printWindow.document.write("<style>");
    printWindow.document.write(
      "body { font-family: Arial, sans-serif; padding: 20px; }"
    );
    printWindow.document.write("h1 { color: #333; text-align: center; }");
    printWindow.document.write("p { margin: 0; padding: 5px 0; }");
    printWindow.document.write(
      "table { width: 100%; border-collapse: collapse; margin-bottom: 20px; }"
    );
    printWindow.document.write(
      "th, td { border: 1px solid #ddd; padding: 8px; text-align: left; }"
    );
    printWindow.document.write("th { background-color: #f4f4f4; }");
    printWindow.document.write(
      ".header { text-align: right; margin-bottom: 20px; }"
    );
    printWindow.document.write("</style></head><body>");
    printWindow.document.write("<h4>Delivery Address</h4>");
    printWindow.document.write("<p> " + order.customer.name + "</p>");
    printWindow.document.write("<p> " + order.customer.addressOne + "</p>");
    printWindow.document.write(
      "<p>" + order.customer.city + ", " + order.customer.state + ", ",
      +order.customer.pinCode + "</p>"
    );

    printWindow.document.write("<p> " + order.customer.landmark + "</p>");

   

    printWindow.document.write("</body></html>");

    printWindow.document.close();
    printWindow.focus();
    printWindow.print();
  };
 
  // const handleImageClick = (image, title) => {
  //   setSelectedImage(image);
  //   setImageTitle(title || "Image Preview");
  //   setOpenImageDialog(true);
  // };

  const getStatusColor = (status) => {
    const normalizedStatus = status || "";

    switch (normalizedStatus) {
      case "PENDING":
        return "rgb(252 210 4)";
        case "PLACED":
          return "rgb(252 210 4)";
      case "Accepted":
        return "rgb(0 163 238)";
        case "delivered":
          return "rgb(0 163 238)";
        case "shipped":
        return "rgb(0 163 238)";
        case "Ready to ship":
          return "rgb(0 163 238)";
      case "PAID":
        return "#42e695";
      case "active":
        return "#81d4fa";
      case "ONLINE":
        return "green";
      case "failed":
        return "#ef5350";
      default:
        return "red";
    }
  };
  const copyToClipboard = (text) => {
    navigator.clipboard
      .writeText(text)
      .then(() => alert("Order ID copied to clipboard!"))
      .catch((err) => alert("Failed to copy!"));
  };
  const handleCopy = (email) => {
    navigator.clipboard
    .writeText(email)
    .then(() => alert("Email copied to clipboard!"))
    .catch((err) => alert("Failed to copy!"));
    // navigator.clipboard.writeText(email);
    // alert('Email copied to clipboard!');
  };
  return (
    <>
      <Drawer
        anchor="right"
        open={open}
        BackdropProps={{ invisible: true }}
        onClose={toggleDrawer(false)}
        sx={{
          "& .MuiDrawer-paper": {
            width: isSmallScreen ? "100%" : 500,
            boxSizing: "border-box",
          },
        }}
      >
        <Box sx={{ width: "100%", padding: 2, backgroundColor: "#f7f7f7" }}>
          <Typography
            style={{
              textAlign: "center",
              justifyContent: "center",
              
              color: theme.palette.green.menuhead,
            }}
            variant="body1"
            className="mainhead pt-3"
            gutterBottom
            
          >
            <b>Order details</b>
          </Typography>
          <Card sx={{ width: "100%", marginTop: "15px", boxShadow: "none" }}>
            <CardContent>
              <Box>
                <Box
                  display="flex"
                  justifyContent="flex-end"
                  alignItems="center"
                  style={{ width: "100%" }}
                >
                  <Typography style={{ fontSize: "13px" }} variant="subtitle1">
                    {formatDate(order.createdOn)}
                  </Typography>
                </Box>
                <Box>
                  <Box
                    style={{ justifyContent: "space-between", display: "flex" }}
                    className=" subhead"
                  >
                    <Typography
                      style={{ fontSize: "13px" }}
                      variant="subtitle1"
                    >
                      Order ID: {order.orderId || "null"}
                    </Typography>
                    <Tooltip title="Copy Order ID">
                      <IconButton
                        onClick={() => copyToClipboard(order.orderId)}
                        style={{ paddingRight: "0px", paddingTop: "0px" }}
                        size="small"
                      >
                        <CopyIcon />
                      </IconButton>
                    </Tooltip>
                  </Box>
                  <Typography
                    style={{ fontSize: "14px" }}
                    className=" subhead1"
                    variant="subtitle1"
                  >
                    Order status:
                    <span
                      style={{
                        color: getStatusColor(order.orderStatus),
                        marginLeft: "5px",
                        textTransform: "uppercase",
                      }}
                    >
                      {" "}
                      {order.orderStatus}
                    </span>
                  </Typography>
                </Box>
              </Box>
            </CardContent>
          </Card>

          <Card sx={{ width: "100%", marginTop: "15px", boxShadow: "none" }}>
            <CardContent style={{ padding: "16px" }}>
              <div style={{ justifyContent: "space-between", display: "flex" }}>
                <Typography variant="body2"  className=" subhead" gutterBottom>
                  <b style={{fontSize:'medium',color: theme.palette.green.menuhead,}}>Customer contact info</b>
                </Typography>
                <Box onClick={handlePrint}>
                <Box className="marginBottom5" sx={{ display: 'flex', alignItems: 'center' }}>
            <PrintIcon
              style={{
                marginRight: '5px',
                fontSize: '20px',
                color: theme.palette.green.menuhead,
              }}
            />
            <Typography   variant="body1" style={{ fontFamily: 'Poppins, sans-serif', color: theme.palette.green.menuhead, }}>
              <b>Print</b>
            </Typography>
          </Box>
       </Box>
                {/* <Tooltip title="Print Address">
                  
                  <IconButton
                    onClick={handlePrint}
                    className="no-print "
                    style={{ paddingTop: "0px" }}
                  >
                    <PrintIcon
                      style={{ fontSize: "20px", color: "#008000c4" }}
                    />
                  </IconButton>
                </Tooltip> */}
              </div>
              <Grid container spacing={2}>
                <Grid item xs={12} sm={6}>
                  <div className="contact">
                    <Typography
                      style={{
                        fontSize: "13px",
                        color: "blue",
                        paddingBottom: "5px",
                      }}
                      variant="subtitle1"
                    >
                      {order.customer.name}
                    </Typography>
                    <Typography
                      style={{
                        fontSize: "13px",
                        paddingBottom: "5px",
                        width: "18rem",
                      }}
                      variant="subtitle1"
                    >
                      {" "}
                      {order.customer.addressOne}
                    </Typography>
                    <div className="">
                      <Typography
                        style={{
                          fontSize: "13px",
                          paddingBottom: "5px",
                          width: "18rem",
                        }}
                        variant="subtitle1"
                      >
                        {" "}
                        {order.customer.city},{order.customer.state},
                        {order.customer.pinCode}
                      </Typography>

                      <Typography
                        style={{
                          fontSize: "13px",
                          paddingBottom: "5px",
                          width: "18rem",
                        }}
                        variant="subtitle1"
                      >
                        {" "}
                        {order.customer.landmark}
                      </Typography>
                    </div>
                  </div>
                </Grid>
              </Grid>
              <Grid style={{}}>
                <div className="d-flex justify-content-between ">
                  <Typography
                    style={{
                      fontSize: "13px",
                      paddingBottom: "5px",
                      color: "#000000a6",
                    }}
                    variant="subtitle1"
                  >
                    <b> {order.customer.mobileNumber}</b>
                  </Typography>

                  <div className="d-flex justify-content-end  align-items-center">
                    <WhatsAppChatButton
                      phoneNumber={order.customer.mobileNumber}
                    />
                  </div>
                </div>
              </Grid>

              <Grid style={{ paddingBottom: "5px" }}>
                <div
                  style={{
                    display: "flex",
                    justifyContent: "space-between",
                    alignItems: "center",
                    flexWrap: "nowrap",
                  }}
                >
                  <Typography
                    style={{
                      fontSize: "13px",
                      paddingTop: "5px",
                      overflow: "hidden",
                      textOverflow: "ellipsis",
                      whiteSpace: "nowrap",
                      flexGrow: 1,
                      marginRight: "8px",
                    }}
                    variant="subtitle1"
                  >
                    <Tooltip
                      title={
                        <div style={{ display: "flex", alignItems: "center" }}>
                          {order.customer.email}
                          <IconButton
                            size="small"
                            onClick={() => handleCopy(order.customer.email)}
                            style={{ marginLeft: "5px", color: "#fff" }}
                          >
                            <ContentCopyIcon fontSize="small" />
                          </IconButton>
                        </div>
                      }
                      arrow
                    >
                      {order.customer.email}
                    </Tooltip>
                  </Typography>

                  <div
                    className="d-flex align-items-right"
                    style={{ flexShrink: 0 }}
                  >
                    <a
                      href={`mailto:${
                        order.customer.email
                      }?subject=Order%20Confirmation%20-%20Your%20Order%20is%20Successfully%20Delivered!&body=Hello%20${encodeURIComponent(
                        order.customer.name
                      )},%0A%0AWe%20are%20delighted%20to%20inform%20you%20that%20your%20order%20has%20been%20successfully%20delivered!%0A%0A%2A%2AOrder%20Details:%2A%2A%0A-%20Order%20ID:%20${
                        order.orderId
                      }%0A-%20Product%20Name:%20${encodeURIComponent(
                        order.products.productName
                      )}%0A-%20Delivery%20Address:%20${encodeURIComponent(
                        order.customer.addressOne
                      )}%0A%0AThank%20you%20for%20choosing%20us.%20If%20you%20have%20any%20questions%20or%20need%20assistance,%20feel%20free%20to%20reach%20out.%0A%0ABest%20regards,%0A[Your%20Company%20Name]%0A[Your%20Company%20Contact%20Information]`}
                      style={{ textDecoration: "none", color: "" }}
                    >
                      <Button
                        className="mail"
                        sx={{
                          display: "flex",
                          justifyContent: "center",
                          alignItems: "center",
                          color: "red",
                          padding: "2px",
                        }}
                      >
                        <Box
                          sx={{
                            display: "flex",
                            alignItems: "center",
                            padding: "2px",
                          }}
                        >
                          <MailIcon style={{ fontSize: "20px" }} />
                        </Box>
                        <Box
                          sx={{
                            display: "flex",
                            alignItems: "center",
                            padding: "2px",
                            color: "black",
                            flexGrow: 1,
                            justifyContent: "center",
                          }}
                        >
                          <Typography variant="button">Mail</Typography>
                        </Box>
                      </Button>
                    </a>
                  </div>
                </div>
              </Grid>
            </CardContent>
          </Card>

          <Card sx={{ width: "100%", marginTop: "15px", boxShadow: "none" }}>
            <CardContent style={{ padding: "16px" ,paddingBottom:'0px'}}>
              <WhatsAppShare
                phoneNumber={order.customer.mobileNumber || "N/A"}
                orderNumber={order.orderId || "N/A"}
                brandName={order.product?.brandName || "N/A"}
                trackingId={order.id || "N/A"}
                statusMessage={order.orderStatus || "N/A"}
                OrderId={order.id || "N/A"}
              />
            </CardContent>
          </Card>

          <Card sx={{ width: "100%", marginTop: "15px", boxShadow: "none" }}>
            <CardContent style={{ padding: "16px" }}>
              <Typography variant="body2" className="subhead" gutterBottom>
                <b style={{fontSize:'medium',color: theme.palette.green.menuhead,}}>Product details</b>
              </Typography>

              <div style={{ paddingBottom: "3px" }}>
                <Grid container spacing={2}>
                  <Grid item xs={12} md={12}>
                    <div
                      style={{
                        
                        display: "flex",
                        flexDirection: "row",
                        alignItems: "center",
                        flexWrap: "wrap",
                        gap: "10px",
                      }}
                    >
                      <Box sx={{ textAlign: "center" }}>
                        {order.products &&
                          order.products[0]?.productImageUrls?.length > 0 && (
                            <img
                              // onClick={() =>
                              //   handleImageClick(
                              //     order.products[0].productImageUrls[0], 
                              //     order.products[0].productName 
                              //   )
                              // }
                              src={order.products[0].productImageUrls[0]} 
                              alt={
                                order.products[0].productName || "Product Image"
                              }
                              style={{
                                height: "100px",
                                width: "100px", 
                                borderRadius: "10px",
                                objectFit: "cover",
                              }}
                            />
                          )}

                        <span
                          style={{
                            fontWeight: "bold",
                            color: "black",
                            display: "block",
                            marginTop: "5px",
                          }}
                        >
                          {/* ₹{order.products[0]?.productPrice} */}
                        </span>
                      </Box>

                      <div
                        style={{
                          marginLeft: "7px",
                          color: "#7d7f82",
                          fontSize: "13px",
                          // overflow: "hidden",

                          textOverflow: "ellipsis",
                          whiteSpace: "nowrap",
                        }}
                      >
                        <Typography
                          style={{
                            fontSize: "13px",
                            color: "black",
                            fontWeight: "bold",
                          }}
                          variant="subtitle1"
                        >
                          {order.products[0].productName}
                        </Typography>
                        <Typography
                          variant="body1"
                          style={{
                            color: "blue",
                            fontSize: "15px",
                            // paddingBottom: "10px",
                          }}
                        >
                          <b> ₹{order.products[0]?.productPrice}</b>
                        </Typography>

                        <div
                          style={{
                            paddingBottom: "5px",
                            width: "56px",
                            display: "flex",
                          }}
                        >
                          <Typography
                            variant="body2"
                            style={{
                              backgroundColor: "red",
                              fontSize: "10px",
                              padding: "5px",
                              color: "white",
                              display: "flex",
                              alignItems: "center",
                              justifyContent: "center",
                              borderRadius: "4px",
                            }}
                          >
                            <span>{order.products[0]?.discount}%</span>
                            <span style={{ marginLeft: "5px" }}>Off</span>
                          </Typography>
                        </div>
                        <Typography
                          variant="body1"
                          style={{
                            color: "black",
                            fontSize: "12px",
                            paddingBottom: "10px",
                            textDecoration: "line-through",
                          }}
                        >
                          ₹{order.products[0]?.mrp}
                        </Typography>
                        {order.product?.productType === "CUSTOM" && (
                          <Box
                            sx={{
                              display: "flex",
                              justifyContent: "center",
                              alignItems: "center",
                              width: "150px",
                              height: "50px",
                              marginBottom: "8px",
                              marginRight: "8px",
                            }}
                          >
                            <Button
                              variant="contained"
                              className="download"
                              onClick={() =>
                                downloadCustomImage(
                                  // order.product[0]?.productImageUrls[0]
                                  order.product.customFile
                                )
                              }
                              fullWidth
                            >
                              <DownloadForOfflineIcon
                                className="me-2"
                                style={{ fontSize: "20px" }}
                              />
                              Download
                            </Button>
                          </Box>
                        )}
                      </div>
                    </div>
                  </Grid>
                </Grid>
              </div>
              {additionalProductsCount > 0 && (
              <Box style={{display:'flex',justifyContent:'flex-end'}}>
                <b>+{additionalProductsCount} more items</b>
              </Box>
                  )}
            </CardContent>
          </Card>
          <Card sx={{ width: "100%", marginTop: "15px", boxShadow: "none" }}>
            <CardContent style={{ padding: "16px" }}>
              <Typography variant="body2" className="subhead" gutterBottom>
                <b style={{fontSize:'medium',color: theme.palette.green.menuhead,}}>Payment details</b>
              </Typography>
              <div
      style={{
        display: "flex",
        justifyContent: "space-between",
        alignItems: "center",  padding:'5px',fontSize:'small',fontWeight:'400'
      }}
    >
      <Typography className="payment" variant="subtitle1">
        Price ({totalProductCount} items)
      </Typography>
      <span>
        ₹{order.payment.totalProductMRP}
      </span>
    </div>
    <div
      style={{
        display: "flex",
        justifyContent: "space-between",
        alignItems: "center",  padding:'5px',fontSize:'small',fontWeight:'400'
      }}
    >
      <Typography className="payment" variant="subtitle1">
        Discount
      </Typography>
      <span style={{color:'#5eb100'}}>
       - ₹{order.payment.totalProductDiscount}
      </span>
    </div>
    <div
      style={{
        display: "flex",
        justifyContent: "space-between",
        alignItems: "center",  padding:'5px',fontSize:'small',fontWeight:'400'
      }}
    >
      <Typography className="payment" variant="subtitle1">
        Total price ({totalProductCount} items)
      </Typography>
      <span style={{color:'black'}}>
       ₹{order.payment.totalProductPrice}
      </span>
      
    </div>
    <div
      style={{
        display: "flex",
        justifyContent: "space-between",
        padding:'5px',
        alignItems: "center",    backgroundColor:'#c5e1a5' ,borderRadius:'4px'
      }}
    >
      <Typography className="payment" variant="subtitle1">
    <b>   GRANT TOTAL</b>
      </Typography>
      <span>
      <b>₹{order.totalPay}</b>
      </span>
      
    </div>
              </CardContent>
              </Card>

        </Box>
      </Drawer>
    </>
  );
}

export default OrderDetails;
