import React, { useEffect, useState } from "react";
import { Alert, Box, Button, Snackbar, Typography } from "@mui/material";
import WhatsAppIcon from "@mui/icons-material/WhatsApp";
import ShareIcon from "@mui/icons-material/Share";

import OrderStatusStepper from "./OrderStatusstepper";



const WhatsAppShare = ({
  phoneNumber,
  orderNumber,
  brandName,
  trackingId,
  statusMessage,
  OrderId,
}) => {
  const [currentStatus, setCurrentStatus] = useState(statusMessage);
  // const [buttonClicked, setButtonClicked] = useState(null);
  const [snackbarOpen, setSnackbarOpen] = useState(false);
  const [snackbarMessage, setSnackbarMessage] = useState("");
  const formattedOrderNumber = orderNumber.replace(/\D/g, "");

  useEffect(() => {
    setCurrentStatus(statusMessage);
    // setButtonClicked(null);
  }, [OrderId, statusMessage]);

  const getStatusContent = (status) => {
    switch (status) {
      case "PLACED":
        return {
          title: "Order Placed",
          content: "has been received and is pending approval.",
        };
      case "COMPLETED":
        return {
          title: "Order Completed",
          content: "has been completed successfully.",
        };
      case "ACCEPTED":
        return {
          title: "Order Accepted",
          content: "has been Accepted and is being processed.",
        };
      case "REJECTED":
        return { title: "Order Rejected", content: "has been Rejected" };
      default:
        return { title: status, content: status };
    }
  };

  const handleShareClick = (status) => {
    const { title, content } = getStatusContent(status);
    if (
      !phoneNumber ||
      !orderNumber ||
      !brandName ||
      !trackingId ||
      !title ||
      !content
    ) {
      console.error("Missing required data for sharing.");
      return;
    }

    const whatsappUrl = `https://api.whatsapp.com/send/?phone=${phoneNumber}&text=%2A${title}%2A%0A%0AHI+Customer%2C+your+order+number+%2A${formattedOrderNumber}%2A+from+%2A${brandName}%2A+${content}%0A%0ATrack+Your+Order+at%3A%0Ahttps%3A%2F%2Fnoava.in%2Fconfirmation/${trackingId}%0A%0AHappy+Shopping+%F0%9F%98%8A%0Ahttps%3A%2F%2Fnoava.in&type=phone_number&app_absent=0`;
    window.open(whatsappUrl, "_blank");
  };

  const updateOrderStatus = async (status, orderId) => {
    try {
      // Assuming the API call is implemented here
      // const response = await axios.post(`${apiUrl}/updateOrderStatus`, { status, orderId });
      setCurrentStatus(status);
      setSnackbarMessage(`Order has been ${status.toLowerCase()}.`);
      setSnackbarOpen(true);
    } catch (error) {
      console.error("Failed to update order status", error);
    }
  };

  // const handleAcceptClick = () => {
  //   if (currentStatus === "REJECTED") {
  //     return; 
  //   }
  //   setButtonClicked("Accepted");
  //   updateOrderStatus("ACCEPTED", OrderId);
  // };

  // const handleRejectClick = () => {
  //   setButtonClicked("REJECTED");
  //   updateOrderStatus("REJECTED", OrderId);
  // };

  const handleCloseSnackbar = () => {
    setSnackbarOpen(false);
  };

  return (
    <div>
      <Box
        sx={{
          display: "flex",
          alignItems: "center",
          border: "1px solid #ccc",
          padding: "5px",
          borderRadius: "8px",
          width: "100%",
          marginBottom: "5px",
          backgroundColor: "#c5e1a5",
          cursor: "pointer",
          "&:hover": {
            backgroundColor: "#a5d6a7",
          },
        }}
        onClick={() => handleShareClick(currentStatus)}
        aria-label="Share order status on WhatsApp"
      >
        <Box sx={{ display: "flex", alignItems: "center", padding: "2px" }}>
          <WhatsAppIcon sx={{ fontSize: "20px", color: "rgb(0 129 27)" }} />
        </Box>

        <Box sx={{ flex: 1, textAlign: "left", padding: "2px" }}>
          <Typography
            variant="subtitle1"
            sx={{
              fontSize: "12px",
              fontWeight: "bold",
              color: "rgb(0 129 27)",
            }}
          >
            Share Order Status
          </Typography>
        </Box>

        <Box sx={{ display: "flex", alignItems: "center", padding: "2px" }}>
          <ShareIcon sx={{ fontSize: "20px", color: "rgb(0 129 27)" }} />
        </Box>
      </Box>

    <Box style={{marginBottom:'15px'}}></Box> 

      {(currentStatus === "Ready to ship" ||  currentStatus === "delivered" || currentStatus === "shipped") && (
  <OrderStatusStepper currentStatus={currentStatus} />
)}

      <Snackbar
        open={snackbarOpen}
        autoHideDuration={6000}
        onClose={handleCloseSnackbar}
        message={snackbarMessage}
        action={
          <Button color="inherit" onClick={handleCloseSnackbar}>
            Close
          </Button>
        }
      >
        <Alert
          onClose={handleCloseSnackbar}
          severity="success"
          sx={{ width: "100%" }}
        >
          {snackbarMessage}
        </Alert>
      </Snackbar>
    </div>
  );
};

export default WhatsAppShare;
