
import React, { useState } from 'react';
import { Dialog, DialogTitle, DialogContent, DialogActions, TextField, Typography, Box } from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import DoneIcon from '@mui/icons-material/Done';
import IconButtonWithText from '../../../reusable/button/IconButtonWithText ';

const UpdateDescriptionDialog = ({ open, handleClose, description, handleSave }) => {
  const [text, setText] = useState(description);

  const [isSubmitting, setIsSubmitting] = useState(false);

  const handleChange = (e) => {
    let newText = e.target.value;

 
    newText = newText.replace(/\s{2,}/g, ' ');

  
    if (newText.length <= 4000) {
      setText(newText);
    }
  };

  const handleSaveClick = async () => {
    setIsSubmitting(true);

    try {
      await handleSave(text); 
      handleClose();
    } catch (error) {
      console.error('Failed to save description:', error);
    
    } finally {
      setIsSubmitting(false);
    }
  };


  return (
    <Dialog open={open} onClose={handleClose} maxWidth="sm" fullWidth>
      <DialogTitle
        sx={{
          padding: '16px',
          display: 'flex',
          justifyContent: 'space-between',
          fontFamily: 'Poppins, sans-serif',
          fontWeight: 'bold',
        }}
      >
        Update Description
        <CloseIcon onClick={handleClose} />
      </DialogTitle>
      <DialogContent
        sx={{
          padding: '16px',
        }}
      ><Box > <Typography variant="caption" sx={{ mt: 1, textAlign: 'left', color: 'blue', display: 'flex',fontSize:'15px',
        justifyContent: 'flex-end',  }}>
      <b>  {text.length}/4000</b>
      </Typography></Box>
       
        <TextField
          variant="outlined"
          size="small"
          multiline
          sx={{
            width: '100%',
            height: '200px',
            borderRadius: '5px',
            backgroundColor: '#efefef',
            '& .MuiOutlinedInput-root': {
              '& fieldset': {
                border: 'none',
              },
            },
            overflow: 'auto', 
          }}
          placeholder="Enter description here..."
          value={text}
          onChange={handleChange}
          inputProps={{ maxLength: 4000 }} // HTML5 attribute for additional limitation
        />
        
      </DialogContent>
      <DialogActions
        sx={{
          padding: '16px',
        }}
      >
        <IconButtonWithText icon={DoneIcon} text="SAVE" onClick={handleSaveClick} disabled={isSubmitting} />
      </DialogActions>
    </Dialog>
  );
};

export default UpdateDescriptionDialog;
