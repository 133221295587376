import React from 'react';
import { Dialog, DialogTitle, DialogContent, DialogActions, Box, Typography, TextField, InputAdornment } from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import AddIcon from '@mui/icons-material/Add';
import IconButtonWithText from '../../../reusable/button/IconButtonWithText ';


function AddProductButtonDialog({ open, handleClose, handleChange, handleAddProduct, newProduct, theme }) {
  return (
    <Dialog
      open={open}
      onClose={handleClose}
      maxWidth="md" 
      fullWidth 
      PaperProps={{
        sx: {
          width: "600px",
          maxWidth: "none", 
        },
      }}
    >
      <DialogTitle
        sx={{
          backgroundColor: "#02ac02db",
          color: "white",
          textAlign: "center",
          fontSize: "16px",
          fontWeight: "500",
          padding: "14px",
          fontFamily: "Poppins, sans-serif",
        }}
      >
        Add New Product
      </DialogTitle>
      <DialogContent style={{ padding: "20px" }}>
        <Box style={{ justifyContent: "space-between", display: "flex" }}>
          <Typography variant="body1" style={{ marginBottom: "5px" }}>
            <span
              style={{
                fontFamily: "Poppins, sans-serif",
                fontWeight: "300",
                color: theme.palette.primary.text,
              }}
            >
              Product Name
            </span>
          </Typography>
          <Typography
            variant="caption"
            sx={{
              textAlign: "left",
              color: "blue",
              display: "flex",
              fontSize: "15px",
              justifyContent: "flex-end",
            }}
          >
            <b style={{ color: theme.palette.primary.text }}>{newProduct.name.length}/100</b>
          </Typography>
        </Box>
        <TextField
          type="text"
          name="name"
          variant="outlined"
          size="small"
          className="customTexName"
          placeholder="Enter the product name"
          fullWidth
          value={newProduct.name}
          onChange={handleChange}
          autoComplete="off"
          inputProps={{ maxLength: 100 }}
          sx={{
            marginBottom: "10px",
            "& .MuiOutlinedInput-root": {
              "& fieldset": {
                border: "none",
              },
            },
          }}
        />
        <Box
          sx={{
            display: "flex",
            justifyContent: 'space-between',
            alignItems: "center",
            marginBottom: "10px",
          }}
        >
          <Typography
            variant="body1"
            style={{ marginBottom: "5px" }}
          >
            <span
              style={{
                fontFamily: "Poppins, sans-serif",
                fontWeight: "300",
                color: theme.palette.primary.text,
              }}
            >
              Selling price
            </span>
          </Typography>

          <TextField
            type="number"
            variant="outlined"
            size="small"
            name="price"
            className="customTextField"
            placeholder="0"
            value={newProduct.price}
            onChange={handleChange}
            sx={{
              "& .MuiOutlinedInput-root": {
                "& fieldset": {
                  border: "none",
                },
              },
            }}
            InputProps={{
              startAdornment: (
                <InputAdornment position="start" style={{ color: "black" }}>
                  <b>₹</b>
                </InputAdornment>
              ),
            }}
          />
        </Box>
      </DialogContent>
      <DialogActions sx={{ padding: "20px" }}>
        <button
          onClick={handleClose}
          type="button"
          className="btn btn-outline-secondary d-flex justify-content-between align-items-center px-4"
        >
          <CloseIcon
            sx={{ fontSize: "20px !important" }}
            className="me-2"
          />
          CANCEL
        </button>
        <IconButtonWithText
          icon={AddIcon}
          text="ADD"
          onClick={handleAddProduct}
        />
      </DialogActions>
    </Dialog>
  );
}

export default AddProductButtonDialog;
