import React from "react";
import {
  Box,
  Typography,
  List,
  ListItem,
  ListItemText,
  ListItemSecondaryAction,
  IconButton,
  Dialog,
  Avatar,
  DialogTitle,
  DialogContent,
} from "@mui/material";
import YouTubeIcon from "@mui/icons-material/YouTube";
import DeleteIcon from "@mui/icons-material/Delete";
import CloseIcon from "@mui/icons-material/Close";

const VideoListModal = ({
  open,
  handleClose,
  videos,
  handleViewVideo,
  handleDeleteVideo,
}) => {
  return (
    <Dialog open={open} onClose={handleClose} maxWidth="md" fullWidth>
      <DialogTitle>
        <div
          style={{
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
          }}
        >
          <Box
            className="marginBottom5"
            sx={{ display: "flex", alignItems: "center" }}
          >
            <YouTubeIcon
              style={{
                marginRight: "5px",
                fontSize: "20px",
                color: "red",
              }}
            />
            <Typography
              variant="body1"
              style={{ fontFamily: "Poppins, sans-serif", color: "black" }}
            >
              <b>YouTube Video</b>
            </Typography>
          </Box>
          <IconButton onClick={handleClose}>
            <CloseIcon />
          </IconButton>
        </div>
      </DialogTitle>
      <DialogContent>
        <Box sx={{ padding: "16px" }}>
          <List>
            {videos.map((video, index) => (
              <ListItem
                key={index}
                sx={{ display: "flex", alignItems: "center" }}
                onClick={() => handleViewVideo(video)}
              >
                <Box
                  sx={{
                    position: "relative",
                    width: "100%",
                    maxWidth: "100px", // Maximum width for larger screens
                    height: "auto",
                    aspectRatio: "16/9", // Maintain aspect ratio
                    marginRight: "16px",
                    "@media (max-width: 600px)": {
                      // Adjust for mobile view
                      maxWidth: "80px", // Smaller max width on mobile
                      marginRight: "8px", // Smaller margin on mobile
                    },
                  }}
                >
                  {/* Video Thumbnail */}
                  <Avatar
                    variant="square"
                    src={`https://img.youtube.com/vi/${getYouTubeVideoId(
                      video
                    )}/default.jpg`}
                    sx={{
                      width: "100%",
                      height: "100%",
                      borderRadius: "5px",
                    }}
                  />

                  {/* Centered YouTube Icon */}
                  <YouTubeIcon
                    sx={{
                      position: "absolute",
                      top: "50%",
                      left: "50%",
                      transform: "translate(-50%, -50%)",
                      color: "white",
                      fontSize: 20,
                      "@media (max-width: 600px)": {
                        fontSize: 16,
                      },
                    }}
                  />
                </Box>

                {/* Video Link */}
                <ListItemText
                  primary={video}
                  primaryTypographyProps={{
                    style: {
                      whiteSpace: "nowrap",
                      overflow: "hidden",
                      textOverflow: "ellipsis",
                    },
                  }}
                  onClick={() => handleViewVideo(video)}
                  sx={{ cursor: "pointer" }}
                />

                {/* Delete Icon on the Right */}
                <ListItemSecondaryAction>
                  <IconButton
                    edge="end"
                    style={{ color: "red" }}
                    aria-label="delete"
                    onClick={() => handleDeleteVideo(video)}
                  >
                    <DeleteIcon />
                  </IconButton>
                </ListItemSecondaryAction>
              </ListItem>
            ))}
          </List>
        </Box>
      </DialogContent>
    </Dialog>
  );
};

const getYouTubeVideoId = (url) => {
  const regex =
    /(?:https?:\/\/)?(?:www\.)?(?:youtube\.com\/(?:[^\/\n\s]+\/\S+\/|(?:v|e(?:mbed)?)\/|.*[?&]v=)|youtu\.be\/)([a-zA-Z0-9_-]{11})/;
  const match = url.match(regex);
  return match ? match[1] : null;
};

export default VideoListModal;
