
import React, { useState } from "react";
import { styled, useTheme } from "@mui/material/styles";
import Box from "@mui/material/Box";
import MuiDrawer from "@mui/material/Drawer";
import List from "@mui/material/List";
import CssBaseline from "@mui/material/CssBaseline";
import Typography from "@mui/material/Typography";
import Divider from "@mui/material/Divider";
import DashboardIcon from "@mui/icons-material/Dashboard";
import ShoppingCartIcon from '@mui/icons-material/ShoppingCart';
import ListItem from "@mui/material/ListItem";
import ListItemButton from "@mui/material/ListItemButton";
import ListItemIcon from "@mui/material/ListItemIcon";
import ListItemText from "@mui/material/ListItemText";
import HomeIcon from "@mui/icons-material/Home";
import { useLocation, useNavigate } from "react-router-dom";
// import logo from "../../../assets/images/profile/favicon-32x32.png";
import CloseIcon from '@mui/icons-material/Close';
import useMediaQuery from "@mui/material/useMediaQuery";
import { Collapse } from "@mui/material";
import CheckCircleIcon from "@mui/icons-material/CheckCircle";
import ExpandLessIcon from "@mui/icons-material/ExpandLess";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import RadioButtonUncheckedIcon from "@mui/icons-material/RadioButtonUnchecked";
import StoreIcon from '@mui/icons-material/Store';
import ThumbUpAltIcon from "@mui/icons-material/ThumbUpAlt";
const drawerWidth = 240;

const openedMixin = (theme) => ({
  width: drawerWidth,
  transition: theme.transitions.create("width", {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.enteringScreen,
  }),
  overflowX: "hidden",
});

const closedMixin = (theme) => ({
  transition: theme.transitions.create("width", {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.leavingScreen,
  }),
  overflowX: "hidden",
  width: `calc(${theme.spacing(7)} + 1px)`,
  [theme.breakpoints.up("sm")]: {
    width: `calc(${theme.spacing(8)} + 1px)`,
  },
});

const Drawer = styled(MuiDrawer, {
  shouldForwardProp: (prop) => prop !== "open",
})(({ theme, open }) => ({
  width: drawerWidth,
  flexShrink: 0,
  whiteSpace: "nowrap",
  boxSizing: "border-box",
  ...(open && {
    ...openedMixin(theme),
    "& .MuiDrawer-paper": openedMixin(theme),
  }),
  ...(!open && {
    ...closedMixin(theme),
    "& .MuiDrawer-paper": closedMixin(theme),
  }),
}));

export default function Menu({ open, setOpen }) {
  const theme = useTheme();
  const location = useLocation();
  const isSmallScreen = useMediaQuery(theme.breakpoints.down('md')); // Use media query to check screen size
  const [activeItem, setActiveItem] = useState({ section: "", item: "" });
  const navigate = useNavigate();
   

  const [collapseState, setCollapseState] = useState({});
  const listItemButtonSx = {
    display: "flex",
    alignItems: "center",
    justifyContent: open ? "initial" : "center",
    padding: "0px 10px",
    fontSize: "15px",
    color: theme.palette.gray.main,
    outlineWidth: 0,
    textOverflow: "ellipsis",
    overflow: "hidden",
    letterSpacing: ".5px",
    borderRadius: "0.25rem",
    transition: "all .3s ease-out",
    minHeight: 40,
    marginTop: "4px",
    px: 2.5,
    "&:hover": {
      color: theme.palette.menu.light,
      textDecoration: "none",
      backgroundColor: theme.palette.menu.lightGreen,
      borderRadius: "0.5rem",
    },
    "&.active": {
      color: theme.palette.menu.light,
      textDecoration: "none",
      backgroundColor: theme.palette.menu.lightGreen,
      borderRadius: "0.5rem",
    },
  };
  const handleCollapse = (section) => {
    setCollapseState((prevState) => ({
      ...prevState,
      [section]: !prevState[section],
    }));
  };
  const listItemIconSx = {
    color: theme.palette.menu.iconWithText,
    minWidth: 0,
    mr: open ? 3 : "auto",
    justifyContent: "center",
    "&:hover": {
      color: theme.palette.menu.light,
      backgroundColor: theme.palette.menu.lightGreen,
    },
  };

  const handleItemClick = (section, item) => {
    setActiveItem({ section, item });
  };

  const handleNavigation = (path, section) => {
    navigate(path);
    handleItemClick(section, section);
    if (isSmallScreen) { // Close the drawer only on small screens
      setOpen(false);
    }
  };
  const handleItemClickDrop = (section, item) => {
    setActiveItem({ section, item });
    if (isSmallScreen) {
      setOpen(false); 
    }
  };
  const nestedListItemButtonSx = {
    ...listItemButtonSx,
    // pl: open ? 4 : 2,
  };
  
  return (
    <>
      {open && (
        <Box sx={{ display: "flex" }}>
          <CssBaseline />
          <Drawer variant="permanent" open={open}>
            <Box sx={{ display: "flex", justifyContent: "space-between" }}>
              <Box
                sx={{
                  display: "flex",
                  alignItems: "center",
                  gap: "15px",
                  paddingLeft: "12px",
                }}
              >
                {/* <img alt="image1" src={logo}  />  */}
                <Typography variant="h6"><b> Nego Tube</b> </Typography>
              </Box>

              <div style={{ padding: "10px" }}>
                <CloseIcon
                  style={{ paddingTop: 2, paddingLeft: 3, width: "2rem" }}
                  onClick={() => setOpen(!open)}
                />
              </div>
            </Box>
            <Divider />
            <List sx={{ p: 1 }}>
            <List sx={{ paddingTop: "0px !important", paddingBottom: "5px !important" }}>
              <ListItem disablePadding sx={{ display: "block" }}>
                <ListItemButton
                  sx={{
                    ...listItemButtonSx,
                    ...(location.pathname === "/dashboard" && {
                      backgroundColor: theme.palette.menu.lightGreen,
                    }),
                  }}
                  onClick={() => handleNavigation("/dashboard", "dashboard")}
                  className={location.pathname === "/dashboard" ? "active" : ""}
                >
                  <ListItemIcon sx={listItemIconSx}>
                    <DashboardIcon />
                  </ListItemIcon>
                  {open && (
                    <ListItemText primary="Dashboard" sx={{ opacity: 1 }} />
                  )}
                </ListItemButton>
              </ListItem>
            </List>

         



<List sx={{ paddingTop: "0px !important", paddingBottom: "5px !important" }}>
        <ListItem disablePadding sx={{ display: "block" }} onClick={() => handleCollapse("home")}>
          <ListItemButton
            sx={{
              ...listItemButtonSx,
              ...(activeItem.section === "home" && activeItem.item === "home" && {
                backgroundColor: "rgb(236 247 255)",
              }),
            }}
            onClick={() => handleItemClick("home", "home")}
            className={
              activeItem.section === "home" && activeItem.item === "home"
                ? "active"
                : ""
            }
          >
            <ListItemIcon sx={listItemIconSx}>
              <HomeIcon />
            </ListItemIcon>
            {open && (
              <>
                <ListItemText primary="Home" sx={{ opacity: 1 }} />
                {collapseState.home ? <ExpandLessIcon /> : <ExpandMoreIcon />}
              </>
            )}
          </ListItemButton>
        </ListItem>
        <Collapse in={collapseState.home && open} timeout="auto" unmountOnExit>
          {["Slider", "Product promo"].map((text) => (
            <ListItem key={text} disablePadding>
              <ListItemButton
                sx={{
                  ...nestedListItemButtonSx,
                  ...(activeItem.section === "home" && activeItem.item === text.toLowerCase() && {
                    backgroundColor: "rgb(236 247 255)",
                  }),
                }}
                onClick={() => {
                  navigate(`./${text.toLowerCase()}`);
                  handleItemClickDrop("home", text.toLowerCase());
                }}
                className={
                  activeItem.section === "home" && activeItem.item === text.toLowerCase()
                    ? "active"
                    : ""
                }
              >
                <ListItemIcon sx={listItemIconSx}>
                  <RadioButtonUncheckedIcon size="small" sx={{ width: "9px !important" }} />
                </ListItemIcon>
                <ListItemIcon sx={{ ...listItemIconSx, minWidth: "auto", marginRight: "8px" }}></ListItemIcon>
                {open && <ListItemText primary={text} sx={{ opacity: 1 }} />}
              </ListItemButton>
            </ListItem>
          ))}
        </Collapse>
      </List>



              {/* Home */}

              <List sx={{ paddingTop: "0px !important", paddingBottom: "5px !important" }}>
                <ListItem disablePadding sx={{ display: "block" }}>
                  <ListItemButton
                    sx={{
                      ...listItemButtonSx,
                      ...(location.pathname === "/add-store" && {
                        backgroundColor: theme.palette.menu.lightGreen,
                      }),
                    }}
                    onClick={() => handleNavigation("add-store", "add-store")}
                    className={location.pathname === "/add-store" ? "active" : ""}
                  >
                    <ListItemIcon sx={listItemIconSx}>
                      <StoreIcon />
                    </ListItemIcon>
                    {open && (
                      <ListItemText primary="Add store" sx={{ opacity: 1 }} />
                    )}
                  </ListItemButton>
                </ListItem>
              </List>


              <List sx={{ paddingTop: "0px !important", paddingBottom: "5px !important" }}>
                <ListItem disablePadding sx={{ display: "block" }}>
                  <ListItemButton
                    sx={{
                      ...listItemButtonSx,
                      ...(location.pathname === "/ManageProduct" && {
                        backgroundColor: theme.palette.menu.lightGreen,
                      }),
                    }}
                    onClick={() => handleNavigation('/ManageProduct', "Manage Product")}
                    className={location.pathname === "/ManageProduct" ? "active" : ""}
                  >
                    <ListItemIcon sx={listItemIconSx}>
                      <ShoppingCartIcon />
                    </ListItemIcon>
                    {open && (
                      <ListItemText primary="Manage Product" sx={{ opacity: 1 }} />
                    )}
                  </ListItemButton>
                </ListItem>
              </List>





              <List
            sx={{
              paddingTop: "0px !important",
              paddingBottom: "5px !important",
            }}
          >
            <ListItem
              disablePadding
              sx={{ display: "block" }}
              onClick={() => handleCollapse("Order")}
            >
              <ListItemButton
                sx={{
                  ...listItemButtonSx,
                  ...(activeItem.section === "Order" &&
                    activeItem.item === "Order" && {
                      backgroundColor: "rgb(236 247 255)",
                    }),
                }}
                onClick={() => handleItemClick("Order", "Order")}
                className={
                  activeItem.section === "Order" && activeItem.item === "Order"
                    ? "active"
                    : ""
                }
              >
                <ListItemIcon sx={listItemIconSx}>
                  <CheckCircleIcon  />
                </ListItemIcon>
                {open && (
                  <>
                    <ListItemText primary="Orders" sx={{ opacity: 1 }} />
                    {collapseState.Order ? (
                      <ExpandLessIcon />
                    ) : (
                      <ExpandMoreIcon />
                    )}
                  </>
                )}
              </ListItemButton>
            </ListItem>
            <Collapse
              in={collapseState.Order && open}
              timeout="auto"
              unmountOnExit
            >
              {[ "Accepted", "All orders",].map((text) => (
                <ListItem key={text} disablePadding>
                  <ListItemButton
                    sx={{
                      ...nestedListItemButtonSx,
                      ...(activeItem.section === "Order" &&
                        activeItem.item === text.toLowerCase() && {
                          backgroundColor: "rgb(236 247 255)",
                        }),
                    }}
                    onClick={() => {
                      navigate(`./${text.toLowerCase()}`);
                      handleItemClickDrop("Order", text.toLowerCase());
                    }}
                    className={
                      activeItem.section === "Order" &&
                      activeItem.item === text.toLowerCase()
                        ? "active"
                        : ""
                    }
                  >
                    <ListItemIcon sx={listItemIconSx}>
                      <RadioButtonUncheckedIcon
                        size="small"
                        sx={{ width: "9px !important" }}
                      />
                    </ListItemIcon>
                    <ListItemIcon
                      sx={{
                        ...listItemIconSx,
                        minWidth: "auto",
                        marginRight: "8px",
                      }}
                    >
                   
                      {text === "Accepted" && (
                        <ThumbUpAltIcon fontSize="16px" />
                      )}
                      {text === "All orders" && <CheckCircleIcon fontSize="16px" />}
                      {/* {text === "Rejected" && <CancelIcon fontSize="16px" />} */}
                    </ListItemIcon>
                    {open && (
                      <ListItemText primary={text} sx={{ opacity: 1 }} />
                    )}
                  </ListItemButton>
                </ListItem>
              ))}
            </Collapse>
          </List>


            </List>
          </Drawer>
        </Box>
      )}
    </>
  );
}
