import React, { useState } from "react";
import {
  Drawer,
  IconButton,
  Typography,
  Box,
  useMediaQuery,
  Grid,

  TextField,

  Button,
 
  Card,
  CardContent,
} from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";

import CameraAltIcon from "@mui/icons-material/CameraAlt";
import EditIcon from "@mui/icons-material/Edit";


import AddIcon from "@mui/icons-material/Add";
import { useTheme } from "@mui/material/styles";
import "../ProductPage/Product.css";

import ImageUploadGaleryDialog from "../../reusable/button/image/ImageUploadGaleryDialog";
import DeleteIcon from "@mui/icons-material/Delete";

import PriceSection from "./PriceSession";
import YouTubeURLInput from "./YoutubeUrl";
import NameEdit from "./EditDialog/NameEdit";
import UpdateDescriptionDialog from "./EditDialog/EditDescription";
import ImageCropDialog from "../../reusable/button/image/ImageCropDialog";
const RightSideDrawer = ({ open, toggleDrawer, data }) => {
  const isSmallScreen = useMediaQuery((theme) => theme.breakpoints.down("sm"));
  const [imageSrc, setImageSrc] = useState(data?.image || "");
  const [croppedImage, setCroppedImage] = useState(null);
  const [isCropping, setIsCropping] = useState(false);
  const [isEditing, setIsEditing] = useState(false);
  const [newProductName, setNewProductName] = useState(data?.name || "");
  const [isImageDialogOpen, setIsImageDialogOpen] = useState(false);
  const [openModel, setOpenModel] = useState(false);
  const [description, setDescription] = useState("");
  const [isDialogOpen, setIsDialogOpen] = useState(false);
  const [croppedAreaPixels, setCroppedAreaPixels] = useState(null);
  const [uploadedImages, setUploadedImages] = useState([]);
  const [isSelectedUploadImage, setIsselectedUploadImage] = useState(false);
  const theme = useTheme();
  const handleFileChange = (event) => {
    const file = event.target.files[0];
    if (file) {
      const reader = new FileReader();
      reader.onload = () => {
        setImageSrc(reader.result);
        setIsImageDialogOpen(true); // Open the dialog
        setIsCropping(true); // Set to cropping state
      };
      reader.readAsDataURL(file);
    }
  };
  const handleClickOpen = () => {
    setOpenModel(true);
  };

  const handleClose = () => {
    setOpenModel(false);
  };

  const onCropComplete = (_, croppedAreaPixels) => {
    setCroppedAreaPixels(croppedAreaPixels);
  };
  const handleImageRemove = (index) => {
    setUploadedImages((prevImages) => prevImages.filter((_, i) => i !== index));
  };
  const handleSave = () => {
    // Handle save logic here
    handleClose();
  };
  const generateCroppedImage = async () => {
    const canvas = document.createElement("canvas");
    const image = new Image();
    image.src = imageSrc;
    await new Promise((resolve) => {
      image.onload = resolve;
    });

    const ctx = canvas.getContext("2d");
    canvas.width = croppedAreaPixels.width;
    canvas.height = croppedAreaPixels.height;
    ctx.drawImage(
      image,
      croppedAreaPixels.x,
      croppedAreaPixels.y,
      croppedAreaPixels.width,
      croppedAreaPixels.height,
      0,
      0,
      croppedAreaPixels.width,
      croppedAreaPixels.height
    );

    const croppedImageDataUrl = canvas.toDataURL("image/jpeg");
    handleCropDone(croppedImageDataUrl);
  };
  const handleDialogOpenGalery = () => {
    setIsDialogOpen(true);
  };

  const handleDialogClose = () => {
    setIsDialogOpen(false);
  };

  const handleChange = (event) => {
    setDescription(event.target.value);
  };
  const handleCropDone = (croppedImage) => {
    setCroppedImage(croppedImage);
    setIsCropping(false);
    setIsImageDialogOpen(false); // Close the dialog after cropping
  };


  const handleEditClick = () => {
    setIsEditing(true);
  };
  const handleEditSave = (normalizedName) => {
    data.name = normalizedName; // Assuming data is in the right scope
    setIsEditing(false);
  };
  

  const handleEditCancel = () => {
    setIsEditing(false);
  };



  const handleImageDialogClose = () => {
    setIsImageDialogOpen(false);
    setImageSrc(null);
  };
  const handleImagesUpload = (images) => {
    setUploadedImages((prevImages) => [...prevImages, ...images]);
    setIsselectedUploadImage(true);
  };
  return (
    <Drawer
      anchor="right"
      open={open}
      BackdropProps={{ invisible: true }}
      onClose={toggleDrawer(false)}
      sx={{
        "& .MuiDrawer-paper": {
          width: isSmallScreen ? "100%" : 400,
          boxSizing: "border-box",
        },
      }}
    >
      <Box sx={{ width: "100%", padding: 2, backgroundColor: "#f7f7f7" }}>
        <Box
          sx={{
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
          }}
        >
          <div>
            <Typography
              variant="h6"
              style={{ fontFamily: "Poppins, sans-serif" }}
            >
              Manage Product
            </Typography>
            <Typography
              variant="body2"
              style={{ fontFamily: "Poppins, sans-serif" }}
            >
              {data?.name || "No product selected"}
            </Typography>
          </div>
          <IconButton onClick={toggleDrawer(false)} size="large">
            <CloseIcon />
          </IconButton>
        </Box>

        {data ? (
          <Grid
            container
            spacing={2}
            width="100%"
            marginTop="0px"
            marginLeft="0px"
          >
            <Grid item xs={12} style={{ marginTop: "20px", padding: "0px" }}>
              <Box
                sx={{
                  position: "relative",
                  width: "80%",
                  maxWidth: "300px",
                  height: 0,
                  paddingBottom: "80%",
                  backgroundColor: "#333",
                  borderRadius: "8px",
                  overflow: "hidden",
                  margin: "0 auto",
                }}
              >
                <img
                  src={croppedImage || data.image}
                  alt={data.name}
                  style={{
                    position: "absolute",
                    top: 0,
                    left: 0,
                    width: "100%",
                    height: "100%",
                    objectFit: "cover",
                    borderRadius: "8px",
                  }}
                />
              </Box>
            </Grid>

            <Grid
              item
              xs={12}
              style={{ marginTop: "15px", overflowX: "auto", padding: "0px" }}
            >
              <Box
                sx={{
                  display: "flex",
                  alignItems: "center",
                  gap: "10px",
                  whiteSpace: "nowrap",
                }}
              >
                <Box
                  sx={{
                    position: "relative",
                    display: "inline-block",
                  }}
                >
                  <img
                    src={croppedImage || data.image}
                    alt={data.name}
                    style={{
                      height: "50px",
                      width: "50px",
                      borderRadius: "5px",
                    }}
                  />
                  <IconButton
                    sx={{
                      position: "absolute",
                      bottom: 0,
                      right: 0,
                      backgroundColor: "rgba(255, 255, 255, 0.8)",
                      borderRadius: "5px",
                      padding: "2px",
                      minWidth: 0,
                      boxShadow: "0px 0px 5px rgba(0,0,0,0.2)",
                      "&:hover": {
                        backgroundColor: "rgba(255, 255, 255, 0.8)",
                      },
                    }}
                    size="small"
                    component="label"
                  >
                    <CameraAltIcon
                      fontSize="small"
                      style={{ color: "green" }}
                    />
                    <input
                      type="file"
                      accept="image/*"
                      style={{ display: "none" }}
                      onChange={handleFileChange}
                    />
                  </IconButton>
                </Box>

                {isSelectedUploadImage && (
                  <Box sx={{ display: "flex", gap: "10px" }}>
                    {uploadedImages.map((image, index) => (
                      <Box
                        key={index}
                        sx={{
                          position: "relative",
                          display: "inline-block",
                        }}
                      >
                        <img
                          src={URL.createObjectURL(image)}
                          alt={`Uploaded ${index}`}
                          style={{
                            height: "50px",
                            width: "50px",
                            borderRadius: "5px",
                          }}
                        />
                        <IconButton
                          sx={{
                            position: "absolute",
                            bottom: 0,
                            right: 0,
                            backgroundColor: "rgba(255, 255, 255, 0.8)",
                            borderRadius: "5px",
                            padding: "2px",
                            minWidth: 0,
                            boxShadow: "0px 0px 5px rgba(0,0,0,0.2)",
                            "&:hover": {
                              backgroundColor: "rgba(255, 255, 255, 0.8)", // Ensure no color change on hover
                            },
                          }}
                          size="small"
                          onClick={() => handleImageRemove(index)}
                        >
                          <DeleteIcon
                            fontSize="small"
                            style={{
                              color: "red",
                              backgroundColor: "#00000000",
                            }}
                          />
                        </IconButton>
                      </Box>
                    ))}
                  </Box>
                )}

                <Box>
                  <Button
                    onClick={handleDialogOpenGalery}
                    sx={{
                      height: "50px",
                      width: "50px",
                      borderRadius: "5px",
                      minWidth: 0,
                      padding: 0,
                      display: "flex",
                      justifyContent: "center",
                      alignItems: "center",
                      backgroundColor: "green",
                      "&:hover": {
                        backgroundColor: "green",
                        boxShadow: "none",
                      },
                    }}
                  >
                    <AddIcon style={{ color: "white" }} />
                  </Button>
                </Box>
              </Box>

              <ImageUploadGaleryDialog
                isOpen={isDialogOpen}
                onClose={handleDialogClose}
                onUpload={handleImagesUpload}
              />
            </Grid>

            {/* ///////Nmae Sessision */}
            <Card sx={{ width: "100%", marginTop: "15px", boxShadow: "none" }}>
              <CardContent style={{ padding: "0px" }}>
                <Grid
                  item
                  xs={12}
                  style={{ padding: "16px 16px" }}
                  onClick={handleEditClick}
                >
                  <Box
                    className="marginBottom5"
                    sx={{
                      display: "flex",
                      alignItems: "center",
                      paddingBottom: "6px",
                    }}
                  >
                    <EditIcon
                      style={{
                        marginRight: "5px",
                        fontSize: "18px",
                        color: theme.palette.primary.text,
                      }}
                    />
                    <Typography
                      variant="body1"
                      style={{
                        color: theme.palette.primary.text,
                        fontFamily: "Poppins, sans-serif",
                      }}
                    >
                      <b>Product Name</b>
                    </Typography>
                  </Box>
              

                  <TextField
                    type="text"
                    variant="outlined"
                    size="small"
                    className="customTexName"
                    placeholder="Product name"
                    fullWidth
                    value={data.name}
                    autoComplete="off"
                    sx={{
                      "& .MuiOutlinedInput-root": {
                        "& fieldset": {
                          border: "none",
                        },
                      },
                    }}
                  />
                </Grid>
              </CardContent>
            </Card>
            {/* <Divider
              sx={{
                backgroundColor: "gray",
                border: "5px solid",
                width: "100%",
              }}
            /> */}
            {/* ///////Price Sessision */}
            <Card sx={{ width: "100%", marginTop: "15px", boxShadow: "none" }}>
              <CardContent style={{ padding: "0px" }}>
                <PriceSection order={data} />
              </CardContent>
            </Card>

            {/* Description Session */}
            <Card sx={{ width: "100%", marginTop: "15px", boxShadow: "none" }}>
              <CardContent style={{ padding: "0px" }}>
                <Grid
                  item
                  xs={12}
                  style={{ padding: "16px 16px" }}
                  onClick={handleClickOpen}
                >
                  <Box
                    className="marginBottom5"
                    sx={{
                      display: "flex",
                      alignItems: "center",
                      justifyContent: "space-between",
                      width: "100%",
                    }}
                  >
                    <Box
                      className="marginBottom5"
                      sx={{ display: "flex", alignItems: "center" }}
                    >
                      <EditIcon
                        style={{
                          marginRight: "5px",
                          fontSize: "18px",
                          color: theme.palette.primary.text,
                        }}
                      />
                      <Typography
                        variant="body1"
                        style={{
                          color: theme.palette.primary.text,
                          fontFamily: "Poppins, sans-serif",
                        }}
                      >
                        <b>Description</b>
                      </Typography>
                    </Box>
                    <Box>
                      {/* <EditIcon
                    style={{ marginRight: "5px", fontSize: "20px" }}
                  /> */}
                    </Box>
                  </Box>
                  <Box sx={{ marginTop: "10px" }}>
                    <TextField
                      variant="outlined"
                      size="small"
                      minRows={4}
                      multiline
                      sx={{
                        width: "100%",
                        padding: "8px",
                        // height: "100px",
                        borderRadius: "5px",
                        backgroundColor: "#efefef",
                        "& .MuiOutlinedInput-root": {
                          "& fieldset": {
                            border: "none",
                          },
                        },
                      }}
                      placeholder="Enter description here.."
                    />
                  </Box>
                </Grid>
              </CardContent>
            </Card>
            {/*  Youtube Session */}

            <Card sx={{ width: "100%", marginTop: "15px", boxShadow: "none" }}>
              <CardContent style={{ padding: "0px" }}>
                <YouTubeURLInput />
              </CardContent>
            </Card>
          </Grid>
        ) : (
          <Typography variant="body1">No product selected</Typography>
        )}

        {/* Name Dialog */}
     
        <NameEdit
        isOpen={isEditing}
        handleClose={handleEditCancel}
        value={newProductName}
        onChange={(e) => setNewProductName(e.target.value)}
        onSave={handleEditSave}
      />
        {/* image Dialog */}

        {isCropping && (
        <ImageCropDialog
          isOpen={isImageDialogOpen}
          onClose={handleImageDialogClose}
          imageSrc={imageSrc}
          onCropComplete={onCropComplete}
          onSave={generateCroppedImage}
        />
      )}

        {/* {isCropping && (
          <Dialog
            open={isImageDialogOpen}
            onClose={handleImageDialogClose}
            maxWidth="sm"
            fullWidth
          >
            <DialogTitle style={{ padding: "16px" }}>
              <div
                style={{
                  display: "flex",
                  justifyContent: "space-between",
                  fontFamily: "Poppins, sans-serif",
                  fontWeight: "bold",
                }}
              >
                Crop Image
                <CloseIcon onClick={handleImageDialogClose} />
              </div>
            </DialogTitle>

            <DialogContent>
              <ImageCropper
                imageSrc={imageSrc}
                // onCropDone={handleCropDone}
                // onCancel={handleCropCancel}
                // croppedAreaPixels={croppedAreaPixels}
                // setCroppedAreaPixels={setCroppedAreaPixels}
                onCropComplete={onCropComplete}
              />
            </DialogContent>

            <DialogActions style={{ padding: "16px" }}>
              <IconButtonWithText
                icon={DoneIcon}
                text="SAVE"
                onClick={generateCroppedImage}
              />
            </DialogActions>
          </Dialog>
        )} */}

        {/* Description Dialog */}

        <UpdateDescriptionDialog
        open={openModel}
        handleClose={handleClose}
        description={description}
        handleChange={handleChange}
        handleSave={handleSave}
      />
     
      </Box>
    </Drawer>
  );
};

export default RightSideDrawer;
