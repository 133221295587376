// utils/CookieApi.js


import { getRefreshToken,setAccessToken, getAccessToken, removeAccessToken} from "./CookieUtiles";
const apiBaseUrl = process.env.REACT_APP_API_BASE_URL;

export const createToken = async (navigate) => {
    console.log("create token");
    removeAccessToken();
    try {
        const refreshToken = getRefreshToken();
        if (!refreshToken) {
            navigate('/login');
            console.log("log")
            return false;
        }

        const response = await fetch(`${apiBaseUrl}/create-token`, {
            method: 'GET',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': `Bearer ${refreshToken}`,
            },
        });
console.log("referesh",refreshToken)
        if (response.status === 401) {
            navigate('/login');
            return false;
        }

        const data = await response.json();
        console.log(data)
        if (response.ok && data.results && data.results.accessToken) {
            setAccessToken(data.results.accessToken);
            // setRefreshToken(refreshToken);
            veridiedToken(navigate);
            return true;
        } else {
            navigate('/login');
            return false;
        }
    } catch (error) {
        console.error("Error creating token:", error);
        navigate('/login');
        return false;
    }
};

export const veridiedToken = async (navigate) => {
    console.log("Auth token");
    try {
        const accessToken = getAccessToken();
        const response = await fetch(`${apiBaseUrl}/auth-token`, {
            method: 'GET',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': `Bearer ${accessToken}`,
            },
        });

        if (response.status === 401) {
            const tokenCreated = await createToken(navigate);
            if (!tokenCreated) {
                navigate('/login');
            }
            return;
        }

        const data = await response.json();
        if (response.ok && data.status && data.code === "VERIFIED") {
            console.log("User details:", data.results);
        } else {
            const tokenCreated = await createToken(navigate);
            if (!tokenCreated) {
                navigate('/login');
            }
        }
    } catch (error) {
        console.error("Error authenticating token:", error);
        console.log("Error authenticating token:", error);
        navigate('/login');
    }
};
