
import React from 'react';
import SearchIcon from '@mui/icons-material/Search';

const SearchBar = ({
  placeholder ,
  value,
  onChange,
 
}) => {
  return (
    <div style={{ paddingTop: "10px", position: "relative",  }}>
      <SearchIcon
        style={{
          position: "absolute",
          right: "15px",
          top: "53%",
          transform: "translateY(-50%)",
          color: "gray",
          pointerEvents: "none",
       
        }}
      />
      <input
        style={{
          borderRadius: "20px",
          padding: "6px 15px",
          width: "100%",
          fontSize: "16px",
          border: "none",
          outline: "none",
          backgroundColor: "white",
          paddingLeft: "15px", 
         
        }}
        // type="search"
         className="mb-2"
        value={value}
        placeholder={placeholder} 
        onChange={onChange} 
      />
    </div>
  );
};

export default SearchBar;








// // import React from 'react'
// // import SearchIcon from "@mui/icons-material/Search";
// // import '../../../components/ManageProducts/ProductPage/Search.css'
// // function SearchBox() {
// //   return (
// //     <div>
    
// // <h1>Search Box</h1>

// // <div class="box">

// // 	<i class="fa fa-search" aria-hidden="true"></i>

// // 	<input type="text" name=""/>

// // </div>


// //     </div>
// //   )
// // }

// // export default SearchBox
// import React from 'react';
// import SearchIcon from '@mui/icons-material/Search';

// function SearchBox() {
//   return (
//     <div style={{ paddingTop: '10px', position: 'relative', width: '200px' }}>
//       <SearchIcon
//         style={{
//           position: 'absolute',
//           left: '15px', // Position the icon on the left side
//           top: '60%',
//           transform: 'translateY(-50%)',
//           color: 'gray',
//           pointerEvents: 'none', // Prevents interaction with the icon
//         }}
//       />
//       <input
//         type="search"
//         placeholder="Search..."
//         style={{
//           borderRadius: '20px',
//           padding: '6px 15px',
//           width: '100%', // Adjust width to fill the container, since the width of the container is fixed
//           fontSize: '16px',
//           border: 'none',
//           outline: 'none',
//           backgroundColor: 'white',
//           paddingLeft: '40px', // Add space for the icon
//           paddingRight: '10px', // Space for right side, adjust as needed
//         }}
//       />
//     </div>
//   );
// }

// export default SearchBox;