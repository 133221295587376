
import React, { useState } from 'react';
import { Box,  Dialog, DialogTitle, DialogContent, DialogActions, IconButton, LinearProgress } from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import ImageUpload from './Imageupload';
import DoneIcon from "@mui/icons-material/Done";
const ImageUploadGaleryDialog = ({ isOpen, onClose, onUpload }) => {
  const [selectedImages, setSelectedImages] = useState([]);
  const [loadingImages, setLoadingImages] = useState({});

  const handleImageChange = (event) => {
    const files = Array.from(event.target.files);
    const newImages = files.map((file, index) => ({
      file,
      index: selectedImages.length + index,
      loaded: false
    }));
    setSelectedImages((prevImages) => [...prevImages, ...newImages]);

    newImages.forEach(({ file, index }) => {
      const reader = new FileReader();

      
      const simulateProgress = () => {
        const intervals = [20, 40, 80, 100];
        let step = 0;
        const intervalId = setInterval(() => {
          if (step < intervals.length) {
            setLoadingImages((prev) => ({
              ...prev,
              [index]: { progress: intervals[step], buffer: intervals[step] + 10 }
            }));
            step++;
          } else {
            clearInterval(intervalId);
           
            setLoadingImages((prev) => ({
              ...prev,
              [index]: { progress: 100, buffer: 100 }
            }));
            setSelectedImages((prevImages) =>
              prevImages.map((img) =>
                img.index === index ? { ...img, loaded: true } : img
              )
            );
          }
        }, 1000); 
      };

      reader.onloadstart = () => {
        setLoadingImages((prev) => ({
          ...prev,
          [index]: { progress: 0, buffer: 10 }
        }));
      };

      reader.onloadend = () => {
        simulateProgress();
      };

      // Read file as Data URL
      reader.readAsDataURL(file);
    });
  };

  const handleImageRemove = (index) => {
    setSelectedImages((prevImages) => prevImages.filter((_, i) => i !== index));
    setLoadingImages((prev) => {
      const updated = { ...prev };
      delete updated[index];
      return updated;
    });
  };

  const handleUpload = () => {
    onUpload(selectedImages.map((img) => img.file));
    onClose();
  };

  return (
    <Dialog open={isOpen} onClose={onClose} maxWidth="sm" fullWidth>
      <DialogTitle style={{padding:'16px'}}>
        <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
          Upload Images
          <IconButton onClick={onClose}>
            <CloseIcon />
          </IconButton>
        </div>
      </DialogTitle>

      <DialogContent>
        <ImageUpload onChange={handleImageChange} title="Choose Images" />
        
        <Box sx={{ display: 'flex', flexWrap: 'wrap', gap: '10px', marginTop: '20px' }}>
          {selectedImages.map((image, index) => (
            <Box
              key={index}
              sx={{
                display: 'flex',
                alignItems: 'center',
                gap: '10px',
                position: 'relative',
                width: '100%',
              }}
            >
              <img
                src={URL.createObjectURL(image.file)}
                alt={`Selected ${index}`}
                style={{ height: '50px', width: '50px', borderRadius: '5px' }}
              />
              <Box sx={{ flexGrow: 1 }}>
                <LinearProgress
                  variant="buffer"
                  value={loadingImages[index]?.progress || 0}
                  valueBuffer={loadingImages[index]?.buffer || 0}
                />
              </Box>
              <IconButton
                sx={{
                  backgroundColor: 'rgba(255, 255, 255, 0.8)',
                  borderRadius: '50%',
                  padding: '2px',
                }}
                size="small"
                onClick={() => handleImageRemove(index)}
              >
                {image.loaded ? (
                  <CheckCircleIcon color="success" fontSize="small" />
                ) : (
                  <CloseIcon fontSize="small" />
                )}
              </IconButton>
            </Box>
          ))}
        </Box>
      </DialogContent>

      <DialogActions style={{padding:'16px'}}>
      <button
              onClick={handleUpload}
              type="button"
              className="btn btn-outline-success d-flex justify-content-between align-items-center px-4"
            >
              <DoneIcon sx={{ fontSize: "20px !important" }} className="me-2" />
              SUBMIT
            </button>
      
      </DialogActions>
    </Dialog>
  );
};

export default ImageUploadGaleryDialog;
