



import React, { useState } from 'react';
import { AppBar, Toolbar, Typography, styled, Box, IconButton, Menu, MenuItem, Tooltip, Avatar } from '@mui/material';
import MenuIcon from '@mui/icons-material/Menu';
import Profile from '../../../assets/images/profile/avatar-2.png';
import { useNavigate } from 'react-router-dom';

const StyledToolbar = styled(Toolbar)({
  display: 'flex',
  justifyContent: 'space-between',
  backgroundColor: '#fff',
  color: 'black'
});

const settings = ['Profile', 'Logout'];

const NavBar = ({ setLoggedIn, setDrawerOpen, drawerOpen }) => {
  const [anchorElUser, setAnchorElUser] = useState(null);
  const navigate = useNavigate();

  const handleOpenUserMenu = (event) => {
    setAnchorElUser(event.currentTarget);
  };
  const handleProfileClick = () => {
    navigate('/profile'); 
    handleCloseUserMenu(); 
  };
  const handleCloseUserMenu = () => {
    setAnchorElUser(null);
  };

  const toggleDrawer = () => {
    setDrawerOpen(!drawerOpen);
  };

  const handleLogout = () => {
    setLoggedIn(true);
    navigate('/');
  };

  return (
    
    <AppBar position='sticky'>
      <StyledToolbar>
        <Box sx={{ display: 'flex', alignItems: 'center', gap: '5px' }}>
          <IconButton
            size="large"
            edge="start"
            color="inherit"
            aria-label="menu"
            sx={{ mr: 2 }}
            onClick={toggleDrawer}
          >
            <MenuIcon />
          </IconButton>
        </Box>
        <Box sx={{ display: 'flex', alignItems: 'center', gap: '20px' }}>
          <Box sx={{ display: 'flex', alignItems: 'center', gap: '10px' }}>
            <Box sx={{ height: '63px', borderRight: '1px solid #ddd' }} />
            <Box
              sx={{ display: 'flex', alignItems: 'center', padding: '0 10px', cursor: 'pointer' }}
              onClick={handleOpenUserMenu}
            >
              <Tooltip title="Open settings">
                <IconButton sx={{ p: 0 }}>
                  <Avatar alt="Travis Howard" src={Profile} />
                </IconButton>
              </Tooltip>
              {/* <Box sx={{ ml: 2 }}>
                <Typography variant="body2" color="textSecondary">
                  <span style={{ color: '#413c3c', fontSize: '15px', fontWeight: '500' }}> Admin</span><br />
                  <span style={{ color: '#747474', fontSize: '13px' }}>Profile</span>
                </Typography>
              </Box> */}
            </Box>
            <Box sx={{ height: '63px', borderLeft: '1px solid #ddd' }} />
          </Box>
          <Menu
            sx={{ mt: '25px' }}
            id="menu-appbar"
            anchorEl={anchorElUser}
            open={Boolean(anchorElUser)}
            onClose={handleCloseUserMenu}
          >
            {settings.map((setting) => (
              <MenuItem key={setting} onClick={setting === 'Logout' ? handleLogout : handleProfileClick }>
                <Typography textAlign="center">{setting}</Typography>
              </MenuItem>
            ))}
          </Menu>
        </Box>
      </StyledToolbar>
    </AppBar>
 
  );
};

export default NavBar;
