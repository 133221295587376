
import React, { useState } from 'react';
import { Select, MenuItem, FormControl, InputLabel } from '@mui/material';

const SubcategorySelector = ({ subcategories, onSubcategorySelect }) => {
  const [selectedSubcategory, setSelectedSubcategory] = useState('');

  const handleChange = (event) => {
    const selectedValue = event.target.value;
    setSelectedSubcategory(selectedValue);
    
    const selectedSubcategoryObject = subcategories.find(subcategory => subcategory.id === selectedValue);
    onSubcategorySelect(selectedSubcategoryObject);
  };

  return (
    <FormControl fullWidth variant="outlined" sx={{ marginBottom: 2 ,'.MuiInputBase-root': { borderRadius: '8px' },
        '.MuiOutlinedInput-input': { padding: '12px' }}}>
      <InputLabel>Select Subcategory</InputLabel>
      <Select
      
        value={selectedSubcategory}
        onChange={handleChange}
        label="Select Subcategory"
      >
        <MenuItem value="">
          <em>Select subcategory</em>
        </MenuItem>
        {subcategories.map((subcategory) => (
          <MenuItem key={subcategory.id} value={subcategory.id}>
            {subcategory.name}
          </MenuItem>
        ))}
      </Select>
    </FormControl>
  );
};

export default SubcategorySelector;





// import React from 'react';
// import { List, ListItem, ListItemText } from '@mui/material';

// const SubcategorySelector = ({ subcategories, onSubcategorySelect }) => {
//   console.log("onSubcategorySelect",onSubcategorySelect)
//   return (
//     <List>
//       {subcategories.map(subcategory => (
//         <ListItem button key={subcategory.id} onClick={() => onSubcategorySelect(subcategory)}>
          
//           <ListItemText primary={subcategory.name} />
//         </ListItem>
//       ))}
//     </List>
//   );
// };

// export default SubcategorySelector;
