


import React, { useState } from "react";
import {

  Typography,
  Breadcrumbs,
  Box,
  Grid,


} from "@mui/material";
import CategoryItem from "../mainCategory/Category ";

import AddCircleIcon from "@mui/icons-material/AddCircle";

import electronicsImage from "../../../assets/images/electronics.jpg";
import toys from "../../../assets/images/toys.jpg";
import Furniture from "../../../assets/images/furniture.jpg";
import Clothing from "../../../assets/images/clothing.jpg";
import booksImage from "../../../assets/images/booksImage.jpg";
import groceriesImage from "../../../assets/images/groceriesImage.jpg";
import { useTheme } from "@mui/material/styles";
import GridViewIcon from "@mui/icons-material/GridView";
import IconWithText from "../ProductPage/IconWithText ";
import SearchBar from "../ProductPage/SearchBox";
import AddButton from "../../reusable/button/AddButton";
import ProductTable from "../ProductPage/ProductTable";
import StoreIcon from '@mui/icons-material/Store';
import CommonDialogAdd from "./CommondialogAdd";
import AddProductButtonDialog from "../ProductPage/EditDialog/AddProductButtonDialog";
import useErrorHandle from "../../error/ErrorHandling";
import { useNavigate } from "react-router-dom";
const initialCategories = [
  { id: 1, name: "Electronics ", image: electronicsImage },
  { id: 2, name: "Furniture", image: Furniture },
  { id: 3, name: "Clothing", image: Clothing },
  { id: 4, name: "Toys", image: toys },
  { id: 5, name: "Books", image: booksImage },
  { id: 6, name: "Groceries ", image: groceriesImage },
];

const CategoryManager = ({authToken}) => {
  const [categories, setCategories] = useState(initialCategories);
  const [open, setOpen] = useState(false);
  const [dialogOpen, setDialogOen] = useState(false);
  const [newCategory, setNewCategory] = useState({ name: "", image: "" });
  const navigate = useNavigate();
  
 
  const apiBaseUrl = process.env.REACT_APP_API_BASE_URL_NEGOTUBE;;
  const theme = useTheme();
  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);
  const handlAddDialogeOpen = () => setDialogOen(true);
  const handleAddDialogClose = () => setDialogOen(false);
  const [searchQuery, setSearchQuery] = useState('');
 
  const handleError = useErrorHandle();
  const handleAddProduct = () => {
       console.log("New Product:", newCategory);
    setDialogOen(false);
  };
  
  const handleBack = () => {
    navigate(`/add-store`);
  };

  
  
  const handleSearchChange = (e) => {
    setSearchQuery(e.target.value);
  };
  const handleChange = (event) => {
    const { name, value } = event.target;
    setNewCategory((prev) => ({ ...prev, [name]: value }));
  };



  const handleEditCategory = (id, newName, newImage) => {
    const updatedCategories = categories.map((cat) =>
      cat.id === id ? { ...cat, name: newName, image: newImage } : cat
    );
    setCategories(updatedCategories);
  };

  const handleDeleteCategory = (id) => {
    const updatedCategories = categories.filter((cat) => cat.id !== id);
    setCategories(updatedCategories);
  };



  const handleAddCategory = async () => {
    if (newCategory.name ) {
      const newCategoryItem = {
        storeid: "66ea84db24bcf429b28b223b",
        name: newCategory.name,
      };
    
      try {
        const response = await fetch(`${apiBaseUrl}/add-maincategory`, {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
            'Authorization': `Bearer ${authToken}`,
          },
          body: JSON.stringify(newCategoryItem),
        });
        console.log("Category Auth",authToken)
        if (!response.ok) {
          await handleError(response); 
        }
        if (response.ok) {
          const result = await response.json();
          console.log('Category added:', result);
          setCategories([...categories, { id: Date.now(), ...newCategory }]);
          setNewCategory({ name: "", image: "" });
          handleClose();
        } else {
          console.error('Failed to add category, status:', response.status);
          const errorResponse = await response.text();
          console.error('Error response:', errorResponse);
        }
      } catch (error) {
        console.error('Error:', error);
      }
    }
  };
  
  const linkStyle = {
    // padding: "5px 5px",
    borderRadius: "10px",
    fontSize: "small",
    display: "inline-flex",
    cursor: "pointer",
    backgroundColor:theme.palette.custom.light,
    color: "white",
    transition: "background-color 0.3s",
  };
  const iconStyle = {
    display: "flex",
    alignItems: "center", 
    padding: "5px", 
  };
  
  const textStyle = {
    display: "flex",
    alignItems: "center", 
    padding: "5px",
  };
 
  return (
    <div
      style={{
        backgroundColor: theme.palette.custom.backgroundColor,
        minHeight: "100vh",
        width: "100%",
        display: "flex",
        flexDirection: "column",
      }}
    >
      <div>
        <div>
          <Breadcrumbs aria-label="breadcrumb" separator=">">
          <Typography
              color="textPrimary"
              onClick={handleBack}
              style={linkStyle}
            >
              <Box style={iconStyle}
              >
            <StoreIcon style={{ fontSize: "large" }} />
          </Box>
          <Box style={textStyle}>Stores</Box>
            </Typography>
           
            <Typography
              color="textPrimary"
              // onClick={handleBack}
              style={linkStyle}
            >
              <Box style={iconStyle}
              >
            <GridViewIcon style={{ fontSize: "large" }} />
          </Box>
          <Box style={textStyle}>Category</Box>
            </Typography>
          </Breadcrumbs>
        </div>

        <Grid>
          <Box
            style={{
              display: "flex",
              flexWrap: "wrap",
              justifyContent: "space-between",
              alignItems: "center",
              marginTop: "5px",
            }}
          >
            <Grid
              item
              xs={12}
              sm={6}
              style={{ textAlign: "center", marginBottom: "0.5rem" }}
            >
              <div
                style={{
                  display: "flex",
                  flexDirection: "row",
                  alignItems: "center",
                }}
              >
                <GridViewIcon
                  style={{
                    fontSize: 40,
                    color: theme.palette.custom.light,
                    paddingTop: "1rem",
                    margin: "0 auto",
                  }}
                />
                <Typography
                  gutterBottom
                  variant="h6"
                  align="center"
                  style={{
                    paddingTop: "1rem",
                    color:theme.palette.custom.light,
                    // width: "10rem",
                    margin: "0 auto",
                  }}
                >
                CATEGORY
                </Typography>
              </div>
            </Grid>

            <Grid item xs={12} sm={6} style={{ textAlign: "center" }}>
              <button type="button" className="buttonmain" onClick={handleOpen}>
                <span className="button__icon">
                  <AddCircleIcon />
                </span>
                <span className="button__text">
                  <b>Add Category</b>
                </span>
              </button>
            </Grid>
          </Box>
        </Grid>

        <Grid
          container
          spacing={0}
          //  style={{ marginTop: "0px" }}
        >
                {Array.isArray(categories) && categories.map((category) => (
                  <>

          {/* {categories.map((category) => ( */}
            <Grid
              item
              xs={12}
              sm={6}
              md={4}
              lg={4}
              xl={3}
              key={category.id}
              style={{ padding: "5px" }}
            >
              <CategoryItem
                category={category}
                setCategories={setCategories}
                onClick={handleOpen}
                onEdit={handleEditCategory}
                onDelete={handleDeleteCategory}
              />
            </Grid>
            </>
          ))}
        </Grid>
        <Grid>
          <Box
            style={{
              display: "flex",
              flexWrap: "wrap",
              justifyContent: "space-between",
              alignItems: "center",
              // marginTop: "2px",
            }}
          >
            <Grid
              item
              xs={12}
              sm={6}
              style={{ textAlign: "center", marginBottom: "0.5rem" }}
            >
              <div
                style={{
                  display: "flex",
                  flexDirection: "row",
                  alignItems: "center",
                 
                }}
              >
                <IconWithText  
        text="PRODUCTS"/>
        <SearchBar placeholder="Search..."
        value={searchQuery}
        onChange={handleSearchChange}
       />
                


              </div>
            </Grid>
            <Grid item xs={12} sm={6} style={{ textAlign: "center" }}>
             
              <AddButton className = "buttonProduct" onClick={handlAddDialogeOpen}/>
            </Grid>
          </Box>
        </Grid>
        <AddProductButtonDialog   open={dialogOpen}
        handleClose={handleAddDialogClose}
        handleChange={handleChange}
        handleAddProduct={handleAddProduct}
        newProduct={newCategory}
        theme={theme}/>
      
        <ProductTable    />
        <CommonDialogAdd
        open={open}
        onClose={handleClose}
        title= 'Add Main Category'
        placeholder="Type Here..."
        value={newCategory.name}
        head=" Enter Category Name"
        onChange={handleChange}
        onSubmit={handleAddCategory}
        maxLength={32}
        submitButtonText="ADD"
      />
       
      </div>
    </div>
  );
};
ProductTable.defaultProps = {
  products: [],
};
export default CategoryManager;
