import React, { useState } from 'react';
import { Select, MenuItem, FormControl, InputLabel } from '@mui/material';

const CategorySelector = ({ categories, onCategorySelect }) => {
  const [selectedCategory, setSelectedCategory] = useState('');

  const handleChange = (event) => {
    const selectedValue = event.target.value;
    setSelectedCategory(selectedValue);
    const selectedCategoryObject = categories.find(category => category.id === selectedValue);
    onCategorySelect(selectedCategoryObject);
  };

  return (
    <div>
      <FormControl 
        fullWidth 
        variant="outlined" 
        sx={{ 
          marginBottom: 2, 
          '.MuiInputBase-root': { borderRadius: '8px' },
          '.MuiOutlinedInput-input': { padding: '12px' }
        }}
      >
        <InputLabel>Select Category</InputLabel>
        <Select
          value={selectedCategory}
          onChange={handleChange}
          label="Select Category"
          
        >
          <MenuItem value="">
            <em >Select category</em>
          </MenuItem>
          {categories.map((category) => (
            <MenuItem key={category.id} value={category.id}>
              {category.name}
            </MenuItem>
          ))}
        </Select>
      </FormControl>
    </div>
  );
};

export default CategorySelector;





// // CategorySelector.js
// import React from 'react';
// import { List, ListItem, ListItemText } from '@mui/material';

// const CategorySelector = ({ categories, onCategorySelect }) => {
//     console.log("vat",categories)
//   return (
    
//     <List>
//       {categories.map(category => (
//         <ListItem button key={category.id} onClick={() => onCategorySelect(category)}>
//           <ListItemText primary={category.name} />
//         </ListItem>
//       ))}
//     </List>
//   );
// };

// export default CategorySelector;
