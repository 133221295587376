// CustomDialog.js
import React from 'react';
import { Dialog, DialogTitle, DialogContent, DialogActions, TextField, Box, Typography,  } from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import AddIcon from '@mui/icons-material/Add';
import { useTheme } from '@mui/material/styles';
import IconButtonWithText from '../../reusable/button/IconButtonWithText ';
import CancelButton from '../../reusable/button/CancelButton';

const CommonDialogAdd = ({
  open,
  onClose,
  title,
  placeholder,
  value,
  onChange,
  onSubmit,
  maxLength,
  submitButtonText,head
}) => {
  const theme = useTheme();

  return (
    <Dialog
      open={open}
      onClose={onClose}
      maxWidth="md"
      fullWidth
      sx={{ "& .MuiDialog-paper": { maxWidth: "sm" } }}
    >
      <DialogTitle
        sx={{
          backgroundColor: "#02ac02db",
          color: "white",
          textAlign: "center",
          fontSize: "16px",
          fontWeight: "500",
          padding: "14px",
          fontFamily: "Poppins, sans-serif",
        }}
      >
        <b>{title}</b>
      </DialogTitle>
      <DialogContent style={{ padding: "20px" }}>
        <Box style={{ justifyContent: "space-between", display: "flex" }}>
          <Typography variant="body1" style={{ marginBottom: "5px" }}>
            <b
              style={{
                fontFamily: "Poppins, sans-serif",
                fontWeight: "400",
                color: theme.palette.primary.text,
              }}
            >
             {head}
            </b>
          </Typography>
          <Typography
            variant="caption"
            sx={{
              textAlign: "left",
              color: "blue",
              display: "flex",
              fontSize: "15px",
              justifyContent: "flex-end",
            }}
          >
            <b style={{ color: theme.palette.primary.text }}>
              {value.length}/{maxLength}
            </b>
          </Typography>
        </Box>
        <TextField
          type="text"
          name="name"
          variant="outlined"
          size="small"
          className="customTexName"
          placeholder={placeholder}
          fullWidth
          value={value}
          onChange={onChange}
          autoComplete="off"
          inputProps={{ maxLength: maxLength }}
          sx={{
            marginBottom: "10px",
            "& .MuiOutlinedInput-root": {
              "& fieldset": {
                border: "none",
              },
            },
          }}
        />
      </DialogContent>
      <DialogActions sx={{ padding: "20px" }}>
        <CancelButton     icon={CloseIcon}
              
              onClick={onClose}/>
         
        {/* <button
          onClick={onClose}
          type="button"
          className="btn btn-outline-secondary d-flex justify-content-between align-items-center px-4"
        >
          <CloseIcon sx={{ fontSize: "20px !important" }} className="me-2" />
          CANCEL
        </button> */}
        <IconButtonWithText
              icon={AddIcon}
              text={submitButtonText}
              onClick={onSubmit}
         
            />
        
      </DialogActions>
    </Dialog>
  );
};

export default CommonDialogAdd;
