
import React from 'react';
import DoneIcon from '@mui/icons-material/Done';
import PropTypes from 'prop-types';

const IconButtonWithText = ({ 
  icon: Icon = DoneIcon, 
  text = 'SAVE', 
  onClick 
}) => {
  return (
    <button 
      onClick={onClick} 
      type="button" 
      className="btn btn-outline-success d-flex justify-content-between align-items-center px-4"
    >
      {Icon && <Icon sx={{ fontSize: "20px !important" }} className="me-2" />}
      {text}
    </button>
  );
};

// Define prop types
IconButtonWithText.propTypes = {
  icon: PropTypes.elementType,
  text: PropTypes.string,
  onClick: PropTypes.func.isRequired,
};

export default IconButtonWithText;
