
import React from 'react';
import Typography from '@mui/material/Typography';
import PropTypes from 'prop-types';

const ImageUpload = ({ onChange, previewImage, error ,title}) => {
  return (
    <div>
      <Typography variant="body1" style={{ marginBottom: "5px" }}>
              <b
                style={{ fontWeight: "400", fontFamily: "Poppins, sans-serif" }}
              >
        {title}
      </b>
      </Typography>
      <input
        className="form-control"
        type="file"
        id="formFile"
        accept="image/png, image/jpeg, image/jpg"
        onChange={onChange}
        multiple 
      />
      {previewImage && (
        <div style={{ marginTop: '10px' }}>
          <img
            src={previewImage}
            alt="Preview"
            style={{
              width: '100%',
              maxHeight: '200px',
              objectFit: 'contain',
            }}
          />
        </div>
      )}
    {error && (
        <Typography className="error">{error}</Typography>
      )}
    </div>
  );
};

ImageUpload.propTypes = {
  onChange: PropTypes.func.isRequired,
  previewImage: PropTypes.string,
  error: PropTypes.string,
};

export default ImageUpload;
