
import React from "react";
import {
  Card,
  Table,
  TableBody,
  TableContainer,
  TableHead,
  TableRow,
 
  Skeleton,
  Tooltip,
  IconButton,
  Typography
} from "@mui/material";
import { styled } from "@mui/material/styles";
import VisibilityIcon from "@mui/icons-material/Visibility";
import { useTheme } from "@mui/material/styles";
import TableCell, { tableCellClasses } from "@mui/material/TableCell";

const formatDate = (dateString, theme) => {
  const date = new Date(dateString);
  const day = String(date.getDate()).padStart(2, "0");
  const month = date
    .toLocaleString("default", { month: "short" })
    .toUpperCase();
  const year = String(date.getFullYear()).slice(-2);
  return `${day} ${month} ${year}`;
};

const StyledTableCell = styled(TableCell)(({ theme }) => ({
  [`&.${tableCellClasses.head}`]: {
    backgroundColor: theme.palette.lime.head,
    color: theme.palette.common.black,
    textAlign: "left",
    padding: "16px",
    whiteSpace: "nowrap",
    overflow: "hidden",
    textOverflow: "ellipsis",
    position: 'sticky',
    top: 0,
    zIndex: 1,
  },
  [`&.${tableCellClasses.body}`]: {
    fontSize: 14,
    textAlign: "left",
    whiteSpace: "nowrap",
    overflow: "hidden",
    textOverflow: "ellipsis",
  },
}));

const StyledTableRow = styled(TableRow)(() => ({
  "&:nth-of-type(odd)": {
    // backgroundColor: theme.palette.action.hover,
  },
  "&:last-child td, &:last-child th": {
    border: 0,
  },
}));

function OrderTable({ data, onView, loading }) {
  const theme = useTheme();
 
  return (
    <div className="row">
      <div className="col-lg-12 mx-auto">
        <Card>
          <TableContainer
            sx={{
              maxHeight: 'calc(100vh - 200px)',
              overflowY: 'auto',
              overflowX: 'auto',
            }}
          >
            <Table sx={{ minWidth: 700 }} aria-label="customized table">
              <TableHead>
                <StyledTableRow>
                  <StyledTableCell style={{ width: '0%' }}>Order ID</StyledTableCell>
                  <StyledTableCell style={{ width: '0%' }}>Date</StyledTableCell>
                  <StyledTableCell style={{ width: '15%' }}>Product Name</StyledTableCell>
                  <StyledTableCell style={{ width: '10%' }}>Customer Name</StyledTableCell>
                  <StyledTableCell style={{ width: '0%' }}>Mobile No</StyledTableCell>
                  <StyledTableCell>Address</StyledTableCell>
                  <StyledTableCell style={{ width: '0%' }}>Total</StyledTableCell>
                  <StyledTableCell style={{ width: '0%' }}>Action</StyledTableCell>
                </StyledTableRow>
              </TableHead>

              <TableBody>
                {loading ? (
                  Array.from({ length: 7 }).map((_, index) => (
                    <StyledTableRow key={index}>
                      <StyledTableCell><Skeleton width="100%" /></StyledTableCell>
                      <StyledTableCell><Skeleton width="80%" /></StyledTableCell>
                      <StyledTableCell><Skeleton width={80} /></StyledTableCell>
                      <StyledTableCell><Skeleton width={80} /></StyledTableCell>
                      <StyledTableCell><Skeleton width={80} /></StyledTableCell>
                      <StyledTableCell><Skeleton width={80} /></StyledTableCell>
                      <StyledTableCell><Skeleton width={100} /></StyledTableCell>
                      <StyledTableCell><Skeleton width={80} /></StyledTableCell>
                    </StyledTableRow>
                  ))
                ) : data === null || data.length === 0 ? (
                  <StyledTableRow>
                    <StyledTableCell colSpan={8} style={{ textAlign: 'center' }}>
                      <Typography variant="body1" color="#ff0000d1">
                        No data available
                      </Typography>
                    </StyledTableCell>
                  </StyledTableRow>
                ) : (
                  data.map((order) => (
                    <StyledTableRow key={order.id}>
                      <StyledTableCell>{order.orderId || 'null'}</StyledTableCell>
                      <StyledTableCell>{formatDate(order.createdOn, theme)}</StyledTableCell>
                      <StyledTableCell>
                       
                          <Tooltip title={order.products[0]?.productName || 'N/A'} arrow>
                            {order.products[0]?.productName || 'N/A'}
                          </Tooltip>
                        
                      </StyledTableCell>
                      <StyledTableCell>
                        <Tooltip title={order.customer.name} arrow>
                          {order.customer.name}
                        </Tooltip>
                      </StyledTableCell>
                      <StyledTableCell>{order.customer.mobileNumber}</StyledTableCell>
                      <StyledTableCell>{order.customer.addressOne}</StyledTableCell>
                      <StyledTableCell>₹{order.totalPay}</StyledTableCell>
                      <StyledTableCell style={{ width: "20px" }}>
                        <div className="d-flex justify-content-between align-items-center">
                          <IconButton onClick={() => onView(order)} className="visible" style={{backgroundColor: theme.palette.lime.hovor,}}>
                            <VisibilityIcon style={{ fontSize: "19px",  }} />
                          </IconButton>
                        </div>
                      </StyledTableCell>
                    </StyledTableRow>
                  ))
                )}
              </TableBody>
            </Table>
          </TableContainer>
        </Card>
      </div>
    </div>
  );
}

export default OrderTable;
