import React, { useState } from 'react';
import { Grid, ListItem, ListItemIcon } from '@mui/material';

const ProductSelector = ({ products, onProductSelect }) => {
  const [hoveredProductId, setHoveredProductId] = useState(null); 

  return (
    <Grid container spacing={3}>
      {products.map((product) => (
        <Grid item xs={6} sm={3} md={3} lg={3} xl={2} key={product.id} spacing={3}>
          <ListItem
            button
            onClick={() => onProductSelect(product)}
            style={{ cursor: 'pointer' ,padding:'0px'}}
          >
            <ListItemIcon style={{ marginRight: '10px' }}>
              <img
                src={product.image}
                alt={product.name}
                style={{
                  width: '100px',
                  height: '100px',
                  borderRadius: '1rem',
                  objectFit: 'cover',
                  transition: 'filter 0.3s, background-color 0.3s',
                  filter: hoveredProductId === product.id ? 'brightness(0.7)' : 'brightness(1)',

                }}
                onMouseEnter={() => setHoveredProductId(product.id)} 
                onMouseLeave={() => setHoveredProductId(null)} 
              />
            </ListItemIcon>
          </ListItem>
        </Grid>
      ))}
    </Grid>
  );
};

export default ProductSelector;





// import React, { useState } from 'react';
// import { List, ListItem, ListItemText, ListItemIcon, Checkbox } from '@mui/material';

// const ProductSelector = ({ products, onProductSelect }) => {
//   const [selectedProducts, setSelectedProducts] = useState([]);

//   const handleToggleProduct = (product) => {
//     setSelectedProducts(prevSelected => {
//       const isSelected = prevSelected.find(item => item.id === product.id);
//       if (isSelected) {
//         return prevSelected.filter(item => item.id !== product.id);
//       } else {
//         return [...prevSelected, product];
//       }
//     });
//   };

//   // Call onProductSelect with the updated list of selected products
//   React.useEffect(() => {
//     onProductSelect(selectedProducts);
//   }, [selectedProducts, onProductSelect]);

//   return (
//     <List>
//       {products.map(product => (
//         <ListItem key={product.id}>
//           <ListItemIcon>
//             <img src={product.image} alt={product.name} style={{ width: '50px', height: '50px' }} />
//           </ListItemIcon>
//           <ListItemText primary={product.name} secondary={`$${product.price.toFixed(2)}`} />
//           <Checkbox
//             checked={!!selectedProducts.find(item => item.id === product.id)}
//             onChange={() => handleToggleProduct(product)}
//             edge="end"
//           />
//         </ListItem>
//       ))}
//     </List>
//   );
// };

// export default ProductSelector;
