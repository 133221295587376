// ImageCropDialog.js
import React from "react";
import Dialog from "@mui/material/Dialog";
import DialogTitle from "@mui/material/DialogTitle";
import DialogContent from "@mui/material/DialogContent";
import DialogActions from "@mui/material/DialogActions";
import CloseIcon from "@mui/icons-material/Close";
import DoneIcon from "@mui/icons-material/Done";

import ImageCropper from "./ImageCropper";
import IconButtonWithText from "../IconButtonWithText ";


const ImageCropDialog = ({
  isOpen,
  onClose,
  imageSrc,
  onCropComplete,
  onSave,
  title = "Crop Image",
}) => (
  <Dialog open={isOpen} onClose={onClose} maxWidth="sm" fullWidth>
    <DialogTitle style={{ padding: "16px" }}>
      
      <div
        style={{
          display: "flex",
          justifyContent: "space-between",
          fontFamily: "Poppins, sans-serif",
          fontWeight: "bold",
        }}
      >
        {title}
        <CloseIcon onClick={onClose} style={{ cursor: "pointer" }} />
      </div>
    </DialogTitle>
    <DialogContent>
      <ImageCropper imageSrc={imageSrc} onCropComplete={onCropComplete} />
    </DialogContent>
    <DialogActions style={{ padding: "16px" }}>
      <IconButtonWithText
        icon={DoneIcon}
        text="SAVE"
        onClick={onSave}
      />
    </DialogActions>
  </Dialog>
);

export default ImageCropDialog;
