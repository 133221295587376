
import React, { useEffect, useState } from 'react';
import { useTheme } from "@mui/material/styles";
import { Box, Grid, Typography } from '@mui/material';
import AddCircleIcon from '@mui/icons-material/AddCircle';

import { fetchCategories, fetchProductsByCategory } from "../../../utils/SelectionApiUtile";
import ProductModal from './selctionprocess/ProductModel';
import ProductPromoTable from './selctionprocess/ProductPromoTable';

function ProductPromo() {
  const [open, setOpen] = useState(false);
  const [categories, setCategories] = useState([]);
  const [selectedCategory, setSelectedCategory] = useState(null);
  const [subcategories, setSubcategories] = useState([]);
  const [selectedSubcategory, setSelectedSubcategory] = useState(null);
  const [products, setProducts] = useState([]);
  const [selectedProducts, setSelectedProducts] = useState([]); // State for selected products
  const theme = useTheme();

  useEffect(() => {
    fetchCategories().then(setCategories); // Fetch categories on component mount
  }, []);

  useEffect(() => {
    if (selectedCategory) {
      fetchProductsByCategory(selectedCategory).then(data => setSubcategories(data.subcategories)); // Fetch subcategories when a category is selected
    }
  }, [selectedCategory]);

  useEffect(() => {
    if (selectedSubcategory) {
      fetchProductsByCategory(selectedSubcategory).then(data => setProducts(data.products)); // Fetch products when a subcategory is selected
    }
  }, [selectedSubcategory]);

  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);

  const handleAddProduct = (product) => {
    setSelectedProducts(prev => [...prev, product]); // Add product to selectedProducts
    handleClose();
    setProducts(""); // Reset products
  };

  return (
    <div>
      <Grid>
        <Box style={{
          display: "flex", flexWrap: "wrap", justifyContent: "space-between", alignItems: "center", marginTop: "5px"
        }}>
          <Grid item xs={12} sm={6} style={{ textAlign: "center", marginBottom: "0.5rem" }}>
            <div style={{ display: "flex", flexDirection: "row", alignItems: "center" }}>
              <Typography
                gutterBottom
                variant="h6"
                align="center"
                style={{
                  paddingTop: "1rem", color: theme.palette.store.light, margin: "0 auto"
                }}>
                PRODUCT PROMO
              </Typography>
            </div>
          </Grid>
          <Grid item xs={12} sm={6} style={{ textAlign: "center" }}>
            <button type="button" className="buttonstore" onClick={handleOpen}>
              <span className="button__icon"><AddCircleIcon /></span>
              <span className="button__text"><b>Promo Product</b></span>
            </button>
          </Grid>
        </Box>
      </Grid>
      <div style={{ marginTop: '15px' }}></div>
      <ProductPromoTable products={selectedProducts} setProducts={setSelectedProducts} /> {/* Pass setSelectedProducts */}
      <ProductModal open={open} onClose={handleClose} onProductAdd={handleAddProduct} />
    </div>
  );
}

export default ProductPromo;


// import React, { useState, useEffect } from 'react';
// import { useTheme } from "@mui/material/styles";
// import { Box, Grid,  Typography } from '@mui/material';
// import AddCircleIcon from '@mui/icons-material/AddCircle';

// import { fetchCategories ,fetchProductsByCategory} from "../../../utils/SelectionApiUtile";

// import ProductModal from './selctionprocess/ProductModel';
// import ProductPromoTable from './selctionprocess/ProductPromoTable';



// function ProductPromo() {
//   const [open, setOpen] = useState(false);
//   const [categories, setCategories] = useState([]);
//   const [selectedCategory, setSelectedCategory] = useState(null);
//   const [subcategories, setSubcategories] = useState([]);
//   const [selectedSubcategory, setSelectedSubcategory] = useState(null);
//   const [products, setProducts] = useState([]);
//   const [selectedProducts, setSelectedProducts] = useState([]);
//   const theme = useTheme();
//   useEffect(() => {
//     // Fetch categories on component mount
//     fetchCategories().then(setCategories);
//   }, []);

//   useEffect(() => {
//     // Fetch subcategories when a category is selected
//     if (selectedCategory) {
//       // Mock fetch subcategories
//       fetchProductsByCategory(selectedCategory).then(data => setSubcategories(data.subcategories));
//     }
//   }, [selectedCategory]);

//   useEffect(() => {
//     // Fetch products when a subcategory is selected
//     if (selectedSubcategory) {
//       // Mock fetch products
//       fetchProductsByCategory(selectedSubcategory).then(data => setProducts(data.products));
//     }
//   }, [selectedSubcategory]);

//   const handleOpen = () => setOpen(true);
//   const handleClose = () => setOpen(false);

//   const handleAddProduct = (product) => {
//     setSelectedProducts(prev => [...prev, product]);
//     console.log("promo",selectedProducts)
//     handleClose();
//     setProducts("")
//   };

//   return (
//     <div>
//       <Grid>
//         <Box
//           style={{
//             display: "flex",
//             flexWrap: "wrap",
//             justifyContent: "space-between",
//             alignItems: "center",
//             marginTop: "5px",
//           }}
//         >
//           <Grid item xs={12} sm={6} style={{ textAlign: "center", marginBottom: "0.5rem" }}>
//             <div
//               style={{
//                 display: "flex",
//                 flexDirection: "row",
//                 alignItems: "center",
//               }}
//             >
//              <Typography
//                 gutterBottom
//                 variant="h6"
//                 align="center"
//                 style={{
//                   paddingTop: "1rem",
//                   color: theme.palette.store.light,
//                   margin: "0 auto",
//                 }}
//               >
//                 PRODUCT PROMO
//               </Typography>
//             </div>
//           </Grid>
//           <Grid item xs={12} sm={6} style={{ textAlign: "center" }}>
         
//           <button type="button" className="buttonstore" onClick={handleOpen}>
//               <span className="button__icon">
//                 <AddCircleIcon />
//               </span>
//               <span className="button__text">
//                 <b>Promo Product</b>
//               </span>
//             </button>
//           </Grid>
//         </Box>
//       </Grid>
//       <div style={{ marginTop: '15px' }}></div>
//       <ProductPromoTable products={selectedProducts} setProducts={selectedProducts}/>
//       <ProductModal open={open} onClose={handleClose} onProductAdd={handleAddProduct}/>
    
//     </div>
//   );
// }

// export default ProductPromo;