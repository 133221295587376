// main button adding category,subcategory,proucts


// import React from 'react';
// import AddCircleIcon from '@mui/icons-material/AddCircle';


// const AddButton = ({ onClick, text = "Add Product", icon = <AddCircleIcon />, className }) => {
//   return (
//     <div className="d-flex justify-content-end">
//       <button
//         type="button"
//         className={className}
//         onClick={onClick}
//       >
//         <span className="button__icon">
//           {icon}
//         </span>
//         <span className="button__text">
//           <b>{text}</b>
//         </span>
//       </button>
//     </div>
//   );
// };

// export default AddButton;


import React from 'react';
import AddCircleIcon from '@mui/icons-material/AddCircle';

const AddButton = ({ onClick, text = "Add Product", icon, className }) => {
  // If no icon is passed, use the default AddCircleIcon
  const IconToUse = icon || <AddCircleIcon />;

  return (
    <div className="d-flex justify-content-end">
      <button
        type="button"
        className={className}
        onClick={onClick}
        style={{ whiteSpace: 'nowrap' }}
      >
        <span className="button__icon">
          {IconToUse}
        </span>
        <span className="button__text">
          <b>{text}</b>
        </span>
      </button>
    </div>
  );
};

export default AddButton;
