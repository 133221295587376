// utils/CookieUtiles.js
import Cookies from 'js-cookie';

export const getAccessToken = () => {
  return Cookies.get('accessToken');
};
export const getRefreshToken = () => {
  return Cookies.get('refreshToken');
};
 
export const setAccessToken = (token) => {
  Cookies.set('accessToken', token, );
};
export const setRefreshToken = (token) => {
  Cookies.set('refreshToken', token, { expires: 300 }); 
};


export const removeAccessToken = () => {
  Cookies.remove('accessToken');
};

export const removeRefreshToken = () => {
  Cookies.remove('refreshToken');
};
