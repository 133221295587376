import React, { useState } from 'react';
import { Card, CardContent, IconButton, Typography } from '@mui/material';
import MoreHorizIcon from '@mui/icons-material/MoreHoriz';
import { useNavigate } from 'react-router-dom';
import '../../ManageProducts/ManageCategory.css';
import defaultImage from '../../../../src/assets/images/Upload Image.jpeg';
import { useTheme } from "@mui/material/styles";
import DialogEditDelete from '../mainCategory/DialogEditDelete';
import ImageCropDialog from '../../reusable/button/image/ImageCropDialog';

const SubcategoryItem = ({ category, onEditSubcategory, onDeleteSubcategory }) => {
  const [open, setOpen] = useState(false);
  const navigate = useNavigate();
  const [hover, setHover] = useState(false);
  const theme = useTheme();
  const [imageSrc, setImageSrc] = useState(null);
 
  const [isCropping, setIsCropping] = useState(false);
  const [isImageDialogOpen, setIsImageDialogOpen] = useState(false);
  const [newName, setNewName] = useState(''); 
  const [croppedAreaPixels, setCroppedAreaPixels] = useState(null);
  const [croppedImage, setCroppedImage] = useState(null);
  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);


  const handleViewProduct = () => {
    navigate(`/product`);
  };


  const handleFileChange = (event) => {
    const file = event.target.files[0];
    if (file) {
      const reader = new FileReader();
      reader.onload = () => {
        setImageSrc(reader.result);
        setIsImageDialogOpen(true);
        setIsCropping(true);
      };
      reader.readAsDataURL(file);
    }
  };

  const handleChangeName = (e) => {
    setNewName(e.target.value);
  };
  const generateCroppedImage = async () => {
    const canvas = document.createElement("canvas");
    
    const image = new Image();
    
    image.src = imageSrc;
    await new Promise((resolve) => {
      image.onload = resolve;
    });

    const ctx = canvas.getContext("2d");
    canvas.width = croppedAreaPixels.width;
    canvas.height = croppedAreaPixels.height;
    ctx.drawImage(
      image,
      croppedAreaPixels.x,
      croppedAreaPixels.y,
      croppedAreaPixels.width,
      croppedAreaPixels.height,
      0,
      0,
      croppedAreaPixels.width,
      croppedAreaPixels.height
    );

    const croppedImageDataUrl = canvas.toDataURL("image/jpeg");
    handleCropDone(croppedImageDataUrl);
  };
  const handleCropDone = (croppedImage) => {
    setCroppedImage(croppedImage);
    setIsCropping(false);
    setIsImageDialogOpen(false); // Close the dialog after cropping
  };
  const onCropComplete = (_, croppedAreaPixels) => {
    setCroppedAreaPixels(croppedAreaPixels);
  };
  const handleImageDialogClose = () => {
    setIsImageDialogOpen(false);
    setImageSrc(null);
  };
  const handleDelete = () => {
    if (onDeleteSubcategory) {
      onDeleteSubcategory(category.id);
    }
  };

  const handleEditCategory = () => {
    if (onEditSubcategory) {
      onEditSubcategory(category.id, newName);
    }
  };

  return (
    <div>
      <Card
        variant="contained"
        style={{
          borderRadius: '1.2rem',
          position: 'relative',
          transition: 'transform 0.3s, box-shadow 0.3s, background-color 0.3s, color 0.3s, font-weight 0.3s',
          cursor: 'pointer',
          backgroundColor: hover ? theme.palette.blue.hovor : 'white',
          color: hover ? '#0277bd' : 'black',
          overflow: 'hidden',
          fontWeight: hover ? 'bold' : 'normal',
        }}
        onMouseEnter={() => setHover(true)}
        onMouseLeave={() => setHover(false)}
      >
        <CardContent style={{ padding: "10px" }}>
          <div onClick={handleViewProduct} style={{ display: "flex", alignItems: "center", position: "relative" }}>
            {category.image && (
              <div style={{ position: 'relative', marginRight: "10px" }}>
                <img
                  src={category.image || "default-image-url"}
                  alt={category.name}
                  style={{
                    width: "100px",
                    height: "100px",
                    borderRadius: "1rem",
                    objectFit: "cover",
                    transition: 'filter 0.3s, background-color 0.3s',
                    filter: hover ? 'brightness(0.7)' : 'brightness(1)',
                  }}
                />
                {hover && (
                  <div
                    style={{
                      position: 'absolute',
                      top: 0,
                      left: 0,
                      width: '100%',
                      height: '100%',
                      backgroundColor: theme.palette.blue.hovor,
                      opacity: 0.6,
                      borderRadius: "1rem",
                    }}
                  />
                )}
              </div>
            )}
            <div style={{ flex: 1 }}>
              <Typography
                variant="h6"
                style={{
                  fontFamily: 'Poppins, sans-serif',
                  overflowWrap: 'break-word',
                  fontWeight: hover ? 'bold' : 'normal',
                  transition: 'font-weight 0.3s',
                }}
              >
                {category.name}
              </Typography>
            </div>
          </div>
          <div style={{ position: "absolute", top: 10, right: 10 }}>
            <IconButton
              onClick={handleOpen}
              style={{
                padding: '2px',
                borderRadius: '3px',
                color: '#0277bd',
                '&:hover': {
                  backgroundColor: '#66bb6a !important',
                },
              }}
            >
              <MoreHorizIcon />
            </IconButton>
          </div>
        </CardContent>
      </Card>

      <DialogEditDelete
        open={open}
        onClose={handleClose}
        title="Manage SubCategory"
        category={category}
        onChangeName={handleChangeName}
        onFileChange={handleFileChange}
        onDelete={handleDelete}
        onSave={handleEditCategory}
        croppedImage={croppedImage}
        defaultImage={defaultImage}
        head="SubCategory"
      />
       {isCropping && (
        <ImageCropDialog
          isOpen={isImageDialogOpen}
          onClose={handleImageDialogClose}
          imageSrc={imageSrc}
          onCropComplete={onCropComplete}
          onSave={generateCroppedImage}
        />
      )}

    </div>
  );
};

export default SubcategoryItem;
