

import "./App.css";
import React, { useEffect, useState } from 'react';
import { BrowserRouter as Router, Route, Routes, Navigate, useNavigate } from 'react-router-dom';
import NavBar from './components/layout/header/NavBar';
import Menu from './components/layout/sideBar/Menu';
import SubcategoryView from './components/ManageProducts/subCategory/SubCategoryView';
import CategoryManager from './components/ManageProducts/mainCategory/CategoryManager';
import BackgroundColorProvider from './styles/color/BackgroundColorProvider';
import Product from './components/ManageProducts/ProductPage/Product';
import Dashboard from './components/layout/Dashboard/Dashboard';
import OrdersData from './components/ManageProducts/Orders/OrderData';
import OrdersPage from './components/ManageProducts/Orders/OrderPage';
import ProfilePage from './components/layout/profile/ProfilePage';
import Login from './components/loginPage/Login';
import AddStore from "./components/ManageProducts/addStore/AddStore";
import { getAccessToken,getRefreshToken, removeAccessToken, removeRefreshToken } from "./utils/CookieUtiles";
import DynamicTitleAndIcon from "./components/layout/Dashboard/DynamicTitle";

import ProductPromo from "./components/homePage/promo/ProductPromo";
import { createToken } from "./utils/CreateApi";

function App() {
  const [drawerOpen, setDrawerOpen] = useState(true);
    const navigate = useNavigate();
  const [loggedIn, setLoggedIn] = useState(() => {
  
    const token = getRefreshToken();
    return !!token; 
  });
  // const [loggedIn, setLoggedIn] = useState(() => !!getRefreshToken());
  const [authToken, setAuthToken] = useState(getAccessToken()); 
  const handleLogin = () => {
    setLoggedIn(true);
    navigate("/")
   
  };
  useEffect(() => {
    console.log("Access Token:", getAccessToken());
  console.log("Refresh Token:", getRefreshToken());
    const token = getAccessToken(); 
    if(token){
      setAuthToken(token); 
    }
    else if(!token){
   createToken(navigate);
  const token = getAccessToken();
  setAuthToken(token); 
 }
 
    const refreshToken = getRefreshToken();
    if (!refreshToken) {
      console.log("Refresh token is empty")
      handleLogout();
    }

    console.log("authToken", authToken); 
  }, [authToken,getAccessToken()]);


  const handleLogout = () => {
    navigate('/login');
    setLoggedIn(false);
    removeAccessToken();
    removeRefreshToken();
 
  };

  return (
    <><DynamicTitleAndIcon/>
      {!loggedIn ? (
        <Login setLoggedIn={handleLogin}  />
      ) : (
        <div className="main">
          <Menu open={drawerOpen} setOpen={setDrawerOpen} />
          <div className="App">
            <NavBar setDrawerOpen={setDrawerOpen} drawerOpen={drawerOpen} setLoggedIn={handleLogout} />
            <BackgroundColorProvider>
              <div className={`content ${drawerOpen ? "content-open" : "content-closed"}`}>
                <Routes>
                  <Route path="/" element={<Navigate to="/dashboard" />} />
                  <Route path="dashboard" element={<Dashboard />} />
                  <Route path="Product Promo" element={<ProductPromo />} />
                  <Route path="profile" element={<ProfilePage />} />
                  <Route path="category" element={<CategoryManager authToken={authToken} />} />
                  <Route path="/category/:categoryId" element={<SubcategoryView authToken={authToken} />} />
                  <Route path="Product" element={<Product authToken={authToken} />} />
                  <Route path="ManageProduct" element={<CategoryManager authToken={authToken} />} />
                  <Route path="add-store" element={<AddStore authToken={authToken} />} />
                  <Route path="All orders" element={<OrdersData authToken={authToken} />} />
                  <Route path="Accepted" element={<OrdersPage authToken={authToken} />} />
                </Routes>
              </div>
            </BackgroundColorProvider>
          </div>
        </div>
      )}
    </>
  );
}

export default App;

