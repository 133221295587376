import React, { useState, useEffect } from "react";
import '../loginPage/applog.css';
import logo from '../../styles/image/favicon-32x32.png';
import authImage from '../../styles/image/auth-login-image.png'; 
import google from '../../styles/image/google-icon.svg'; 
import FullPageLoader from '../reusable/FullPagePreloader';
import { setAccessToken, setRefreshToken, getRefreshToken, removeAccessToken, getAccessToken } from '../../utils/CookieUtiles'; 
import useErrorHandle from "../error/ErrorHandling";



const Login = ({ setLoggedIn }) => {
  const [email, setEmail] = useState('');
  const [otp, setOtp] = useState('');
  const [step, setStep] = useState(1); 
  const [error, setError] = useState('');
  const [timer, setTimer] = useState(30);
  const [loading, setLoading] = useState(false); 
  const apiBaseUrl = process.env.REACT_APP_API_BASE_URL;
  const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
  const handleError = useErrorHandle();
  useEffect(() => {
    console.log("Current Access Token:", getAccessToken()); // Log to check token
  }, []);
  const startTimer = () => {
    setTimer(30); 
    const id = setInterval(() => {
      setTimer((prev) => {
        if (prev <= 1) {
          clearInterval(id);
          removeAccessToken(); 
          return 0;
        }
        return prev - 1;
      });
    }, 1000);
  };

  const verifyOtp = async () => {
    setLoading(true); 
    setError('');
    try {
      const verifyToken = getAccessToken();
    
      const response = await fetch(`${apiBaseUrl}/auth-email-otp`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          'Authorization': `Bearer ${verifyToken}`, 
        },
        body: JSON.stringify({ verifycode: otp }), 
      });
      if (!response.ok) {
        await handleError(response); 
      }
      const data = await response.json();
    
      if (response.ok && data.results && data.results.accessToken) {
        setAccessToken(data.results.accessToken);
        setRefreshToken(data.results.refreshToken);
        console.log("access",getAccessToken())
        console.log("refresh",getRefreshToken())
        setLoggedIn(true); 
      } else {
        setError('Invalid OTP.');
      } 
    } catch (error) {
      setError('An error occurred during OTP verification.');
    } finally {
      setLoading(false); 
    }
  };

  const handleEmailSubmit = (e) => {
    e.preventDefault();
    if (!emailRegex.test(email)) {
      setError('Please enter a valid email address.');
      return;
    }
    sendOtp();
  };

  const handleOtpSubmit = (e) => {
    e.preventDefault();
    verifyOtp();
  };

  const sendOtp = async () => {
    setLoading(true); 
    setError('');
    try {
      const response = await fetch(`${apiBaseUrl}/auth-email`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({ email }),
      });
      // if (!response.ok) {
      //   await handleError(response);
      // }
      const data = await response.json();
     
      if (response.ok && data.results.verifytoken) {
        // localStorage.setItem('verifytoken', data.results.verifytoken); 

        setAccessToken(data.results.verifytoken);
        console.log("Verify token set in cookies:", getAccessToken());
        setStep(2); 
        startTimer();
      } else {
        setError('Failed to send OTP.');
      } 
    } catch (error) {
      setError('An error occurred while sending the OTP.');
    } finally {
      setLoading(false); 
    }
  };

 

  useEffect(() => {
    if (otp.length === 6) {
      handleOtpSubmit(new Event('submit'));
    }
  }, [otp]);

  return (
    <div>
      {loading && <FullPageLoader />} 
      <div className="wrapper">
        <div className="section-authentication-cover">
          <div className="">
            <div className="row g-0">
              <div className="col-12 col-xl-7 col-xxl-8 auth-cover-left align-items-center justify-content-center d-none d-xl-flex">
                <div className="card shadow-none bg-transparent shadow-none rounded-0 mb-0" style={{border:'none'}}>
                  <div className="card-body">
                    <img src={authImage} className="img-fluid" width="600" alt="" />
                  </div>
                </div>
              </div>
              <div className="col-12 col-xl-5 col-xxl-4 auth-cover-right align-items-center justify-content-center">
                <div className="card rounded-0 m-3 shadow-none bg-transparent mb-0" style={{border:'none'}}>
                  <div className="card-body p-sm-5">
                    <div className="p-3 text-center">
                      <div className="text-center">
                        <img src={logo} width="60" alt="" />
                      </div>
                      {step === 1 && (
                        <>
                          <h4 className="text-center mt-5 font-weight-bold">
                            Welcome to Nego Tube 
                          </h4>
                          <p className="text-center text-muted">
                            By continuing with your registered email ID
                          </p>
                          <form onSubmit={handleEmailSubmit}>
                            <div className="form-group mt-4">
                              <label htmlFor="email" className="text-left" style={{ marginBottom: "4px", fontSize: '14px' }}>
                                Email
                              </label>
                              <input
                                type="email"
                                id="email"
                                className="form-control"
                                placeholder="Enter your email"
                                value={email}
                                onChange={(e) => setEmail(e.target.value)}
                                required
                              />
                            </div>
                            {error && <p className="error-message">{error}</p>}
                            <button className="btn btn-primary w-100 my-3">Sign In</button>
                          </form>
                          <p className="text-center text-muted">
                        Don't have an account yet?
                        <a href="/register" className="text-primary" style={{ paddingLeft: "4px" }}>
                          Register here
                        </a>
                      </p>

                      <div className="d-flex align-items-center justify-content-center my-3 position-relative">
                        <hr className="flex-grow-1" />
                        <span className="mx-2 text-muted" style={{fontSize:'14px'}}>OR SIGN IN WITH</span>
                        <hr className="flex-grow-1" />
                      </div>

                      <a
                        className="btn btn-white w-100 my-3 waves-effect waves-light"
                        href="/dashboard"
                        style={{ marginTop: "0px !important" }}
                      >
                        <img className="me-2" src={google} width="18" alt="" />{" "}
                        Sign In with Google
                      </a>
                        </>
                        
                      )}

                      {step === 2 && (
                        <>
                          <h4 className="text-center mt-5 font-weight-bold">
                            Verify OTP
                          </h4>
                          <p className="text-center text-muted">
                            Enter the OTP sent to your email
                          </p>
                          <form onSubmit={handleOtpSubmit}>
                            <div className="form-group">
                            <label htmlFor="otp" className="text-left" style={{ marginBottom: "4px", fontSize: '14px' }}>
                            Verify OTP
                            </label>
                              <input
                                type="text"
                                id="otp"
                                className="form-control"
                                placeholder="Enter your OTP"
                                value={otp}
                                onChange={(e) => setOtp(e.target.value)}
                                maxLength="6"
                                required
                              />
                            </div>
                            {timer > 0 ? (
                              <p className="mt-3" style={{color:'red'}}>{timer}s</p>
                            ) : (
                              <p style={{color:'red',paddingTop:'10px'}} onClick={handleEmailSubmit}>Resend OTP</p>
                            )}
                            {error && <p style={{color:"red"}} className="error-message">{error}</p>}
                          </form>
                        </>
                      )}

                      {/* <p className="text-center text-muted">
                        Don't have an account yet?
                        <a href="/register" className="text-primary" style={{ paddingLeft: "4px" }}>
                          Register here
                        </a>
                      </p>

                      <div className="d-flex align-items-center justify-content-center my-3 position-relative">
                        <hr className="flex-grow-1" />
                        <span className="mx-2 text-muted" style={{fontSize:'14px'}}>OR SIGN IN WITH</span>
                        <hr className="flex-grow-1" />
                      </div>

                      <a
                        className="btn btn-white w-100 my-3 waves-effect waves-light"
                        href="/dashboard"
                        style={{ marginTop: "0px !important" }}
                      >
                        <img className="me-2" src={google} width="18" alt="" />{" "}
                        Sign In with Google
                      </a> */}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Login;
