
import React, { useState } from "react";
import {
  Typography,
  Breadcrumbs,
  Box,
  Grid,
 

} from "@mui/material";
import { useNavigate } from "react-router-dom";


import { useTheme } from "@mui/material/styles";
import GridViewIcon from "@mui/icons-material/GridView";
import ProductTable from "./ProductTable";
import mobile from "../../../assets/images/mobile.jpg";
import Samsung from "../../../assets/images/mobile1.jpg";
import macbookpro from "../../../assets/images/mobile.jpg";
import dellxps13 from "../../../assets/images/mobile.jpg";
import ArrowForwardIcon from "@mui/icons-material/ArrowForward";
import leathersof from "../../../assets/images/mobile.jpg";
import fabricsofa from "../../../assets/images/mobile.jpg";
import coffeetabl from "../../../assets/images/mobile.jpg";
import diningtable from "../../../assets/images/mobile.jpg";

import StoreIcon from '@mui/icons-material/Store';
import AddButton from "../../reusable/button/AddButton";
import IconWithText from "./IconWithText ";
import SearchBar from "./SearchBox";


import AddProductButtonDialog from "./EditDialog/AddProductButtonDialog";



const initialCategories = [
  {
    id: 11,
    name: "Electronics",
    image: "https://example.com/images/electronics.jpg",
    subcategories: [
      {
        id: 1,
        name: "Mobile Phones",
        image: "https://example.com/images/mobile.jpg",
        products: [
          {
            id: 101,
            name: "iphone 13",
            description: "Latest Apple smartphone with A15 Bionic chip.",
            price: 999.99,

            image: mobile,
          },
          {
            id: 102,
            name: "Samsung Galaxy S21",
            description: "Flagship Samsung phone with Exynos 2100.",
            price: 799.99,
            image: Samsung,
          },
        ],
      },
      {
        id: 2,
        name: "Laptops",
        image: "https://example.com/images/laptop.jpg",
        products: [
          {
            id: 103,
            name: "MacBook Pro",
            description: "Apple's latest M1 chip-powered laptop.",
            price: 1299.99,
            image: macbookpro,
          },
          {
            id: 104,
            name: "Dell XPS 13",
            description: "Premium Dell laptop with 11th Gen Intel Core i7.",
            price: 1199.99,
            image: dellxps13,
          },
        ],
      },
    ],
  },
  {
    id: 12,
    name: "Furniture",
    image: "https://example.com/images/furniture.jpg",
    subcategories: [
      {
        id: 21,
        name: "Sofas",
        image: "https://example.com/images/sofa.jpg",
        products: [
          {
            id: 201,
            name: "Leather Sofa",
            description: "Comfortable and stylish leather sofa.",
            price: 499.99,
            image: leathersof,
          },
          {
            id: 204,
            name: "Fabric Sofa",
            description: "Comfortable fabric sofa available in various colors.",
            price: 399.99,
            image: fabricsofa,
          },
        ],
      },
      {
        id: 22,
        name: "Tables",
        image: "https://example.com/images/table.jpg",
        products: [
          {
            id: 202,
            name: "Wooden Coffee Table",
            description: "Elegant coffee table made from oak wood.",
            price: 199.99,
            image: coffeetabl,
          },
          {
            id: 203,
            name: "Dining Table Set",
            description: "Modern dining table with six chairs.",
            price: 599.99,
            image: diningtable,
          },
        ],
      },
    ],
  },
];

const Product = () => {
  const [categories] = useState(initialCategories);
  const [open, setOpen] = useState(false);
  const navigate = useNavigate();
  const theme = useTheme();
 
  const [newProduct, setNewProduct] = useState({
    name: "",
    description: "",
    price: "",
    image: "",
  });
  const handleSearchChange = (e) => {
    setSearchQuery(e.target.value);
  };

  const handleClose = () => setOpen(false);

  const handleAddProduct = () => {
    // Add logic to handle the new product here
    console.log("New Product:", newProduct);
    setOpen(false);
  };
  

  const handleChange = (e) => {
    const { name, value } = e.target;
  
    if (name === "name") {
     
      setNewProduct((prev) => ({
        ...prev,
        [name]: value.slice(0, 100), 
      }));
    } else if (name === "price") {
    
      if (/^\d*\.?\d{0,2}$/.test(value) && value.length <= 12) {
        setNewProduct((prev) => ({
          ...prev,
          [name]: value,
        }));
      }
    }
  };
  
  const handleOpen = () => setOpen(true);

  const handleBack = () => {
    navigate("/add-store");
  };
  const handleBackCategory = () => {
    navigate("/category");
  };
//   const handleBackSub = () => {
//     navigate(`/category/:${initialCategories.subcategories.id}`);
// };
const handleBackSub = () => {
  // Assuming you want to navigate based on the first category's first subcategory
  const category = initialCategories[0]; // Access the first category
  if (category && category.subcategories && category.subcategories[0]) {
      const subcategoryId = category.subcategories[0].id; // Access the first subcategory ID
      navigate(`/category/${subcategoryId}`);
  } else {
      console.error("Category or subcategory not found.");
  }
};

  const [searchQuery, setSearchQuery] = useState("");

  const filteredProducts = categories.filter((product) =>
    searchQuery.toLowerCase() === ""
      ? true
      : product.name.toLowerCase().includes(searchQuery.toLowerCase())
  );
  const linkStyle = {
    // padding: "5px 5px",
    borderRadius: "10px",
    fontSize: "small",
    display: "inline-flex",
    cursor: "pointer",
    backgroundColor: theme.palette.purple.light,
    color: "white",
    transition: "background-color 0.3s",
  };

  const iconStyle = {
    display: "flex",
    alignItems: "center",
    padding: "5px",
  };

  const textStyle = {
    display: "flex",
    alignItems: "center",
    padding: "5px",
  };

  // Aggregate all products from all subcategories
  const allProducts = categories.flatMap((category) =>
    category.subcategories.flatMap((subcategory) => subcategory.products)
  );

  return (
    <div
      style={{
        backgroundColor: theme.palette.purple.backgroundColor,
        minHeight: "100vh",
        width: "100%",
        display: "flex",
        flexDirection: "column",
      }}
    >
      <div>
        <Grid
        // style={{ marginBottom: "5px" }}
        >
          <Box
            style={{
              display: "flex",
              flexWrap: "wrap",
              // justifyContent: "space-between",
              alignItems: "center",
              marginTop: "5px",
            }}
          >
            <Grid
              item
              xs={12}
              sm={6}
              style={{ textAlign: "center", marginBottom: "0.5rem" }}
            >
              <div
                style={{
                  display: "flex",
                  flexDirection: "row",
                  alignItems: "center",
                }}
              >
                <Breadcrumbs
                  aria-label="breadcrumb"
                  separator={
                    <b>
                      <ArrowForwardIcon
                        style={{
                          fontSize: "25px",
                          color: theme.palette.purple.light,
                        }}
                      />
                    </b>
                  }
                >
                   <Typography
                    color="textPrimary"
                    onClick={handleBack}
                    style={linkStyle}
                  >
                    <Box style={iconStyle}>
                      <StoreIcon style={{ fontSize: "large" }} />
                    </Box>
                    <Box style={textStyle}>Stores</Box>
                  </Typography>
                  <Typography
                    color="textPrimary"
                    onClick={handleBackCategory}
                    style={linkStyle}
                  >
                    <Box style={iconStyle}>
                      <GridViewIcon style={{ fontSize: "large" }} />
                    </Box>
                    <Box style={textStyle}>Category</Box>
                  </Typography>
                  <Typography
                    color="textPrimary"
                    onClick={handleBackSub}
                    style={linkStyle}
                  >
                    <Box style={textStyle}>Electronics</Box>
                  </Typography>
                  <Typography
                    color="textPrimary"
                    // onClick={handleBack}
                    style={linkStyle}
                  >
                    <Box style={textStyle}>Laptops</Box>
                  </Typography>
                </Breadcrumbs>
              </div>
            </Grid>
          </Box>
        </Grid>

        <Grid>
          <Box
            style={{
              display: "flex",
              flexWrap: "wrap",
              justifyContent: "space-between",
              alignItems: "center",
              // marginTop: "2px",
            }}
          >
            <Grid
              item
              xs={12}
              sm={6}
              style={{ textAlign: "center", marginBottom: "0.5rem" }}
            >
              <div
                style={{
                  display: "flex",
                  flexDirection: "row",
                  alignItems: "center",
                }}
              >
                <IconWithText
                  //  icon={ShoppingCartIcon}
                  text="PRODUCTS"
                />
                <SearchBar
                  placeholder="Search..."
                  value={searchQuery}
                  onChange={handleSearchChange}
                />
              </div>
            </Grid>
            <Grid item xs={12} sm={6} style={{ textAlign: "center" }}>
              <AddButton className="buttonProduct" onClick={handleOpen} />
            </Grid>
          </Box>
        </Grid>
        <ProductTable
          products={allProducts}
          filteredProducts={filteredProducts}
        />
        <AddProductButtonDialog   open={open}
        handleClose={handleClose}
        handleChange={handleChange}
        handleAddProduct={handleAddProduct}
        newProduct={newProduct}
        theme={theme}/>
      

      </div>
    </div>
  );
};

export default Product;
