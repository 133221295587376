import React, { useEffect, useState } from "react";
import { Typography, Grid } from "@mui/material";
import OrderTable from "./OrderTable";
import json from '../../ManageProducts/Orders/Demo.json'; // Importing JSON data
import '../Orders/OtherInfo/Order.css';

import { useTheme } from "@mui/material/styles";
import OrderDetails from "./OrderView";
function OrdersPage({ title, status, authToken }) {
  const [orders, setOrders] = useState([]);
  const [filteredData, setFilteredData] = useState([]);
  const [loading, setLoading] = useState(true);
  const [selectedOrder, setSelectedOrder] = useState(null);
  const theme = useTheme();
  const [drawerOpen, setDrawerOpen] = useState(false);
  const toggleDrawer = (open) => () => {
    setDrawerOpen(open);
  };
  useEffect(() => {
    // Set the orders data directly from the imported JSON
    setOrders(json.content);
    setFilteredData(json.content);
    setLoading(false);
  }, []);

  useEffect(() => {
    // Filter orders based on the 'accepted' orderStatus when orders or status change
    if (orders.length) {
      const acceptedOrders = orders.filter(order => order.orderStatus === 'Accepted');
      setFilteredData(acceptedOrders);
    }
  }, [orders, status]);

  const handleView = (order) => {
    setSelectedOrder(order);
    toggleDrawer(true)();
  };

  return (
    <div   style={{
        backgroundColor: theme.palette.lime.backgroundColor,
        minHeight: "100vh",
        width: "100%",
        display: "flex",
        flexDirection: "column",
      }}>
      <Typography gutterBottom variant="h6" sx={{ color: theme.palette.lime.texthead,}}>
       Accepted Orders
      </Typography>
      <Grid container spacing={1}>
        <Grid item md={12} xs={12}>
          <OrderTable data={filteredData} onView={handleView} loading={loading} />
        </Grid>
      </Grid>
      {selectedOrder && (
            <OrderDetails order={selectedOrder} open={drawerOpen}  toggleDrawer={toggleDrawer} />
      )}
    </div>
  );
}

export default OrdersPage;
