import React, { useState } from "react";
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  Box
} from "@mui/material";
import { useTheme } from "@mui/material/styles";
import RightSideDrawer from "./ProductModel";

const ProductTable = ({ products = [] }) => {
  const theme = useTheme();
  const [drawerOpen, setDrawerOpen] = useState(false);
  const [selectedProduct, setSelectedProduct] = useState('');

  const toggleDrawer = (open) => () => {
    setDrawerOpen(open);
  };

  const handleProductClick = (product) => {
    setSelectedProduct(product);
    toggleDrawer(true)();
  };

  return (
    <div style={{ marginTop: '3px' }}>
      <TableContainer component={Paper}>
        <Table size="small">
          <TableHead>
            <TableRow style={{ backgroundColor: theme.palette.purple.mainbutton, color: "#ff8f00", fontWeight: 'bold' }}>
              <TableCell style={{ padding: "12px", width: "0%" }}>Image</TableCell>
              <TableCell style={{ padding: "12px ", width: "40%" }}>Name</TableCell>
              <TableCell style={{ padding: "12px ", width: "0%", textAlign: "center" }}>Price</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {products.length > 0 ? (
              products.map((product) => (
                <TableRow
                  key={product.id}
                  onClick={() => handleProductClick(product)}
                  sx={{
                    backgroundColor: selectedProduct === product ? 'rgb(0 0 0 / 10%)' : 'inherit',
                    cursor: "pointer",
                    "&:hover": {
                      backgroundColor: 'rgb(0 0 0 / 10%)',
                      "& .MuiTableCell-root": {
                        color: 'rgb(0 0 0 / 34%)',
                        fontFamily: 'Poppins, sans-serif',
                        fontWeight: 'bold'
                      }
                    }
                  }}
                >
                  <TableCell style={{ padding: "2px " }}>
                    <div style={{ position: "relative", display: "inline-block" }}>
                      <img
                        src={product.image}
                        alt={product.name}
                        style={{
                          height: "50px",
                          width: '50px',
                          borderRadius: '5px',
                          transition: "transform 0.3s ease-in-out",
                        }}
                      />
                    </div>
                  </TableCell>
                  <TableCell style={{ padding: "12px " }}>
                    <Box sx={{ fontFamily: "Poppins, sans-serif" }}>
                      {product.name}
                    </Box>
                  </TableCell>
                  <TableCell style={{ padding: "12px ", textAlign: 'center' }}>
                    <Box sx={{ fontFamily: "Poppins, sans-serif", alignItems: 'center' }}>
                      ₹{product.price}
                    </Box>
                  </TableCell>
                </TableRow>
              ))
            ) : (
              <TableRow>
                <TableCell colSpan={4} align="center" style={{ padding: "8px " }}>
                  No products available
                </TableCell>
              </TableRow>
            )}
          </TableBody>
        </Table>
      </TableContainer>
      <RightSideDrawer open={drawerOpen} toggleDrawer={toggleDrawer} data={selectedProduct} />
    </div>
  );
};

export default ProductTable;
