import React from 'react';

import Typography from '@mui/material/Typography';
import WhatsAppIcon from '@mui/icons-material/WhatsApp';
import { Button,  Box } from '@mui/material';

const WhatsAppChatButton = ({phoneNumber}) => {
  const handleClick = () => {
    
    const message = encodeURIComponent('Hi, this is from Noava');
    const url = `https://api.whatsapp.com/send/?phone=${phoneNumber}&text=${message}&type=phone_number&app_absent=0`;
    window.open(url, '_blank'); 
  };

 
  return (
    <div
     
    >
      <Button
      variant="contained"
      className="call"
      onClick={handleClick}
      sx={{ display: 'flex', justifyContent: 'center',backgroundColor: "rgb(0 129 27)", alignItems: 'center', 
          padding:'2px 4px'
        // paddingLeft: '4px',
        // paddingRight: '4px',
        // paddingTop: '2px',
        // paddingBottom: '2px'
      }} 
      
    >
      <Box sx={{ display: 'flex', alignItems: 'center', padding: '2px' }}>
        <WhatsAppIcon  style={{ fontSize: '20px' }}/>
      </Box>
      <Box sx={{ display: 'flex', alignItems: 'center', padding: '2px', flexGrow: 1, justifyContent: 'center' }}>
        <Typography variant="button">
          Chat
        </Typography>
      </Box>
    </Button>
     
    </div>
  );
};

export default WhatsAppChatButton;
