// CategoryDialog.js
import React from 'react';
import { Dialog, DialogTitle, DialogContent, DialogActions, TextField, Box, Typography, IconButton } from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import EditIcon from '@mui/icons-material/Edit';
import CameraAltIcon from '@mui/icons-material/CameraAlt';
import DeleteIcon from '@mui/icons-material/Delete';
import DoneIcon from '@mui/icons-material/Done';
import { useTheme } from '@mui/material/styles';
import IconButtonWithText from '../../reusable/button/IconButtonWithText ';

const DialogEditDelete = ({
  open,
  onClose,
  title,
  category,
  onChangeName,
  onFileChange,
  onDelete,
  onSave,
  croppedImage,
  defaultImage,head
}) => {
  const theme = useTheme();
 // Check if category is defined and has a name property
 const categoryName = category?.name || '';
 const categoryNameLength = categoryName.length;
  return (
    <Dialog
      open={open}
      onClose={onClose}
      maxWidth="md"
      fullWidth
      sx={{ "& .MuiDialog-paper": { maxWidth: "sm" } }}
    >
      <DialogTitle
        sx={{
          backgroundColor: "#02ac02db",
          color: "white",
          textAlign: "center",
          fontSize: "16px",
          fontWeight: "500",
          padding: "14px",
          fontFamily: "Poppins, sans-serif",
        }}
      >
        <div
          style={{
            display: "flex",
            justifyContent: "space-between",
            fontFamily: "Poppins, sans-serif",
            fontWeight: "bold",
          }}
        >
          <b>{title}</b>
          <CloseIcon onClick={onClose} style={{ cursor: "pointer" }} />
        </div>
      </DialogTitle>
      <DialogContent style={{ padding: "20px" }}>
        <Box style={{ justifyContent: "space-between", display: "flex" }}>
          <Box
            className="marginBottom5"
            sx={{
              display: "flex",
              alignItems: "center",
              paddingBottom: "6px",
            }}
          >
            <EditIcon
              style={{
                marginRight: "5px",
                fontSize: "18px",
                color: theme.palette.primary.text,
              }}
            />
            <Typography
              variant="body1"
              style={{
                color: theme.palette.primary.text,
                fontFamily: "Poppins, sans-serif",
              }}
            >
              <b>{head} Name</b>
            </Typography>
          </Box>

          <Typography
            variant="caption"
            sx={{
              textAlign: "left",
              color: "blue",
              display: "flex",
              fontSize: "15px",
              justifyContent: "flex-end",
            }}
          >
            <b style={{ color: theme.palette.primary.text }}>
              {categoryNameLength}/32
            </b>
          </Typography>
        </Box>
        <TextField
          type="text"
          name="name"
          variant="outlined"
          size="small"
          className="customTexName"
          placeholder="Type Here..."
          fullWidth
          value={category.name}
          onChange={onChangeName}
          autoComplete="off"
          inputProps={{ maxLength: 32 }}
          sx={{
            marginBottom: "10px",
            "& .MuiOutlinedInput-root": {
              "& fieldset": {
                border: "none",
              },
            },
          }}
        />

        <Box mt={2}></Box>
        <Box
          className="marginBottom5"
          sx={{
            display: "flex",
            alignItems: "center",
            paddingBottom: "6px",
          }}
        >
          <EditIcon
            style={{
              marginRight: "5px",
              fontSize: "18px",
              color: theme.palette.primary.text,
            }}
          />
          <Typography
            variant="body1"
            style={{
              color: theme.palette.primary.text,
              fontFamily: "Poppins, sans-serif",
            }}
          >
            <b>Upload Image</b>
          </Typography>
        </Box>

        <Box
          onChange={onFileChange}
          sx={{
            position: "relative",
            display: "inline-block",
          }}
        >
          <img
            src={croppedImage || defaultImage}
            alt={category.image}
            style={{ width: '100px', height: '100px', objectFit: 'cover', borderRadius: '5px' }}
          />
          <IconButton
            sx={{
              position: "absolute",
              bottom: 0,
              right: 0,
              backgroundColor: "rgba(255, 255, 255, 0.8)",
              borderRadius: "5px",
              padding: "2px",
              minWidth: 0,
              boxShadow: "0px 0px 5px rgba(0,0,0,0.2)",
              "&:hover": {
                backgroundColor: "rgba(255, 255, 255, 0.8)",
              },
            }}
            size="small"
            component="label"
          >
            <CameraAltIcon fontSize="small" style={{ color: "green" }} />
            <input
              type="file"
              accept="image/*"
              style={{ display: "none" }}
              onChange={onFileChange}
            />
          </IconButton>
        </Box>
      </DialogContent>
      <DialogActions sx={{ padding: "20px" }}>
        <button
          onClick={onDelete}
          type="button"
          className="btn btn-outline-error d-flex justify-content-between align-items-center px-4"
        >
          <DeleteIcon sx={{ fontSize: "20px !important" }} className="me-2" />
          DELETE
        </button>
        <IconButtonWithText
              icon={DoneIcon}
              text="SAVE"
              onClick={onSave}
            />
          </DialogActions>
        
      
    </Dialog>
  );
};

export default DialogEditDelete;
