import { Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Paper, Box, IconButton } from "@mui/material";
import RemoveCircleIcon from "@mui/icons-material/RemoveCircle";
import { useTheme } from "@mui/material/styles";
import { useState } from "react";

const ProductPromoTable = ({ products, setProducts }) => {
  const theme = useTheme();
  const [selectedProduct, setSelectedProduct] = useState(null); 

  const handleProductClick = (product) => {
    setSelectedProduct(product); 
  };

  const handleRemoveProduct = (productId) => {
    setProducts((prevProducts) => prevProducts.filter((product) => product.id !== productId)); 
  };

  return (
    <div style={{ marginTop: "3px" }}>
      <TableContainer component={Paper}>
        <Table size="small">
          <TableHead>
            <TableRow style={{
              backgroundColor: theme.palette.promo.mainbutton, color: "white", fontWeight: "bold"
            }}>
              <TableCell style={{ padding: "12px", width: "0%", color: "white" }}>Image</TableCell>
              <TableCell style={{ padding: "12px", width: "40%", color: "white" }}>Name</TableCell>
              <TableCell style={{ padding: "12px", width: "0%", color: "white", textAlign: "center" }}>Price</TableCell>
              <TableCell style={{ padding: "12px", width: "0%", color: "white" }}>Action</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {products.length > 0 ? (
              products.map((product) => (
                <TableRow
                  key={product.id}
                  onClick={() => handleProductClick(product)}
                  sx={{
                    backgroundColor: selectedProduct === product ? "rgb(0 0 0 / 10%)" : "inherit",
                    cursor: "pointer",
                    "&:hover": {
                      backgroundColor: "rgb(0 0 0 / 10%)",
                      "& .MuiTableCell-root": {
                        color: "rgb(0 0 0 / 34%)", fontFamily: "Poppins, sans-serif", fontWeight: "bold"
                      },
                      "& img": {
                        backgroundColor: "rgb(0 0 0 / 10%)"
                      }
                    }
                  }}>
                  <TableCell style={{ padding: "2px" }}>
                    <div style={{ position: "relative", display: "inline-block" }}>
                      <img
                        src={product.image}
                        alt={product.name}
                        style={{
                          height: "50px", width: "50px", borderRadius: "5px", transition: "transform 0.3s ease-in-out"
                        }} />
                    </div>
                  </TableCell>
                  <TableCell style={{ padding: "12px" }}>
                    <Box sx={{ fontFamily: "Poppins, sans-serif" }}>{product.name}</Box>
                  </TableCell>
                  <TableCell style={{ padding: "12px", textAlign: "center" }}>
                    <Box sx={{ fontFamily: "Poppins, sans-serif" }}>₹{product.price}</Box>
                  </TableCell>
                  <TableCell style={{ padding: "12px", textAlign: "center" }}>
                    <IconButton
                      onClick={(e) => {
                        e.stopPropagation(); // Prevent triggering the row click
                        handleRemoveProduct(product.id);
                      }}
                      style={{
                        color: "#666cff", borderColor: "#e7e7ff", background: "#e7e7ff", borderRadius: "20%", border: "1px solid #eeecec", alignItems: "center"
                      }}>
                      <RemoveCircleIcon style={{ fontSize: "19px" }} />
                    </IconButton>
                  </TableCell>
                </TableRow>
              ))
            ) : (
              <TableRow>
                <TableCell colSpan={4} align="center" style={{ padding: "8px" }}>No products available</TableCell>
              </TableRow>
            )}
          </TableBody>
        </Table>
      </TableContainer>
    </div>
  );
};

export default ProductPromoTable;


// import React, { useEffect, useState } from "react";
// import {
//   Table,
//   TableBody,
//   TableCell,
//   TableContainer,
//   TableHead,
//   TableRow,
//   Paper,
//   Box,
//   IconButton,

// } from "@mui/material";
// import RemoveCircleIcon from '@mui/icons-material/RemoveCircle';
// import { useTheme } from "@mui/material/styles";

// const ProductPromoTable = ({ products }) => {
//   const theme = useTheme();
// console.log("table data products ",products)
//   const [selectedProduct, setSelectedProduct] = useState('');
//   const [isproducts, setProducts] = useState(products);
//   useEffect(() => {
//   console.log(products)
//   }, [products]);
//   const handleProductClick = (product) => {
//     console.log(product)
//     setSelectedProduct(product);
    
  
//   };
//   const handleRemoveProduct = (productId) => {
    
//     setProducts(isproducts.filter(product => product.id !== productId));
//   };

//   return (
//     <div style={{marginTop:'3px'}}>
//       <TableContainer component={Paper}>
//         <Table size="small"> {/* Use size="small" for a more compact table */}
//           <TableHead>
//             <TableRow style={{  backgroundColor: theme.palette.promo.mainbutton, color: "white !important",fontWeight:'bold' }}>
//               <TableCell style={{ padding: "12px", width: "0%" ,color:'white'}}>Image</TableCell>
//               <TableCell style={{ padding: "12px ", width: "40%" ,color:'white'}}>Name</TableCell>
//               <TableCell style={{ padding: "12px ", width: "0%",color:'white' ,textAlign: "center"}}>Price</TableCell>
//               <TableCell style={{ padding: "12px ", width: "0%",color:'white' }}>Action</TableCell>
//             </TableRow>
//           </TableHead>
//           <TableBody>
//           {/* {Array.isArray(products) && products.length > 0 ? ( */}
//             {products.length > 0 ? (
//               products.map((product) => (
//                 <TableRow key={product.id}     onClick={() => handleProductClick(product)}
//                 sx={{
//                   backgroundColor: selectedProduct === product ? 'rgb(0 0 0 / 10%)' : 'inherit',
//                   cursor: "pointer",
//                   "&:hover": {
//                     backgroundColor:' rgb(0 0 0 / 10%)',
//                     "& .MuiTableCell-root": {
//                       color: ' rgb(0 0 0 / 34%)',
//                       fontFamily: 'Poppins, sans-serif',
//                       fontWeight:'bold'
//                     },
//                     "& img": {
//                       backgroundColor:' rgb(0 0 0 / 10%)',
                      
//                     }
//                   },
//                 }}
//                 >
//                   <TableCell style={{ padding: "2px " }}>
//                     <div style={{ position: "relative", display: "inline-block" }}>
//                       <img
//                         src={product.image}
//                         alt={product.name}
//                         style={{
//                           height: "50px",
//                           width:'50px',
//                           borderRadius:'5px',
//                           '&:hover': {
//                             filter: 'brightness(0.7)', 
//                             // backgroundColor: '#c8e6c9',
//                             opacity: 0.6, 
//               },
//                           transition: "transform 0.3s ease-in-out",
//                         }}
//                       />
//                     </div>
//                   </TableCell>
//                   <TableCell style={{ padding: "12px "}}>
//                     <Box
//                       sx={{
                       
//                         fontFamily: "Poppins, sans-serif",
//                       }}
//                     >
//                       {product.name}
//                     </Box>
//                   </TableCell >
//                   <TableCell style={{ padding: "12px " ,textAlign:'center'}}>
//                     <Box
//                       sx={{
                        
//                         fontFamily: "Poppins, sans-serif",alignItems:'center'
//                       }}
//                     >
//                     ₹{product.price}
//                     </Box>
//                   </TableCell>
                
//                   <TableCell style={{ padding: "12px " ,textAlign:'center'}}>
//                   <IconButton  onClick={(e) => {
//                         e.stopPropagation(); 
//                         handleRemoveProduct(product.id);
//                       }}  style={{    color: '#666cff ',
//                         borderColor: '#e7e7ff ',
//                         background: '#e7e7ff',
//                         borderRadius: '20%',
//                         border: '1px solid #eeecec',
//                         alignItems:'center'}}>
//                             <RemoveCircleIcon style={{ fontSize: "19px",  }} />
//                           </IconButton>
//                   </TableCell>
//                 </TableRow>
//               ))
//             ) : (
//               <TableRow>
//                 <TableCell colSpan={4} align="center" style={{padding: "8px "}}>
//                   No products available
//                 </TableCell>
//               </TableRow>
//             )}
//           </TableBody>
//         </Table>
//       </TableContainer>
      
//     </div>
//   );
// };

// export default ProductPromoTable;