import React, { useEffect, useState } from "react";
import {
  Typography,
  Breadcrumbs,
  Box,
  Grid,
  Snackbar,
  Alert,
} from "@mui/material";
import { useTheme } from "@mui/material/styles";
import GridViewIcon from "@mui/icons-material/GridView";
import StoreIcon from '@mui/icons-material/Store';
import AddCircleIcon from "@mui/icons-material/AddCircle";
import CommonDialogAdd from "../mainCategory/CommondialogAdd";
import AddCard from "./AddCard";
import useErrorHandle from "../../error/ErrorHandling";

const AddStore = ({ authToken }) => {
  const [title, setTitle] = useState("");

  const [error, setError] = useState("");
  const handleError = useErrorHandle();
  const [openSnackbar, setOpenSnackbar] = useState(false);
  const [snackbarMessage, setSnackbarMessage] = useState("");
  const [snackbarSeverity, setSnackbarSeverity] = useState("success");
  const [stores, setStores] = useState([]);

  const theme = useTheme();
  const apiBaseUrl = process.env.REACT_APP_API_BASE_URL_NEGOTUBE;

  const [open, setOpen] = useState(false);
  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);

  const handleSnackbarClose = () => {
    setOpenSnackbar(false);
  };
  useEffect(() => {
    console.log("Current Access Token:", authToken); // Log to check token
  }, []);
  const handleSubmit = async (e) => {
    e.preventDefault();
    setError("");
    try {
      // const response = await fetch(`https://cors-anywhere.herokuapp.com/https://api.negotube.com/v1/add-store`, {
      const response = await fetch(`${apiBaseUrl}/add-store`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${authToken}`,
        },
        body: JSON.stringify({ title }),
      });

      console.log("Store Auth", authToken);
      if (!response.ok) {
        await handleError(response);
      }

      const data = await response.json();
      if (response.ok && data.status) {
        setSnackbarMessage("The store is created successfully.");
        setSnackbarSeverity("success");
        setTitle("");
        setStores((prevStores) => [
          ...prevStores,
          { id: data.results.id, title: title },
        ]);
      } else {
        setError(data.message || "Failed to create store.");
        setSnackbarMessage(data.message || "Failed to create store.");
        setSnackbarSeverity("error");
      }
    } catch (err) {
      console.error("Error:", err);
      setError("An error occurred while creating the store.");
      setSnackbarMessage("An error occurred while creating the store.");
      setSnackbarSeverity("error");
    } finally {
      setOpenSnackbar(true);
    }
  };

  const linkStyle = {
    borderRadius: "10px",
    fontSize: "small",
    display: "inline-flex",
    cursor: "pointer",
    backgroundColor: theme.palette.store.light,
    color: "white",
    transition: "background-color 0.3s",
  };

  const iconStyle = {
    display: "flex",
    alignItems: "center",
    padding: "5px",
  };

  const textStyle = {
    display: "flex",
    alignItems: "center",
    padding: "5px",
  };

  return (
    <div
      style={{
        backgroundColor: theme.palette.store.backgroundColor,
        minHeight: "100vh",
        width: "100%",
        display: "flex",
        flexDirection: "column",
      }}
    >
      <div>
        <Breadcrumbs aria-label="breadcrumb" separator=">">
          <Typography color="textPrimary" style={linkStyle}>
            <Box style={iconStyle}>
              <StoreIcon style={{ fontSize: "large" }} />
            </Box>
            <Box style={textStyle}>Stores</Box>
          </Typography>
        </Breadcrumbs>
      </div>

      <Grid>
        <Box
          style={{
            display: "flex",
            flexWrap: "wrap",
            justifyContent: "space-between",
            alignItems: "center",
            marginTop: "0px",
          }}
        >
          <Grid
            item
            xs={12}
            sm={6}
            style={{ textAlign: "center", marginBottom: "0.5rem" }}
          >
            <div
              style={{
                display: "flex",
                flexDirection: "row",
                alignItems: "center",
              }}
            >
              <GridViewIcon
                style={{
                  fontSize: 40,
                  color: theme.palette.store.light,
                  paddingTop: "1rem",
                  margin: "0 auto",
                }}
              />
              <Typography
                gutterBottom
                variant="h6"
                align="center"
                style={{
                  paddingTop: "1rem",
                  color: theme.palette.store.light,
                  margin: "0 auto",
                }}
              >
                STORES
              </Typography>
            </div>
          </Grid>
          <Grid item xs={12} sm={6} style={{ textAlign: "center" }}>
            <button type="button" className="buttonstore" onClick={handleOpen}>
              <span className="button__icon">
                <AddCircleIcon />
              </span>
              <span className="button__text">
                <b>Add Store</b>
              </span>
            </button>
          </Grid>
        </Box>
      </Grid>

      <CommonDialogAdd
        open={open}
        onClose={handleClose}
        title="Add Store"
        placeholder="Type Here..."
        value={title}
        head="Enter Store Name"
        onChange={(e) => setTitle(e.target.value)}
        onSubmit={handleSubmit}
        maxLength={32}
        submitButtonText="ADD"
      />

      <Grid container spacing={1} style={{ marginTop: "0px" }}>
        {stores.map((store) => (
          <Grid item xs={12} sm={6} md={4} key={store.id}>
            <AddCard store={store} />
          </Grid>
        ))}
      </Grid>

      <Snackbar
        open={openSnackbar}
        autoHideDuration={6000}
        onClose={handleSnackbarClose}
      >
        <Alert onClose={handleSnackbarClose} severity={snackbarSeverity}>
          {snackbarMessage}
        </Alert>
      </Snackbar>
    </div>
  );
};

export default AddStore;
